/*
Modal

A modal box that overlays the page.

default - default version uses the midnight color.
visitor-info--themed - uses the color of the current theme.

Markup:
.modal
  .modal__box
    .modal__close-btn
      svg.svg-icon
        use(xlink:href="images/assets/SVGSpritesheet.svg#cross")
    img(src="images/image-11.jpg")
    .modal__box-content
      h3.header--small Hov, har du udfyldt din email korrekt?
      p.text For at tilmelde dig nyhedsbrevet skal vi bruge din email. Det ser ud til at den email du har indtastet har et forkert format.


Styleguide 2.17
*/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;

  &:before {
    content: '';
    position: absolute;
    background-color: black;
    opacity: 0.2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.modal__box {
  background-color: white;

  width: 90%;
  max-width: 500px;
  box-shadow: 0px 10px 60px 0px rgba(0,0,0,0.1);
  position: relative;
  z-index: 1;
}

.modal__box-content {
  padding: $spacing-small;
}

.modal__close-btn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 8px;
  background: $color-theme-primary;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);

  .svg-icon {
    fill: white;
  }
}
