/*
Off canvas menu

The menu that resides off canvas. The menu should be positioned fixed to the top and
left of the page, with a full height. The menu should then be transformed out of
the viewport to the left of the canvas.

Markup:
nav.off-canvas-menu
  .off-canvas-menu__primary
    ul
      li
        a(href="#") Oplev Experimentarium
      li
        a(href="#") Naturvidenskab og teknologi
  .off-canvas-menu__secondary
    ul
      li
        a(href="#") Om os
      li
        a(href="#") Til virksomheder
      li
        a(href="#") Til undervisere
      li
        a(href="#") Presse
      li
        a(href="#") English
      li
        a(href="#")
          svg.svg-icon
            use(xlink:href="images/assets/SVGSpritesheet.svg#cart")
          | Webshop
      li
        a(href="#")
          svg.svg-icon
            use(xlink:href="images/assets/SVGSpritesheet.svg#search")
          | Søg

Styleguide 3.3
*/
.slideout-open .slideout-menu {
  @include breakpoint(large) {
    display: none !important;
  }
}
.slideout-open .slideout-panel {
  @include breakpoint(large) {
    transform: translateX(0px) !important;
  }
}
.off-canvas-menu {

  padding: $spacing-small 0;
  padding-top: 70px;
  background-color: inherit;

  @include breakpoint(medium) {
    padding-top: 80px;
  }

  @include breakpoint(large) {
    padding-top: 50px;
    // display: none;
  }
}

.off-canvas-menu__primary {
  margin-bottom: $spacing-small*1.5;
  // min-height: 70%;

  li {
    padding: 10px 0;
  }
}

.off-canvas-menu__secondary {
  padding: 0 20px;
  ul#menu-top-menu {
    text-align: center;
  }
  li {
    padding: 5px 15px;
    display: inline-block;

    a {
      display: inline-block;
      text-transform: uppercase;
      color: #fff;
      font-size: 12px;

      .svg-icon {
        width: 20px;
        height: 15px;
        margin-right: $spacing-small/2;
        display: inline-block;
        vertical-align: sub;
      }
    }
  }
  a {
      display: inline-block;
      text-transform: uppercase;
      color: #fff;
      font-size: 12px;
    }
}
@each $theme, $color in $themes {
  @include theme($theme, $color, '.off-canvas-menu') {
    background-color: $color;
  }
}
