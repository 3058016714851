/*
Call to action

A full screen width call to action element. The classes .scroll-animate and .scroll-animate--fade-up should be added to the image of the
Call to action element. This will ensure that the image animates in smoothely when the user scrolls the CTA into view.
the call to action has the following modifiers:

default - a normal quote
.call-to-action--reverse - reverses the order of the image and content
.call-to-action--no-image - hides the image and centers the content

markup:
.call-to-action(class="{$modifiers}")
  .row
    .call-to-action__image.columns.small-12.medium-10.large-6.medium-offset-1.large-offset-0
      img(src="images/image-17--01.jpg" data-js-animate="fade-up")
    .call-to-action__content.columns.small-12.medium-10.large-6.medium-offset-1.large-offset-0
      h3.header--medium Tilmeld dig vores nyhedsbrev
      p Praesent posuere diam dui, in consequat felis finibus vitae. Pellentesque dignissim tincidunt sem nec lobortis.
      .input-group
        input(class="input-group-field input-text input-text--invert" type="text" placeholder="Din mail adresse")
        .input-group-button
          input(type="submit" class="button btn btn--invert" value="Tilmeld")
p &nbsp;
.color-theme-container-for-the-element.theme-green
  .call-to-action(class="{$modifiers}")
    .row
      .call-to-action__image.columns.small-12.medium-10.large-6.medium-offset-1.large-offset-0
        img(src="images/image-17--02.jpg" data-js-animate="fade-up")
      .call-to-action__content.columns.small-12.medium-10.large-6.medium-offset-1.large-offset-0
        h3.header--medium Tilmeld dig vores nyhedsbrev
        p Praesent posuere diam dui, in consequat felis finibus vitae. Pellentesque dignissim tincidunt sem nec lobortis.
        .input-group
          input(class="input-group-field input-text input-text--invert" type="text" placeholder="Din mail adresse")
          .input-group-button
            input(type="submit" class="button btn btn--invert" value="Tilmeld")
p &nbsp;
.color-theme-container-for-the-element.theme-green
  .call-to-action(class="{$modifiers}")
    .row
      .call-to-action__image.columns.small-12.medium-10.large-6.medium-offset-1.large-offset-0
        .blend-mode--multiply
          img(src="images/image-17.jpg" data-js-animate="fade-up")
      .call-to-action__content.columns.small-12.medium-10.large-6.medium-offset-1.large-offset-0
        h3.header--medium Tilmeld dig vores nyhedsbrev
        p Praesent posuere diam dui, in consequat felis finibus vitae. Pellentesque dignissim tincidunt sem nec lobortis.
        .input-group
          input(class="input-group-field input-text input-text--invert" type="text" placeholder="Din mail adresse")
          .input-group-button
            input(type="submit" class="button btn btn--invert" value="Tilmeld")

Styleguide 2.2
*/

/*
Product

A product in the shop. Builds upon the call to action element, but also has the 'call-to-action--expressive-image' modifier.
If the image needs to be overlayed on top of the previous element in the DOM, add the modifier 'call-to-action--image-overlay'
the call to action has the following modifiers:

default - a normal quote
.call-to-action--image-overlay - removes the margin above the call to action, to pull the image over the previous DOM element.

markup:
section.call-to-action.call-to-action--expressive-image(class="{$modifiers}")
  .row.align-center
    .call-to-action__image.columns.small-12.medium-10.large-6
      img.scroll-animate.scroll-animate--fade-up(src="images/hero-produkt--02.png")
    .call-to-action__content.columns.small-12.medium-10.large-6
      a(href="")
        h3.header--medium Flametrooper Star-Wars
      p Praesent posuere diam dui, in consequat felis finibus vitae. Pellentesque dignissim tincidunt sem nec lobortis.
      .tags
        svg.svg-icon.icon--tag
          use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
        .tag-group
          span.tag
            a(href="#") Star Wars
          span.tag
            a(href="#") Rummet
      button(class="label-btn btn--invert label-btn--invert" data-text="Se og køb")
        span.label-btn__text Se og køb
        span.label-btn__icon
          svg.svg-icon
            use(xlink:href="images/assets/SVGSpritesheet.svg#cart")
        span.label-btn__label 899 DKK

Styleguide 2.3
*/



.call-to-action {
  background-color: $color-theme-primary;

  @include breakpoint(medium) {
    margin-top: $spacing-small*3;
  }

  @include breakpoint(large) {
    margin-top: $spacing-small*6;
  }

  //overriding tags to white inside the call to action
  .tags {
    svg.icon--tag {
      fill: white;
    }

    .tag {
      a {
        color: white;
      }

      &:after {
        color: white;
      }
    }
  }

  .facebook-like{
    padding:$spacing-small;
    .inner{
      background-color: white;
      padding:$spacing-small;
    }
  }
  .social-shares{
    flex-direction: row;
  }
}

.call-to-action--reverse {
  .row {
    flex-direction: row-reverse;
  }

  .call-to-action__content, .call-to-action__image, .call-to-action__map {
    @include breakpoint(medium) {
      margin-left:auto;
      margin-right:auto;
    }
    @include breakpoint(large) {
      text-align: right;
      .input-group{
        margin-right: 0;
        float:right;
      }
    }
  }
}

div[class*="theme-"] .call-to-action--neutralt{
  background-color: $light-gray;
  .call-to-action__content {
    color:$brand-color--black;
  }
}

.call-to-action__content {
  color: white;
  padding-top: $spacing-large;
  padding-bottom: $spacing-large;
  h3{
    margin-bottom: 0.5em;
    // letter-spacing: 1px;
  }
  p {
    margin-bottom:0.5em;
  }
  .label-btn{
  }

  > * {
    // &:not(:last-child) {
    //   margin-bottom:0.25em;
    // }
    &:last-child{
      margin-top:2em;
    }
  }
}

.call-to-action__image, .call-to-action__map {
  padding-top: $spacing-large;

  >img {
    display: inline-block;
    margin: 0 -20px;
    max-width: calc(100% + 40px);
    box-shadow: $global-dropshadow--default;
  }
  .blend-mode--multiply{
    display: inline-block;
    background-color: $color-theme-primary;
    img {
      mix-blend-mode: multiply;
      width: 100%; //ie fix
      transform: translateY(-1px);
    }
  }

  //google maps
  #google-map{
    display: block;
    position: relative;
    width: 100%;
    height: 300px;
    box-shadow: $global-dropshadow--default;

    iframe {
      width: 100%;
    }

    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }


  @include breakpoint(medium) {
    transform: translateY(- $spacing-small*3);
    margin-bottom: -$spacing-small*3;
    padding-top: 0;

    img {
      max-width: 100%;
      margin: 0;
    }
  }

  @include breakpoint(large) {
    //padding-bottom: $spacing-large;
    transform: translateY(- $spacing-small*4);
    margin-bottom: 0;
  }
}

.call-to-action--no-image {
  margin-top: 0 !important;

  .call-to-action__image {
    display: none;
  }

  .row {
    justify-content: center;
    @extend .align-center;
  }

  .call-to-action__content {
    text-align: center;
  }
}


//used to make
.call-to-action--image-overlay {
  margin-top: 0 !important;
}

//moves the image further up. Used in products where the image is bigger.
.call-to-action--expressive-image {
  @include breakpoint(medium) {
    margin-top: $spacing-small*8;
  }

  @include breakpoint(large) {
    margin-top: $spacing-small*12;
  }
  &.webshop-shop {
    margin-top: 40px;

    @include breakpoint(medium) {
      margin-top: $spacing-small*2;
    }
    @include breakpoint(large) {
      margin-top: $spacing-small*4;
    }

  }

  .call-to-action__image {
    @include breakpoint(medium) {
      transform: translateY(- $spacing-small*8);
      margin-bottom: -$spacing-small*8;

      img {
        max-width: 100%;
        width: auto;
        margin: 0 auto;
        display: block;
        box-shadow: none;
      }
    }

    @include breakpoint(large) {
      transform: translateY(- $spacing-small*12);
      margin-bottom: -$spacing-small*12;
    }
  }
  img.product-background-image {
    width: 100%;
    object-fit: cover;
  }
}

.webshop-shop .web-shop-item-in-home {
  padding: 0 20px;
  margin-top: -20%;

  @include breakpoint(medium) {
    padding:0;
    margin-top: 0;
  }
}


@each $theme, $color in $themes {
  @include theme($theme, $color, '.call-to-action') {
    background-color: $color;
    .call-to-action__image{
      .blend-mode--multiply{
        background-color: $color;
      }
    }
  }
}
