$themes: (
  theme-blue: $brand-color--blue,
  theme-green: $brand-color--green,
  theme-red: $brand-color--red,
  theme-lavanda: $brand-color--lavanda
);

.theme-bg {
  background-color: $color-theme-primary;
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.theme-bg') {
    background-color: $color;
  }
}
