// Step by step
//
// A quote block from the WYSIWYG editor.
// the quote has the following modifiers:
//
// default - a normal quote
// .theme-red - The theme class will be applied to the body tag or a container above the element. There are different color themes.
// but this displays what a red theme would look like.
//
// markup:
//<div class="color-theme-container-for-the-element {$modifiers}">
//  <ol class="wysiwyg step-by-step {$modifiers}">
//    <li>
//       <div class="row align-center">
//         <div class="step-by-step__item">
//           <h3 class="header--small">Det her skal du bruge</h3>
//           <ol>
//            <li>En blomsterpind eller lignende</li>
//            <li>Ståluld uden sæbe</li>
//            <li>Tændstikker</li>
//           <ol>
//         </div>
//       </div>
//    </li>
//    <li>
//       <div class="row align-center">
//         <div class="step-by-step__item">
//           <h3 class="header--small">Sådan gør du</h3>
//           <p>Sæt pinden fast i sne eller jord, så den står fast. Skær cirka 5 cm. af stålulden og træk lidt i det, så det bliver en luftig tot. Hæng stålulden på pinden og antænd.</p>
//         </div>
//         <div class="step-by-step__item">
//           <figure>
//             <img src="images/image-04.jpg">
//             <figcaption>A cheeky macaque, Lower Kintaganban River, Borneo. Original by <a href="http://www.flickr.com/photos/rclark/" class="link">Richard Clark</a></figcaption>
//           </figure>
//         </div>
//       </div>
//    </li>
//    <li>
//       <div class="row align-center">
//         <div class="step-by-step__item">
//           <h3 class="header--small">Hvad sker der?</h3>
//           <p>Var du overrasket over, at jern kan brænde? Næsten alt omkring os kan brænde, hvis det er tilstrækkelig findelt. Jernledninger er så tynde, at hvis det bliver tilstrækkeligt varmt brænder det.</p>
//         </div>
//       </div>
//    </li>
//  </ol>
//</div>
//
// Styleguide 2.4

// list number style
ol.step-by-step{
  counter-reset: step-counter;
  list-style: none;
  padding-top: 50px;
  padding-bottom: 60px;
  >li{
    margin-bottom: 30px;
    &:before{
      position: relative;
      @extend .header--medium;
      content:counter(step-counter);
      counter-increment: step-counter;
      position: absolute;
      width: 60px;
      height: 60px;
      background-color: $color-theme-primary;
      color: white;
      text-align: center;
      vertical-align: middle;
      line-height: 60px;
    }
    &:last-child {
      margin-bottom: 0;

      .row {
        padding-bottom: 0;
      }
    }
  }
  @include breakpoint(medium) {
    >li{
      &:before{
        width: 100px;
        height: 100px;
        left:50%;
        transform: translateX(-50%);
        line-height: 100px;
      }
    }
  }
}
// step item layout
ol.step-by-step{
  >li{
    .row{
      @extend .align-top;
    }
    .step-by-step__item{
      display: inline-block;
      width: calc(100% - 60px);
      padding:0 $spacing-small 0 $spacing-medium;
      @include breakpoint(medium) {
        width: calc(50% - 80px);
        padding:0 $spacing-small 0 0;
      }
    }
    &:nth-child(2n+1){
      .row{
        @extend .align-right;
        &.step-by-step__image {
          .step-by-step__item-image-desc {
            @include breakpoint(medium) {
              text-align: right;
              margin-right: 140px;
            }
          }
        }
      }
      .step-by-step__item{
        text-align: left;
      }
    }
    &:nth-child(2n+2){
      .row {
        &.step-by-step__bullet {
          @include breakpoint(medium) {
            text-align: left;
            justify-content: flex-start;
            padding-bottom: 20px;
          }
          .step-by-step__item-bullet {
            @include breakpoint(medium) {
              text-align: right;
              padding-right: 0;
            }
            ul li {
              @include breakpoint(medium) {
                list-style-position: inside;
              }
            }
          }
        }
        &.step-by-step__image {
          .step-by-step__item-image-desc {
            @include breakpoint(medium) {
              padding-right: 0;
            }
          }
        }
      }
      .step-by-step__item{
        @include breakpoint(medium) {
          text-align: right;
        }
        &:nth-child(2n+1){
          margin-right: -60px;
          @include breakpoint(medium) {
            margin-right: 80px;
          }
        }
        &:nth-child(2n+2){
          margin-left:60px;
        }
      }
    }
  }
}



@each $theme, $color in $themes {
  @include theme($theme, $color, '.step-by-step') {
    >li{
      &:before{
        background-color: $color;
      }
    }
  }
}
