/*
Thumbnail Focus exhibition

This is used when editor what to promote a certain exhibition on a page. Normally it will be shown on one page at a time.

default -  in the default theme
.thumbnail-focus--reversed - reverses the elements
.thumbnail-focus--overlay - makes the thumbnail image overlay the previous DOM element

Markup:
.thumbnail-focus(class="{$modifiers}")
  .row.collapse
    .small-10.medium-8.large-6.small-offset-1.medium-offset-2.large-offset-1.columns
      .thumbnails-exhibition.focus__promotion-item
        a(href="#")
          .thumbnail__title
            h3.header--small Bobleriet
          .thumbnail__image.blend-mode--multiply
            img(src="images/image-02.jpg")
          .thumbnail__content
            p.description Bobleriet handler om sæbebobler. Hvorfor spejler en sæbebobbel dig to gange?
    .small-10.medium-6.large-5.small-offset-1.medium-offset-2.large-offset-0.columns
      .focus__promotion-text
        h5.header--smaller Ved du hvordan nyrerne ser ud? Og hvor leveren sidder? Plastmodellerne viser dig kroppens organer. Mange af dem kan også tages ud, så du kan kigge nærmere på dem.
.color-theme-container-for-the-element.theme-red
  .thumbnail-focus(class="{$modifiers}")
    .row.collapse
      .small-10.medium-8.large-6.small-offset-1.medium-offset-2.large-offset-1.columns
        .thumbnails-exhibition.focus__promotion-item
          a(href="#")
            .thumbnail__title
              h3.header--small Bobleriet
            .thumbnail__image.blend-mode--multiply
              img(src="images/image-02.jpg")
            .thumbnail__content
              p.description Bobleriet handler om sæbebobler. Hvorfor spejler en sæbebobbel dig to gange?
      .small-10.medium-6.large-5.small-offset-1.medium-offset-2.large-offset-0.columns
        .focus__promotion-text
          h5.header--smaller Ved du hvordan nyrerne ser ud? Og hvor leveren sidder? Plastmodellerne viser dig kroppens organer. Mange af dem kan også tages ud, så du kan kigge nærmere på dem.

Styleguide 2.11
*/

.thumbnail-focus{
  background-color: $color-theme-primary;
  padding-bottom:$spacing-medium;
  .focus__promotion-item{
    .thumbnail__title{
      padding:$spacing-small;
      h3{
        margin-bottom:0;
        @extend .header--medium;
        letter-spacing: 0;
      }
    }
    .blend-mode--multiply{
      display: inline-block;
      background-color: $color-theme-primary;
      img {
        mix-blend-mode: multiply;
      }
    }
  }
  @include breakpoint(small) {
    margin-top: $spacing-large;
    .focus__promotion-item{
      margin-top:$spacing-large;
      a{
        padding-bottom: 0;
      }
    }
    .focus__promotion-text{
      margin:0;
    }
  }
  @include breakpoint(medium) {
    margin-top: $spacing-large*3;
    .focus__promotion-item{
      margin-top:-$spacing-large*2;
      margin-bottom:0;
      a{
        padding-bottom: 0;
      }
    }
    .focus__promotion-text{
      margin:$spacing-small $spacing-small*0.5 0;
    }
  }
  @include breakpoint(large) {
    .focus__promotion-text{
      margin:$spacing-medium $spacing-small 0;
    }
    .thumbnails-exhibition{
      a{
        .thumbnail__content{
          width: 50%;
        }
      }
    }
  }
  .focus__promotion-text{
    color: white;
    h5{
      font-weight: 700;
    }
  }
}

.thumbnail-focus--reversed {
  .row {
    flex-direction:row;
    @include breakpoint(small) {
      flex-direction:row;
    }
    @include breakpoint(medium) {
      flex-direction:row;
    }
    @include breakpoint(large) {
      flex-direction:row-reverse;
      justify-content:center;
      .large-offset-1{
        @extend .large-offset-0;
      }
      .focus__promotion-text{
        text-align: right;
      }
    }
  }
}

.thumbnail-focus--overlay {
  @include breakpoint(small) {
    padding-top: $spacing-large + $spacing-small;
    margin-top: 0;
  }
  @include breakpoint(medium) {
    padding-top: $spacing-large*2 + $spacing-small;
    margin-top: 0;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.thumbnail-focus') {
    background-color: $color;
    .blend-mode--multiply{
      background-color: $color;
    }
  }
}
