.browser-safari {
	.global-navigation__extra-info {
    @include breakpoint(large) {
      max-width: 455px;
      max-height: 62px;
    }
	}
  .mb2-search-load {
    margin: 0 auto 2em !important;
  }
}
