/*
Daily program

A thumbnail for event, this is shown in the event overview page

Markup:
ul.daily-program
  li.list-item
    .program__time
      p.time 10:15 - 10:45
    .program__content
      h5.header--nano Tekniksjov
      p.location Store Scene / Stuen
  li.list-item
    .program__time
      p.time 11:30 - 11:45
    .program__content
      h5.header--nano Sæbeboblesjov
      p.location Lille Scene / 1. sal
  li.list-item
    .program__time
      p.time 12:00 - 12:45
    .program__content
      h5.header--nano Hjertedemo
      p.location Brandbaren / 2. sal
  li.list-item
    .program__time
      p.time 13:15 - 13:45
    .program__content
      h5.header--nano Sæbeboblesjov
      p.location Store Scene / Taget
  li.list-item
    .program__time
      p.time 14:45 - 14:45
    .program__content
      h5.header--nano Pigen der ikke ville sidde stille
      p.location Den interaktive Biograf / 1. sal
  li.list-item
    .program__time
      p.time 15:30 - 15:45
    .program__content
      h5.header--nano Sæbeboblesjov
      p.location Store Brandbaren / 2. sal
  li.list-item
    .program__time
      p.time 16:45 - 16:45
    .program__content
      h5.header--nano Pigen der ikke ville sidde stille
      p.Den interaktive Biograf / 1. sal
  li.list-item
    .program__time
      p.time 17:15 - 17:30
    .program__content
      h5.header--nano Sæbeboblesjov
      p.location Lille Scene / Stuen
  li.list-item
    .program__time
      p.time 18.30 - 18:45
    .program__content
      h5.header--nano Pigen der ikke ville sidde stille
      p.location Den interaktive Biograf / 1. sal
  li.list-item
    .program__time
      p.time 18.55 - 19:20
    .program__content
      h5.header--nano Sæbeboblesjov
      p.location Store Scene / 2. sal
  li.list-item
    .program__time
      p.time 19.45 - 20: 00
    .program__content
      h5.header--nano Sæbeboblesjov
      p.Den Store Scene / Stuen
  li.list-item
    .program__time
      p.time 21:00
    .program__content
      h5.header--nano Tak for i dag


Styleguide 2.15

*/

.daily-program{
  @extend .row;
  .list-item{
    @include grid-column(12);
    display: flex;
    margin-bottom:$spacing-small;
    @include breakpoint(medium) {
      @include grid-column(6);
      &:nth-child(2n+1){
        clear: left;
      }
    }
    @include breakpoint(large) {
      @include grid-column(4);
      &:nth-child(2n+1){
        clear: none;
      }
      &:nth-child(3n+1){
        clear: left;
      }
    }
    h5, p{
      margin-bottom:0;
    }
    h5{
      margin-bottom:$spacing-small*0.25;
    }
    p{
      @extend .text--small;
      margin-top:2px;
    }
    div[class^="program__"]{
      display: inline-block;
      align-self: flex-start;
    }
    .program__time{
      width: 30%;
    }
    .program__content{
      width: 65%;
    }
    .more-info {
        display: none;
        .description, .btn {
            margin: 0 0 10px;
        }
        .description {
            font-size: 0.8em;
            line-height: 1.5;
        }
        .btn {
            padding: 3px 15px;
        }
    }
  }
}