@mixin theme($name, $color, $child) {
  .#{$name $child} {
    @content;
  }
}

@mixin center-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
