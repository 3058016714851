/*
Thumbnail tricky class

A tricky thumbnail for exhibition, it appears upside down, when hover, it reverse to normal stats. We use eleTricky.js to remove the up-side-down class. When user refreshes page, the tricky class should be randomized in the selected group.

Markup:
.row.align-center
  .small-12.medium-6.large-4.columns
    .thumbnails-exhibition
      a(href="#")
        .thumbnail__title
          h3.header--small Bobleriet
        .thumbnail__image
          img(src="images/image-02.jpg")
        .thumbnail__content
          p.description Bobleriet handler om sæbebobler. Hvorfor spejler en sæbebobbel dig to gange?
  .small-12.medium-6.large-4.columns
    .thumbnails-exhibition.tricky2D
      a(href="#")
        .thumbnail__title
          h3.header--small Den interaktive biograf
        .thumbnail__image
          img(src="images/image-16.jpg")
        .thumbnail__content
          p.description I den interaktive udstilling Opfindelsernes hus bliver idéer til virkelighed og banebrydende opfindelser.
  .small-12.medium-6.large-4.columns
    .thumbnails-exhibition
      a(href="#")
        .thumbnail__title
          h3.header--small PULS-torvet
        .thumbnail__image
          img(src="images/image-17.jpg")
        .thumbnail__content
          p.description På PULS-torvet gælder det om at være aktiv og få både pulsen og humøret i vejret.

Styleguide 2.19

*/

.tricky{
  perspective: 800;
  &:hover{
    a{
      margin-top:0;
      animation-name: swingBack;
      animation-iteration-count: 1;
      animation-duration: 1s;
      animation-timing-function:cubic-bezier(0.07, 0.78, 0.46, 1.33);
      animation-play-state: running;
      transition-duration: 0.2s;
      transition-property: all;
      transition-delay: 0.7s;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
  }
  a{
    margin-top:-$spacing-medium;
    animation-name: swing;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-duration: 2s;
    animation-play-state: running;
    transition-duration: 0.4s;
    transition-property: all;
    transition-delay: 0s;
    transform: rotateX(180deg) rotateY(0deg) rotateZ(0deg);
  }
}

.tricky2D{
  perspective: 800;
  &:hover{
    a{
      margin-top:0;
      animation-name: swing2DBack;
      animation-iteration-count: 1;
      animation-duration: 0.4s;
      animation-timing-function:cubic-bezier(0.07, 0.78, 0.46, 1.33);
      animation-play-state: running;
      transition-duration: 0.2s;
      transition-property: all;
      transition-delay: 0.4s;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
  }
  a{
    margin-top:-$spacing-medium;
    animation-name: swing2D;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-duration: 2s;
    animation-play-state: running;
    transition-duration: 0.4s;
    transition-property: all;
    transition-delay: 0s;
    transform: rotateX(180deg) rotateY(0deg) rotateZ(0deg);
  }
}


.swing{
  animation-name: swing;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.hover{
}
.hover-out{
}

@keyframes swing {
  0%{
    transform: rotateX(182deg) rotateY(-2deg) rotateZ(-2deg);
  }
  50%{
    transform: rotateX(178deg) rotateY(2deg) rotateZ(2deg);
  }
  100%{
    transform: rotateX(182deg) rotateY(-2deg) rotateZ(-2deg);
  }
}

@keyframes swing2D {
  0%{
    transform: rotateX(-2deg) rotateY(-2deg) rotateZ(178deg);
  }
  50%{
    transform: rotateX(2deg) rotateY(2deg) rotateZ(182deg);
  }
  100%{
    transform: rotateX(-2deg) rotateY(-2deg) rotateZ(178deg);
  }
}

@keyframes swing01 {
  0%{
    transform: rotateX(5deg) rotateY(-5deg);
  }
  50%{
    transform: rotateX(0deg) rotateY(5deg);
  }
  100%{
    transform: rotateX(5deg) rotateY(-5deg);
  }
}

@keyframes swingBack {
  0%{
    transform: rotateX(180deg) rotateY(0deg) rotateZ(2deg);
  }
  100%{
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
}

@keyframes swing2DBack {
  0%{
    transform: rotateX(0deg) rotateY(0deg) rotateZ(180deg);
  }
  100%{
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
}
