//Themes colors
@each $theme, $color in $themes {
  @include theme($theme, $color, '.qustion-circle-selector') {
    border-color: $color;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.answer') {
    .qustion-circle-selector {
      &::after {
        background-color: $color;
      }
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.question-title') {
    background-color: $color;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.quiz-wrapper') {
    background-color: $color;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.quiz-cta-btn') {
    border-color: $color;
    color: $color;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.result-holder-wrapper') {
    background-color: $color;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.answer') {
    svg {
      polyline {
        stroke: $color;
      }
      rect {
        fill: $color;
      }
    }
  }
}

//Global style
.quiz-cta {
  button {
    border: 2px solid;
    padding: 12px 20px;
  }
}

.quiz-title {
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}

.quiz-progress {
  text-align: center;
  font-size: 62px;
  color: #fff;
}



.quiz-wrapper {
  padding: 60px 0;

  .columns {
    & > * { display: none;}
  }
  .columns {
    & > *:first-child{ display: block; }
  }

  .submit-holder {
    //display: none;

    button.submit-answer {
      margin-left: 20px;
      margin-top: 20px;

      &:disabled {
        opacity: 0.3;
      }
    }
  }
}

.question-wrapper {
  max-width: 319px;
  margin: 0 auto;
  color: $brand-color--black;

  display: none;

  -webkit-box-shadow: 0px 10px 40px 0px rgba(0,0,0,.5);
  -moz-box-shadow: 0px 10px 40px 0px rgba(0,0,0,.5);
  box-shadow: 0px 10px 40px 0px rgba(0,0,0,.5);

  .question-title {
    padding: 20px;
    h2 {
      color: #fff;
      font-size: 19px;
      line-height: 25px;
    }
    img.question-image {
      margin-top: 20px;
    }
  }
  .question-answers {

    margin-bottom: 1px;
    padding: 15px 0 15px 0;
    background-color: #fff;

    input[type="radio"] {
      display: none;
    }

    .answer-text {
      font-size: 15px;
      line-height: 21px;
    }

    .answer {
      padding: 9px 24px 9px 20px;
      position: relative;

      display: block;
      width: 100%;
      text-align: left;

      &:focus {
        outline: none;
      }
      &:hover {
        background-color: #F9F9F9;
        cursor: pointer;
      }
      svg {
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);

        display: none;
      }
      .qustion-circle-selector {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border-width: 3px;
        border-style: solid;

        position: relative;

        margin-right: 10px;
      }
      &.answer-selected {
        background-color: #F9F9F9;
        .qustion-circle-selector {
          &::after {
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 100%;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      &.answer-corect {
        background-color: #F9F9F9;

        .checkmark {
          display: block;
        }

        .qustion-circle-selector {
          &::after {
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 100%;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      &.answer-wrong {
        background-color: #F9F9F9;
        color: rgba(11, 19, 38, .5);

        .the-x {
          display: block;
        }

        .qustion-circle-selector {
          border-color: #0B1326;
          opacity: 0.5;
          &::after {
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background-color: #0B1326;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  .question-hints {
    background-color: #fff;
    padding: 20px;
    position: relative;

    display: none;

    .hint {
      display: none;
    }


    .qustion-smiley {
      position: absolute;
      top: 10px;
      right: 20px;
    }

    .question-hints-title {
      font-size: 15px;
      line-height: 25px;
    }

    .question-hints-text {
      font-size: 13px;
      line-height: 20px;

      margin-bottom: 12px;
    }

    .qustion-smiley {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-position: center;
      background-size: contain;

      &.answer-corect {
        background-image: url(../images/assets/corect_smiley.png);
      }
      &.answer-wrong {
        background-image: url(../images/assets/wrong_smiley.png);
      }
    }
  }
}

.result-wrapper {
  position: relative;

  max-width: 530px;
  margin: 0 auto;
  @include breakpoint(large) {
    padding: 40px 0 40px 150px;
  };
  padding: 20px;
  text-align: center;

  background-color: #fff;
  color: $brand-color--black;

  -webkit-box-shadow: 0px 10px 40px 0px rgba(0,0,0,.5);
  -moz-box-shadow: 0px 10px 40px 0px rgba(0,0,0,.5);
  box-shadow: 0px 10px 40px 0px rgba(0,0,0,.5);

  .result-title {
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 9px;
  }

  .result-text {
    margin-bottom: 45px;
    text-align: left;
    @include breakpoint(large) {
      max-width: 300px;
    }
    p {
      font-size: 15px;
      line-height: 25px;
    }
  }

  .result-holder-wrapper {

    @include breakpoint(large) {
      position: absolute;
      top: -68px;
      left: -130px;
    }

    display: inline-block;
    margin-bottom: 20px;

    width: 260px;
    height: 260px;
    padding: 52px 20px;

    -webkit-box-shadow: 0px 10px 40px 0px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 10px 40px 0px rgba(0,0,0,.5);
    box-shadow: 0px 10px 40px 0px rgba(0,0,0,.5);

    color: #fff;

    .result-holder {
      font-size: 62px;
      text-align: center;
    }

    .result-holder-text {
      font-size: 13px;
      line-height: 20px;
      text-align: center;
    }
  }

}
