/*
Global Navigation

The global navigation needs to be positioned fixed in the top of the page.
The page should have a top margin that equals the height of the global navigation.

The link in the navigation corresponding to the current page, should have the 'active' class added to its parent li.

A script should be hooked up to the navigation to toggle the offcanvas nav,
secondary nav and search.
The script should also toggle the condensed modifier when the page scrolls.

default - default view of the global navigation
global-navigation--condensed - Condenses the height of the nav bar and substitutes
the logo for a "play your visit" element when on a mobile phone.
global-navigation--sec-nav-open - class that toggles to open the secondary navigation
global-navigation--search-open - class that toggles to open the search

Markup:
header.global-navigation(class="{$modifiers}")
  .global-navigation__nav-bar
    .row.align-middle.align-justify
      .columns.global-navigation__nav
        .site-logo
          | LOGO
        a.text-icon.plan-visit
          svg
            use(xlink:href="images/assets/SVGSpritesheet.svg#calendar")
          | besøg os
        nav.global-navigation__nav-items
          ul
            li.active
              a(href="#") Oplev Experimentarium
            li
              a(href="#") Naturvidenskab og teknologi
      .columns.small-4.center
        a.text-icon
          svg
            use(xlink:href="images/assets/SVGSpritesheet.svg#ticket")
          | Køb billet
      .columns.small-4.right
        .global-navigation__secondary-nav
          nav
            ul
              li
                a(href="#") Om os
              li
                a(href="#") Til virksomheder
              li
                a(href="#") Til skoler
              li
                a(href="#") Presse
              li
                a(href="#") English
          div.menu-toggler
            svg.close
              use(xlink:href="images/assets/SVGSpritesheet.svg#cross")
            .dot-menu
              span
        .global-navigation__webshop
          span webshop
          button.cart-btn
            svg
              use(xlink:href="images/assets/SVGSpritesheet.svg#cart")
        .global-navigation__search, .global-navigation__search-mobile
          form.search-field
            input(type="text" placeholder="Søg på sitet")
            button(type="submit")
              svg
                use(xlink:href="images/assets/SVGSpritesheet.svg#search")
          button.search-btn
            svg.open
              use(xlink:href="images/assets/SVGSpritesheet.svg#search")
            svg.close
              use(xlink:href="images/assets/SVGSpritesheet.svg#cross")
        .burger(data-js="toggle-off-canvas")
          .burger__logo
            span
          span.burger__text
            | Menu
  .row.align-right
    .global-navigation__extra-info
      .visitor-info.visit(class="{$modifiers}")
        svg
          use(xlink:href="images/assets/SVGSpritesheet.svg#calendar")
        div
          p I dag er vi åbne
            span.time  kl. 09.00 - 21.00
          strong
            | Planlæg dit besøg
      .visitor-info.visitor-info--themed.ticket
        svg
          use(xlink:href="images/assets/SVGSpritesheet.svg#ticket")
        div
          strong
            | Køb billet online
          p
            | og spring køen over.

Styleguide 3.2

*/

.global-navigation {
  width: 100%;
  user-select: none;

  .center {
    text-align: center;
    @include breakpoint(medium) {
      display: none;
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .global-navigation__secondary-nav {
      display: none;
    }

    .global-navigation__search, .global-navigation__search-mobile {
      display: none;
    }
    .global-navigation__webshop {
      display: none;
    }
    @include breakpoint(medium) {
      justify-content: space-around;
    }
    @include breakpoint(large) {
      padding-bottom: $spacing-small;
      justify-content: flex-end;

      .burger {
        display: none;
      }

      .global-navigation__secondary-nav {
        display: block;
        margin-left: -300px;
      }

      .global-navigation__search, .global-navigation__search-mobile {
        display: block;
      }
      .global-navigation__webshop {
        display: flex;
      }
    }
  }

  .text-icon {
    color: $color-midnight;
    text-transform: uppercase;
    font-size: 0.87em;
    white-space: nowrap;
    display: block;
    text-align: center;

    svg {
      width: 30px;
      height: 30px;
      display: block;
      margin: 0 auto;
    }
  }
}

.global-navigation__nav {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  .site-logo {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    opacity: 1;
    transition: $global-transition;

    @include breakpoint(medium) {
      position: static;
      transform: none;
    }
  }

  .plan-visit {
    position: absolute;
    top: 50%;
    left: $spacing-small;
    transform: translate(100%, -50%);
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in;
  }
}

.global-navigation__nav-items {
  opacity: 1;
  transition: $global-transition;
  display: none;
  height: 100%;


  @include breakpoint(large) {
    display: block;
  }

  ul {
    height: 100%;
    display: flex;

    @include breakpoint(xlarge) {
      margin-left:$spacing-small;
    }

    li{
      height: 100%;
      @extend .header--nano;
      font-weight: 500;
      margin-bottom:0;
      position: relative;
      display: flex;
      align-items: flex-end;
      a{
        display: inline-block;
        padding-bottom: $spacing-small*0.75;
        transition:  $global-transition;
        &:hover{
          color:$color-theme-primary;
          transition:  $global-transition;
        }
      }
      @include breakpoint(medium) {
        // align-items: flex-end;
        font-size: 1em;
      }
      @include breakpoint(xlarge) {
        // align-items: flex-end;
        font-size: 1.25em;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $color-theme-primary;
        opacity: 0;
        transition:  $global-transition;
      }

      &.active {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  li {
    margin-left: $spacing-small;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.global-navigation__secondary-nav') {
    li {
      &.active {
        border-bottom: 1px solid $color;
      }
    }
  }
}

.global-navigation__secondary-nav {
  position: relative;
  opacity: 1;
  transition:  $global-transition;

  ul {
    display: flex;
    justify-content: flex-end;
  }

  li {
    flex-shrink: 0;
    margin-right: $spacing-small;
    line-height: 1;
    @extend .text--small;
    text-transform: uppercase;
    a {
      transition: opacity 0.3s cubic-bezier(.01,.96,.58,1);
      transform: translateY(-10px);
      line-height: 24px;
      transition:  $global-transition;
      &:hover{
        color:$color-theme-primary;
        transition:  $global-transition;
      }
    }
  }

  .menu-toggler {
    .close {
      width: 24px;
      height: 24px;
      padding: 5px;
      display: none;
    }
  }

  @include breakpoint(xlarge) {
    position: relative;
    height: 24px;

    nav {
      display: block;
    }

    .menu-toggler {
      display: none;
    }
  }
}
.global-navigation__webshop {
  display: flex;
  align-items:center;
  margin-right: $spacing-small;
  @extend .text--small;
  text-transform: uppercase;

  a {
    display: flex;
    transition: $global-transition;
    .cart-btn {
      width: 19px;
      height: 16px;
      margin-left: $spacing-small*0.25;
      position: relative;
      top: 2px;
      transition: $global-transition;
    }
    &:hover {
      color: $color-theme-primary;
      transition: $global-transition;
      svg {
        fill: $color-theme-primary;
      }
    }
  }
}


.global-navigation__search {
  .search-field {
    position: absolute;
    left: -$spacing-small/2;
    top: 0;
    height: 100%;
    width: 250px;
    transform: translateX(-100%);
    pointer-events: none;
    opacity: 0;
    border-bottom: 1px solid black;
    display: flex;
    background: white;
    transition:  $global-transition;
  }
}


.global-navigation__search, .global-navigation__search-mobile {
  position: relative;

  .search-btn {
    width: 24px;
    height: 24px;
    padding: 5px;
    margin-top: 3px;
    transition:  $global-transition;
    svg {
      width: 100%;
      height: 100%;
    }

    &:focus {
      outline: 0;
    }
    &:hover{
      transition:  $global-transition;
      svg{
        fill:$color-theme-primary;
      }
    }
  }

  .close {
    display: none;
  }

  .search-field {

    border-bottom: 1px solid black;

    input.input-text--search {
      margin-bottom: 0;
      height: 100%;
      padding: 0;
      border: none;
      box-shadow: none;
      flex-grow: 1;
      flex-shrink: 1;
      background: none;
      outline: none;
    }

    button {
      flex-grow: 1;
      flex-shrink: 1;
      width: 24px;
      height: 24px;
      padding: 5px;
      transition:  $global-transition;
      &:hover{
        transition:  $global-transition;
        svg{
          fill:$color-theme-primary;
        }
      }

      svg {
        width: 100%;
        height: 100%;
      }

      &:focus {
        outline: 0;
      }
    }
  }
}

.global-navigation__search-mobile {
  .search-field {
    border-bottom: 1px solid #fff;

    button {
      flex-grow: 1;
      flex-shrink: 1;
      width: 24px;
      height: 24px;
      padding: 5px;
      transition:  $global-transition;
      &:hover{
        transition:  $global-transition;
        svg{
          fill: #fff;
        }
      }
      svg {
        width: 100%;
        height: 100%;
        fill: #fff;
      }
      &:focus {
        outline: 0;
      }
    }
    .input-text--search {
      width: 90%;
      color: #fff;
    }
  }

}

.global-navigation__nav-bar {
  background-color: white;
  box-shadow: $global-dropshadow--02;
  height: $nav-height-small;
  transition:  $global-transition;

  @include breakpoint(large) {
    height: $nav-height-large;
  }

  @include breakpoint(xlarge) {
    height: $nav-height-xlarge;
  }

  .row {
    height: 100%;
  }
}

// new ticket button for tablets
.global-navigation_ticket--tablet {
  display: none;
  @include breakpoint(medium) {
    display: block;
  }
  @include breakpoint(large) {
    display: none;
  }
}


.global-navigation__extra-info {
  display: flex;
  width: 100%;
  box-shadow: $global-dropshadow--01;
  position: fixed;
  z-index: 999;

  @include breakpoint(medium) {
    width: 100%;
    position: fixed;
    right: 0;
    z-index: 999;
  }

  @include breakpoint(large) {
    margin: 0;
    transform: translateY(-40%);
    position: fixed;
    right: 0;
    z-index: 999;
    width: auto;
    body.is-logged.customize-support & {
      transform: translateY(0%);
    }
  }
  @include breakpoint(xlarge) {
    margin: 0 $spacing-small;
    transform: translateY(-40%);
    position: fixed;
    right: 0;
    z-index: 999;
    body.is-logged.customize-support & {
      transform: translateY(0%);
    }
  }
  @media screen and (min-width: 1024px) and (max-width:1240px) {
    // transform: translateY(0%);
    display: none;
  }
  .info {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .ticket {
    display: none;
    padding: 0;
    @include breakpoint(large) {
      display: flex;
    }
  }

  .visit {
    width: 100%;

    @include breakpoint(medium) {
      width: 100%;
    }
    @include breakpoint(large) {
      width: auto;
    }

    svg, strong {
      // display: none;

      @include breakpoint(medium) {
        display: block;
      }
    }

    .time {
      font-weight: bold;

      @include breakpoint(medium) {
        display: inline-block;
      }
    }
  }
}

.global-navigation--sec-nav-open {
  .global-navigation__nav-items {
    opacity: 0;
    pointer-events: none;
  }
  .global-navigation__secondary-nav {
    nav {
      display: block;
    }

    li {
      a {
        pointer-events: all;
        opacity: 1;
        transform: translateY(0);
      }
    }

    .menu-toggler {
      &:hover{
        cursor: pointer;
        .close{
          fill:$color-theme-primary;
        }
      }
      .close {
        display: block;
      }
      .dot-menu {
        display: none;
      }
    }
  }
}

.global-navigation--search-open {
  .global-navigation__secondary-nav {
    opacity: 0;
    pointer-events: none;
  }
  .global-navigation__search, .global-navigation__search-mobile {
    .search-field {
      opacity: 1;
      pointer-events: all;
    }

    .open {
      display: none;
    }

    .close {
      display: block;
    }
  }
}

.global-navigation--condensed {
  .global-navigation__nav-bar {
    @include breakpoint(large) {
      height: 70px;
    }

    @include breakpoint(xlarge) {
      height: 80px;
    }
  }

  .global-navigation__nav {
    .global-navigation__nav-items{
      ul{
        li{
          a{
            padding-bottom: $spacing-small*0.25;
          }
        }
      }
    }

    .site-logo {
      position: absolute;
      top: 50%;
      transform: translate( -100%, -50%);
      opacity: 0;
      pointer-events: none;
      @include breakpoint(medium) {
        transform: none;
        position: static;
        opacity: 1;
        pointer-events: all;
      }
    }

    .plan-visit {
      transform: translate(0, -50%);
      opacity: 1;
      pointer-events: all;

      @include breakpoint(medium) {
        display: none;
      }
    }
  }
  .global-navigation__extra-info {
    display: none;

    @include breakpoint(medium) {
      display: flex;
    }
  }
  #mega-menu-wrap-main-menu, #mega-menu-wrap-main-menu-en {
    @include breakpoint(large) {
      top: 37px;
    }
    @include breakpoint(xlarge) {
      top: 43px;
    }

    transition:  $global-transition;
    #mega-menu-main-menu > li.mega-menu-item > a.mega-menu-link {
      height: 37px;
    }
  }
}


// mega menu styles
#mega-menu-wrap-main-menu, #mega-menu-wrap-main-menu-en {
  @include breakpoint(large) {
    background-color: transparent;
    top: 53px;
    color: inherit;
    transition:  $global-transition;
  }
}
#mega-menu-wrap-main-menu #mega-menu-main-menu,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en {

  @include breakpoint(large) {
    color: inherit;
    margin-left: 30px;
    width: 820px;
    position: absolute;
    li.mega-menu-item {
      color: inherit;
      a.mega-menu-link {
        color: inherit;
        text-transform: none !important;
        font-size: 20px !important;
        &:hover,
        &:focus {
          background: none;
        }
        &:focus {
          color: inherit;
        }
      }
      &.mega-current-menu-item.mega-current_page_item {
        a.mega-menu-link {
          text-decoration: underline !important;
        }
      }
      &.mega-current-menu-item {
        a.mega-menu-link {
          text-decoration: underline !important;
        }
      }
      &.mega-menu-item-has-children {
        & > .mega-sub-menu > li.mega-menu-item > a.mega-menu-link {
          font-size: 13px !important;
          line-height: 1.85em;
          font-weight: bold !important;
          padding-bottom: 6px !important;
        }
        a.mega-menu-link {
          font-size: 20px !important;
          font-weight: 500 !important;
          line-height: 1.3;
          @media screen and (max-width:1380px) {
            font-size: 16px !important;
            line-height: 30px;
          }
        }
      }
    }
  }
}
#mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-item > a.mega-menu-link,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-item > a.mega-menu-link {
  // top level items
  @include breakpoint(large) {
    font-size: 20px;
    line-height: 26px;
    height: 47px;
    padding-right: 0;
  }
  // @media screen and (max-width:1380px) {
  //   font-size: 16px;
  // }
}
#mega-menu-wrap-main-menu li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu,
#mega-menu-wrap-main-menu li.mega-menu-item.mega-menu-flyout ul.mega-sub-menu,
#mega-menu-wrap-main-menu-en li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu,
#mega-menu-wrap-main-menu-en li.mega-menu-item.mega-menu-flyout ul.mega-sub-menu {
  // top level submenu
  @include breakpoint(large) {
    position: fixed !important;
    width: 100%;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    transition:  $global-transition;
  }
}
#mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-flyout.mega-menu-item-has-children > a.mega-menu-link:after,
#mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-flyout li.mega-menu-item-has-children > a.mega-menu-link:after,
#mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-item-has-children > a.mega-menu-link:after,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-flyout.mega-menu-item-has-children > a.mega-menu-link:after,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-flyout li.mega-menu-item-has-children > a.mega-menu-link:after,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-item-has-children > a.mega-menu-link:after {
  content: '';
}
#mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link,
#mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link {
 color: #fff;
 padding-bottom: 17px;
}
#mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link:hover,
#mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link:hover,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link:hover,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link:hover {
 text-decoration: underline;
}

#mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row {
  @include breakpoint(large) {
    max-width: 1340px ;
    margin: 0 auto;
    float: none !important;
    padding-left: 150px;
    display: flex;
    align-items: center;
    justify-items: center;
  }
}
#mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column {
  @include breakpoint(large) {
    margin-left: 0;
  }
}
#mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-item.mega-menu-megamenu ul.mega-sub-menu ul.mega-sub-menu,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-item.mega-menu-megamenu ul.mega-sub-menu ul.mega-sub-menu {
  width: 100%;
}
#mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item {
  padding: 0px 0px 0px 15px;
}
#mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link:hover,
#mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link:hover {
  text-decoration: underline;
}
// mobile stuff



.off-canvas-menu__primary {
  #mega-menu-main-menu,
  #mega-menu-main-menu-en {
    margin-top: 22px !important;
  }
  #mega-menu-wrap-main-menu .mega-menu-toggle,
  #mega-menu-wrap-main-menu-en .mega-menu-toggle {
    display: none;
  }
  #mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-item,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-item {
    display: inline-block;
    margin: 0;
    clear: both;
    border: 0;
    width: 100%;
    vertical-align: top;
    a.mega-menu-link {
      font-size: 20px;
      line-height: 2.25em;
      color: #fff !important;
      margin: 10px;
      background: transparent;
    }
  }
  #mega-menu-wrap-main-menu .mega-menu-toggle + #mega-menu-main-menu li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu,
  #mega-menu-wrap-main-menu-en .mega-menu-toggle + #mega-menu-main-menu-en li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu {
    width: 100%;
    background: rgba(0,0,0,.3);
    padding: 0 20px;
    position: relative;
    display: inline-block;
  }
  #mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row {
    padding: 0;
  }
  #mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row > ul.mega-sub-menu > li.mega-menu-column,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row > ul.mega-sub-menu > li.mega-menu-column {
    width: 100%;
    padding: 0;
  }
  #mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-flyout.mega-menu-item-has-children > a.mega-menu-link:after,
  #mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-flyout li.mega-menu-item-has-children > a.mega-menu-link:after,
  #mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-item-has-children > a.mega-menu-link:after,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-flyout.mega-menu-item-has-children > a.mega-menu-link:after,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-flyout li.mega-menu-item-has-children > a.mega-menu-link:after,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-item-has-children > a.mega-menu-link:after {
    content: '';
  }
  #mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  #mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column:last-child > ul.mega-sub-menu:last-child > li.mega-menu-item:last-child,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column:last-child > ul.mega-sub-menu:last-child > li.mega-menu-item:last-child {
    border-bottom: 0px !important;
  }
  #mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link,
  #mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link {
    display: none;
  }
  #mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link,
  #mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link,
  #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link {
    padding-bottom: 0;
    text-transform: none;
    font-size: 16px;
    font-weight: normal;
  }
}


@each $theme, $color in $themes {
  @include theme($theme, $color, '.global-navigation') {
    &__nav-items {
      ul {
        li {
          &:after {
            background-color: $color;
          }
        }
      }
    }
    .global-navigation__webshop{
      a{
        &:hover{
          color:$color;
          svg{
            fill:$color;
          }
        }
      }
    }
    .search-btn{
      &:hover{
        svg{
          fill:$color;
        }
      }
    }
    .search-field{
      button{
        &:hover{
          svg{
            fill:$color;
          }
        }
      }
    }
  }
}
@each $theme, $color in $themes {
  @include theme($theme, $color, '.menu-toggler') {
    &:hover{
      svg{
        fill:$color !important;
      }
    }
    &__secondary-nav {
      li {
        &.active {
          border-color: $color;
        }
      }
    }
  }
}
@each $theme, $color in $themes {
  @include theme($theme, $color, '.global-navigation__nav-bar') {
    .global-navigation__nav-items, .global-navigation__secondary-nav{
      ul{
        li{
          a{
            &:hover{
              color:$color;
            }
          }
        }
      }
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '#mega-menu-wrap-main-menu') {
    background: $color;
    #mega-menu-main-menu {
      li{
        a{
          &:hover{
            color: $color !important;
          }
        }
        &.mega-toggle-on > a.mega-menu-link {
          color: $color !important;
          background: none;
        }
        &.mega-menu-megamenu > ul.mega-sub-menu {
          background: $color;
          box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.25);
          li > a {
            color: #fff !important;
            &:hover {
              color: #fff !important;
              text-decoration: underline;
            }
          }
        }
      }
      & > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link,
      & > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link,
      & > li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link {
        text-decoration: underline;
        background: none;
        color: inherit;
      }
      ul.mega-sub-menu {
        background: $color;
        li {
          a {
            color: #fff;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '#mega-menu-wrap-main-menu-en') {
    background: $color;
    #mega-menu-main-menu-en {
      li{
        a{
          &:hover{
            color: $color !important;
          }
        }
        &.mega-toggle-on > a.mega-menu-link {
          color: $color !important;
          background: none;
        }
        &.mega-menu-megamenu > ul.mega-sub-menu {
          background: $color;
          box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.25);
          li > a {
            color: #fff !important;
            &:hover {
              color: #fff !important;
              text-decoration: underline;
            }
          }
        }
      }
      & > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link,
      & > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link,
      & > li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link {
        text-decoration: underline;
        background: none;
        color: inherit;
      }
      ul.mega-sub-menu {
        background: $color;
        li {
          a {
            color: #fff;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.off-canvas-menu') {
    #mega-menu-wrap-main-menu #mega-menu-main-menu > li.mega-menu-item.mega-toggle-on > a.mega-menu-link,
    #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en > li.mega-menu-item.mega-toggle-on > a.mega-menu-link {
      color: #fff !important;
      background: none;
    }
    #mega-menu-wrap-main-menu #mega-menu-main-menu li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item,
    #mega-menu-wrap-main-menu-en #mega-menu-main-menu-en li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item {
      border-bottom: 1px solid $color;
    }
  }
}
