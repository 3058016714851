// Quotes
//
// A quote block from the WYSIWYG editor.
// the quote has the following modifiers:
//
// default - a normal quote
// .quote--inverted - inverts the color scheme
//
// markup:
//  <blockquote class="quote {$modifiers}">
//    <div class="row align-center">
//      <div class="quote__content columns small-12 medium-10">
//        <div class="quote__icon">
//          <svg class="svg-icon"><use xlink:href="images/assets/SVGSpritesheet.svg#quote"></use></svg>
//        </div>
//        <p>Quote tonight half of southern Japan is hunched down awaiting the arrival of the typhoon. This is #15 for the Pacific region this year, and the third one in about six weeks to come our way.</p>
//        <footer>Cited <span class="author">Ronald Nunez</span></footer>
//      </div>
//    </div>
//  </blockquote>
//<div class="color-theme-container-for-the-element theme-red">
//  <blockquote class="quote {$modifiers}">
//    <div class="row align-center">
//      <div class="quote__content columns small-12 medium-10">
//        <div class="quote__icon">
//          <svg class="svg-icon"><use xlink:href="images/assets/SVGSpritesheet.svg#quote"></use></svg>
//        </div>
//        <p>Quote tonight half of southern Japan is hunched down awaiting the arrival of the typhoon. This is #15 for the Pacific region this year, and the third one in about six weeks to come our way.</p>
//        <footer>Cited <span class="author">Ronald Nunez</span></footer>
//      </div>
//    </div>
//  </blockquote>
//</div>
//
// Styleguide 2.1

.quote {
  color: $color-theme-primary;
  padding-top: $spacing-small * 2;
  padding-bottom: $spacing-small * 2;

  @include breakpoint(large) {
    padding-top: $spacing-small * 3;
    padding-bottom: $spacing-small * 3;
  }

  &.quote--inverted {
    background-color: currentColor;

    .quote__content {
      color: white;

      .author {
        border-color: white;
      }
    }

    .quote__icon {
      background-color: white;

      .svg-icon {
        fill: $color-theme-primary;
      }
    }
  }
}

.quote__content {
  position: relative;
  padding-left: 40px + $spacing-small;
  padding-right: 40px + $spacing-small;
  padding-top: $spacing-small;

  p {
    @include breakpoint(large) {
      font-size: 1.4em;
    }
  }

  footer {
    font-size: 0.86em;
  }

  .author {
    display: inline-block;
    border-bottom: 1px solid $color-theme-primary;
  }

  @include breakpoint(large) {
    padding-left: 80px + $spacing-small;
    padding-right: 30%;
  }
}

.quote__icon {
  position: absolute;
  left: 0;
  top: 0;
  background-color: $color-theme-primary;
  color: white;
  width: 40px;
  height: 40px;
  padding: 10px;

  .svg-icon {
    fill: white;
  }

  @include breakpoint(large) {
    width: 80px;
    height: 80px;
    padding: 20px;
  }
}


@each $theme, $color in $themes {
  @include theme($theme, $color, '.quote') {
    color: $color;

    .quote__icon {
      background-color: $color;
    }

    .quote__content {
      .author {
        border-color: $color;
      }
    }

    &.quote--inverted {
      .quote__icon {
        background-color: white;

        .svg-icon {
          fill: $color;
        }
      }
    }
  }
}

/***********************************************
New styles using for tamplates in wysiwyg editor
*************************************************/

.quotes {
  padding-top: 90px;
  padding-bottom: 60px;
  .quote-text {
    font-size: 21px;
    letter-spacing: 0px;
    line-height: 31px;

    margin-bottom: 25px;
  }
  .quite-author {
    a {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      padding-bottom: 3px;
      &:hover {
        border: none;
      }
    }
  }
  .column {
    position: relative;
  }
  .quite-icon {
    float: left;
    margin-right: 20px;
    svg {
      width: 40px;
      height: 40px;
      transform: rotate(180deg);
    }
  }

  //Desktop styles
  @include breakpoint('medium'){
    .column {
      padding-left: 60px;
    }

    .quite-icon {
      svg {
        width: auto;
        height: auto;
      }
    }
  };
  @include breakpoint('xlarge'){
    .quite-icon {
      margin-right: 0;
      position: absolute;
      top: -28px;
      left: -40px;
    }
  };
}

//Theme coloring handle
@each $theme, $color in $themes {
  @include theme($theme, $color, '.quotes') {
    &.with-bg {
      background-color: $color;

      .quote-text {
        color: #fff;
      }

      .quite-author {
        color: #fff;
      }

      .quite-icon {
        svg {
          rect {
            fill: #fff;
          }
          path {
            fill: $color;
          }
        }
      }
    }

    .quote-text {
      color: $color;
    }

    .quite-author {
      color: $brand-color--black;
    }

    .quite-icon {
      svg {
        rect {
          fill: $color;
        }
        path {
          fill: #fff;
        }
      }
    }
  }
}
