/*
Burger Logo

Markup:
.burger
  .burger__logo
    span
  span.burger__text
  | Menu

Styleguide 1.11

*/

.burger {
  line-height: 1;
  cursor: pointer;
  padding-top: 8px;
}

.burger__logo {
  width: 40px;
  margin: 0 auto;
  @include burger(30px, 3px, 5px, #222);
    span {
      transform: translateX(-6px);
    }
    &:before, &:after {
      transform: translateX(6px);
    }
}

.burger__text {
  color: $color-midnight;
  text-transform: uppercase;
  margin-top: 7px;
  display: block;
  font-size: 0.87em;
}

.burger--to-cross {
  .burger__logo {
    @include burger-to-cross;
  }
}
