/*
Thumbnail default

A collection for different type post type in their default thumbnail form: events, product, exhibition and normal article

Markup:
.row.align-center
  .small-12.medium-6.large-4.columns
    .thumbnail-default--event
      a(href="#")
        .thumbnail__image
          img(src="images/image-10.jpg")
        .thumbnail__content
          h3.header--smaller Kan du få en regnbue til at forsvinde?
          p.text--small.meta.dates 10, Aug, 2017 - 11, Aug, 2017
          p.description Det er tungt at løfte en sæk på 65 kg – for nogle er det umuligt. Men med én talje eller to kan selv slapsvanse løfte 65 kg. Prøv selv!
          .tags.tags--neutral
            svg.svg-icon.icon--tag
              use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
            .tag-group
              span.tag tags
              span.tag tags
  .small-12.medium-6.large-4.columns
    .thumbnail-default--product
      a(href="#")
        .thumbnail__image
          img(src="images/image-08.jpg")
        .thumbnail__content
          h3.header--smaller Teleskop byggesæt
          p.description Byg dit eget teleskop se helt op til månen.
          button.label-btn(data-text="Se og køb")
            span.label-btn__text Se og køb
            span.label-btn__icon
              svg.svg-icon
                use(xlink:href="images/assets/SVGSpritesheet.svg#cart")
            span.label-btn__label 899 DKK
          .tags.tags--neutral
            svg.svg-icon.icon--tag
              use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
            .tag-group
              span.tag tags
              span.tag tags
  .small-12.medium-6.large-4.columns
    .thumbnail-default--exhibition
      a(href="#")
        .thumbnail__image
          img(src="images/image-06.jpg")
        .thumbnail__content
          h3.header--smaller Kan du løfte en tung sæk?
          p.text--small.meta.dates Udstilling
          p.description Det er tungt at løfte en sæk på 65 kg – for nogle er det umuligt. Men med én talje eller to kan selv slapsvanse løfte 65 kg. Prøv selv!
          .tags.tags--neutral
            svg.svg-icon.icon--tag
              use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
            .tag-group
              span.tag Jorden
              span.tag magna at volutpat
              span.tag sodales
              span.tag magna tellus
  .small-12.medium-6.large-4.columns
    .thumbnail-default--event
      a(href="#")
        .thumbnail__image
          img(src="images/image-01.jpg")
        .thumbnail__content
          h3.header--smaller Aenean felis lacus, volutpat eget erat eu
          p.text--small.meta.dates 10, Aug, 2017
          p.description Med dette forsøg kan du få regnbuens syv farver til at forsvinde. Samtidig får du en fantastisk snurretop.
          .tags.tags--neutral
            svg.svg-icon.icon--tag
              use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
            .tag-group
              span.tag Eksperiment
              span.tag Mennesket

Styleguide 2.5
*/

div[class^='thumbnail-default']{
  background-color: white;
  margin-bottom:$spacing-medium;
  perspective: 100px;
  backface-visibility: hidden;
  a{
    display: block;
    box-shadow: $global-dropshadow--default;
    background-color: white;
    padding-bottom:$spacing-small;
    transition: $global-transition;
    transform: scale(1);
    transform-origin: center;
    .thumbnail__content{
      margin: $spacing-small $spacing-small 0;
      h3{
        @extend .header--smaller;
        margin-bottom: 0.75em;
      }
      p.meta{
        margin-bottom:$spacing-small*0.25;
      }
      .tags{
        margin:1em 0;
      }
      >*{
      }
    }
    &:hover{
      transform: scale($global-scale);
      box-shadow: $global-dropshadow--hover;
      transition: $global-transition;
    }
  }
}
.thumbnail-default--product{
  button.label-btn{
    margin-bottom:$spacing-small*0.5;
  }
}
