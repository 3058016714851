/*
Links

This section demonstrates how links are styled.

markup:
a(class="link" href="#") Click me

Styleguide 1.8
*/

//.link:not(.btn) {
//  display: inline;
//  position: relative;
//  color:$color-theme-primary;
//  border-bottom: 2px solid rgba($brand-color--black, 0.2);
//  transition: $global-transition;
//  &:hover{
//    border-color: $color-theme-primary;
//    color:$brand-color--black;
//    transition: $global-transition;
//  }
//  &--invert{
//    color:white;
//    border-bottom: 2px solid rgba($color-theme-primary, 0.4);
//    &:hover{
//      color:white;
//    }
//  }
//}


@each $theme, $color in $themes {
  @include theme($theme, $color, '.tag-link-in-hero') {
    border-bottom: 2px solid $color;
    &:hover {
      border: none;
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.link') {
    color:$color;
    &:hover{
      border-color: $color;
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.link--invert') {
    color:white;
    border-color:rgba($color, 0.4);
  }
}
