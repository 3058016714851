// Form inputs
//
// This sections demonstrates the different form inputs in their different states
//
// Again the color is inherited from the theme option.
//
// There is also a customized input and button input group in this section.
//
// There is only basic css styling for the native checkbox and radio buttons.
//
// default - a normal form
//
// markup:
// <div class="theme-default">
//   <input type="text" placeholder="placeholder text" class="input-text">
//   <div class="input-group">
//     <input class="input-group-field input-text" type="text" placeholder="placeholder text">
//     <div class="input-group-button">
//       <input type="submit" class="button btn" value="Submit">
//     </div>
//   </div>
//   <div class="row">
//     <fieldset class="small-12 large-6 columns">
//       <legend>Choose Your Favorite</legend>
//       <input type="radio" name="pokemon" value="Red" id="pokemonRed" required><label for="pokemonRed">Red</label>
//       <input type="radio" name="pokemon" value="Blue" id="pokemonBlue"><label for="pokemonBlue">Blue</label>
//       <input type="radio" name="pokemon" value="Yellow" id="pokemonYellow"><label for="pokemonYellow">Yellow</label>
//     </fieldset>
//     <fieldset class="small-12 large-6 columns">
//       <legend>Check these out</legend>
//       <input id="checkbox1" type="checkbox"><label for="checkbox1">Checkbox 1</label>
//       <input id="checkbox2" type="checkbox"><label for="checkbox2">Checkbox 2</label>
//       <input id="checkbox3" type="checkbox"><label for="checkbox3">Checkbox 3</label>
//     </fieldset>
//   </div>
// </div>
//
// Styleguide 1.6


// Form inputs invert
//
// This sections demonstrates the different form inputs in their inverted forms.
//
// input-text--invert - invert the color to white and hover color to its respective background color.
//
// markup:
// <div class="theme-default">
//   <div class="row align-center highlight">
//     <fieldset class="small-12 large-8 columns">
//       <input type="text" placeholder="placeholder text" class="input-text {$modifiers}">
//       <div class="input-group">
//         <input class="input-group-field input-text {$modifiers}" type="text" placeholder="placeholder text">
//         <div class="input-group-button">
//           <input type="submit" class="button btn btn--invert" value="Submit">
//         </div>
//       </div>
//     </div>
//     <div class="row align-center highlight">
//       <fieldset class="small-12 large-4 columns">
//         <legend>Choose Your Favorite</legend>
//         <input type="radio" name="pokemon" value="Red" id="pokemonRed" required><label for="pokemonRed">Red</label>
//         <input type="radio" name="pokemon" value="Blue" id="pokemonBlue"><label for="pokemonBlue">Blue</label>
//         <input type="radio" name="pokemon" value="Yellow" id="pokemonYellow"><label for="pokemonYellow">Yellow</label>
//       </fieldset>
//       <fieldset class="small-12 large-4 columns">
//         <legend>Check these out</legend>
//         <input id="checkbox1" type="checkbox"><label for="checkbox1">Checkbox 1</label>
//         <input id="checkbox2" type="checkbox"><label for="checkbox2">Checkbox 2</label>
//         <input id="checkbox3" type="checkbox"><label for="checkbox3">Checkbox 3</label>
//       </fieldset>
//     </div>
//   </div>
// </div>
//
// Styleguide 1.7



.input-text{
  padding:$spacing-small*0.625 $spacing-small*0.5;
  // margin-bottom:1em;
  border: 0.175em solid;
  border-color:rgba($color-theme-primary, 0.85);
  color:$color-theme-primary;
  background-color:rgba($color-theme-primary, 0.15);
  box-shadow: none;
  line-height: 1rem;
  height: auto;
  -webkit-appearance: none;
  &:focus{
    border: 0.175em solid;
    border-color:$color-theme-primary;
    outline: none;
    box-shadow:none;
  }
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgba($color-theme-primary, 0.85);
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: rgba($color-theme-primary, 0.85);
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: rgba($color-theme-primary, 0.85);
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: rgba($color-theme-primary, 0.85);
  }
  &--invert{
    border-color:rgba(white, 0.85);
    color:$color-theme-primary;
    background-color: rgba(white, 0.85);
    &:focus{
      border-color:darken($color-theme-primary, 7.5);
      color:white;
      background-color: darken($color-theme-primary, 7.5);
    }
  }
}
legend{
  @extend .header--smaller;
}
label{
  color:$color-theme-primary;
}
fieldset{
  margin:$spacing-small 0 $spacing-medium;
}

.input-group{
  .input-group-field{
    border-right: none;
    &:focus{
      border-right: none;
    }
    &.input-text {
      @include breakpoint(small) {
        max-width: 176px;
      }
    }
  }
  .input-group-button {
    .button {
      @include breakpoint(small) {
        padding-left: $spacing-small;
        padding-right: $spacing-small;
      }
    }
  }
  @include breakpoint(large) {
    max-width: 360px;
  }
  .align-center &{
    margin-left:auto;
    margin-right:auto;
  }
}
.newsletterband-checkbox--agree {
  .checkboxFiveInputBand {
    visibility: hidden;
    display: none;
  }
  label {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    top: 0;
    left: 0;
    margin-left: 0;
    margin-right: 3px;
    background: rgba(255, 255, 255, 0.85);
    border: 1px solid #ddd;
    span {
      opacity: 0.1;
      content: '';
      position: absolute;
      width: 12px;
      height: 6px;
      background: transparent;
      top: 3px;
      left: 1px;
      border: 3px solid #333;
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);

    }
    &:hover span {
      opacity: 0.5;
    }
    input[type=checkbox]:checked + span {
      opacity: 1;
      border-color: #38b11b;
    }
  }
  // input[type=checkbox]:checked + label:after {
  //   opacity: 1;
  //   border-color: #38b11b;
  // }
  label > input[type=checkbox]:checked &:after {
    opacity: 1;
    border-color: #38b11b;
  }
  .newsletter-label--title {
    font-size: 0.8em;
    display: inline;
    margin-top: 0px;
    padding-left: 0px;
    vertical-align: middle;
  }
  p.this-i-presume-should-be-hidden {
    opacity: .4;
    font-size: 0.8em;
    &:hover {
      opacity: 1;
    }
    a {
      text-decoration: underline;
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.input-text') {
    border-color: rgba($color, 0.85);
    color:$color;
    background-color: rgba($color, 0.15);
    &:focus{
      border-color:$color;
      background-color: white;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba($color, 0.85);
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: rgba($color, 0.15);
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: rgba($color, 0.15);
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: rgba($color, 0.15);
    }
  }
  @include theme($theme, $color, 'label') {
    border-color: $color;
    color:$color;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.input-text--invert') {
    border-color:rgba(white, 0.85);
    color:$color;
    background-color: rgba(white, 0.85);
    &:focus{
      border-color:darken($color, 7.5);
      color:white;
      background-color: darken($color, 7.5);
    }
  }
}

