$submitTransition: all ease-in-out 0.8s;

body.dilemma-single-exp {
  background-color: #5f0f40;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  height: 1920px;
  overflow: hidden;


  //overflow-y: hidden;

  header {
    a {
      display: block;
    }
  }
  footer {
    position: relative;
    z-index: 10;
  }
  main {

    a.next-dilemma {
      margin-bottom: 20px;
    }

    img.dilemma-single-exp-title-image {
      display: block;
      margin: 0 auto;
      width: 980px;
      max-height: 318px;
      margin-bottom: 60px;
      opacity: 1;
      transition: $submitTransition;

    }

    .dilemma-single-answers-exp {
      max-height: 9999999px;
      opacity: 1;
      transition: $submitTransition;
    }

    .answer-feedback,
    .chart-container,
    span.population,
    section.mere,
    a.next-dilemma,
    .selected-answer-text {
      transform: translateY(1920px);
      transition: all ease-in-out 0.8s;
      position: fixed;
      top: 0;
      visibility: hidden;
    }

    &.answer-submited {

      .dilemma-single-exp-title-image {
        max-height: 0;
        opacity: 0;
      }

      .dilemma-single-answers-exp {
        height: 0;
        opacity: 0;
      }

      .answer-feedback,
      .chart-container,
      span.population,
      section.mere,
      a.next-dilemma,
      .selected-answer-text  {
        transform: translateY(0px);
        position: static;
        visibility: visible;
      }
      .answer-feedback, .selected-answer-text {
        transition-delay: 0.1s;
      }
      .chart-container,
      span.population {
        transition-delay: 0.2s;
      }
      section.mere {
        transition-delay: 0.3s;
      }
	  .btn-wrap {
		text-align: center;
	  }
      a.next-dilemma {
        transition-delay: 0.4s;
        display: inline-block;
        margin: 20px 40px 40px 50px;
        width: 250px;
        padding-top: 5px;
        padding-bottom: 12px;
      }
    }
  }

}

section.dilema-single-question-writting-exp {
  max-width: 700px;
  margin: 0 auto;
  h1, p {
    color: white;

  }
  h1 {
    margin-bottom: 45px;
    font-size: 56px;
    font-weight: 300;
    line-height: 65px;
  }
  p {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 31px;
  }
}

section.dilemma-single-answers-exp {
  max-width: 700px;
  margin: 0 auto;

  color: white;

  ul {
    margin-bottom: 48px;
    li {
      border-top: 1px solid #111111;
      font-size: 25px;
      font-weight: 300;

      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 20px;

      &.selected {
        span.radio-circle {
          border-color: #0DFF92;
          &:after {
            transition: all 0.25s linear;
            opacity: 1;
          }
        }
      }

      span.radio-circle {
        display: inline-block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 4px solid white;
        vertical-align: sub;

        margin-right: 20px;

        position: relative;
        &:after {
          content: '';
          display: inline-block;
          width: 15px;
          height: 15px;
          background-color: #0DFF92;
          border-radius: 50%;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          opacity: 0;
        }
      }

      &:last-child {
        border-bottom: 1px solid #111111;
      }
    }
  }
  button.dilemma-submit-btn-exp[disabled] {
    opacity: 0.3;
  }
}

a.dilemma-home-exp {
  width: 75px;
  height: 75px;
  background-color: green;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  text-align: center;
  i {
    font-size: 46px;
    color: #5f0f40;
    line-height: 75px;
  }
}

section.percentage-dilemma-exp {

  max-width: 700px;
  margin: 0 auto;

  margin-bottom: 60px;

  .answer-feedback {
    background-color: #f6be6f;
    color: black;
    text-align: center;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 10px;

    p {
      margin: 0;
      font-size: 24px;
      line-height: 31px;
    }
  }

  .selected-answer-text {

  }

  .chart-container {
    height: 320px;
  }

  //chart D3
  svg {
    display: block;
    margin: 0 auto;
  }
    .legend {
    stroke-width: 0;
  }

  span.population {
    display: block;
    text-align: right;
    color: #fff6d9;
    font-size: 16px;
    line-height: 21px;

  }
}

section.mere {
  width: 700px;
  margin: 0 auto;
  margin-bottom: 48px;
  display: flex;
  justify-content: space-around;

  .mere-item {
    width: 45%;
    height: 415px;
    padding: 20px;

    box-shadow: 0px 0px 54px 2px rgba(0,0,0,0.75);

    h2 {
      margin-top: 14px;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      color: white;
    }
    button {
      color: white;
      border: 1px solid #fff6d9;
      padding: 15px 50px;
      display: block;
      margin: 0 auto;
      margin-top: 48px;
      font-size: 24px;
      border-radius: 6px;
    }
  }
}

.dilemma-popups-exp {
  position: fixed;

  color: #fff6d9;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  justify-content: center;
  align-items: center;

  display: flex;

  transition: all ease 0.3s;

  z-index: -1;

  background-color: rgba(119, 119, 119, 0);

  &.open {
    z-index: 10;
    background-color: rgba(119, 119, 119, 0.7);
  }

  h1 {
    font-size: 59px;
    line-height: 65px;

  }

  p {
    font-size: 24px;
    line-height: 31px;

  }

  //induvidual popups
  div.green-popup,
  div.red-popup {
    display: none;

    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;

    background-color: #5f0f40;

    position: relative;

    width: 70%;
    height: auto;

    .dilemma-popup-paragraph {
      max-height: 980px;
    }

    span.close-popup-dilemma {
      display: inline-block;
      width: 30px;
      height: 30px;
      background-position-x: -40px;
      background-position-y: 0px;
      background-image: url('../images/close-dilemma-popup.png');

      position: absolute;
      top: -15px;
      right: -15px;
      z-index: 1;

      transform: scale(2);
    }

    &.open {
      display: block;
      animation: fadeIn 0.3s;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}

.dilemma-submit-btn-exp {
  transition: all 0.25s linear;
  background-color: green;
  padding: 15px 50px;
  box-shadow: inset -5px -5px 0px 0px rgba(0,0,0,0.75);
  border-radius: 6px;
  font-size: 24px;

  margin: 0 auto;
  display: block;

  color: white;
}

.next-dilemma {
  @extend .dilemma-submit-btn-exp;
  width: 300px;
  text-align: center;
}
