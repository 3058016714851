.dilemma-single {

  margin-top: 100px;
  &.answer-submited {

    section.dilemma-question > .dilemma-question-wrapper {
      .user-answared {
        transform: scale(1);
      }
    }

    .answers {
      transform: scale(0);
      transform-origin: top left;
    }


    section.dilemma-question {
      .chart-container-web {
        .population {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }

  }

  .for, .imod, .next-dilemma-web, .dilemma-thank-you-notes, .facebook-share {
    transition: all cubic-bezier(0.735, 0.005, 0.265, 1.550) .8s;

    opacity: 0;
    transform: scale(0.9);

    &.inView {
      opacity: 1;
      transform: scale(1);
    }
  }

  .facebook-share {
    text-align: center;
    margin-top: -50px;
    margin-bottom: 50px;

    a {
      transition: all 0.3s cubic-bezier(0, 0.85, 0.49, 1);
      display: flex;
      justify-content: center;

      &:hover {
        transform: scale(1.05);
      }
    }

    span {
      color: white;
      font-size: 20px;
      font-weight: 600;
    }
    span:first-child {
      display: inline-block;
      width: 40px;
      height: 40px;
      border: 3px solid white;
      border-radius: 50%;
      line-height: normal;
      margin-right: 10px;
      position: relative;

      i {
        font-size: 18px;
        color: white;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .mere-dilemma-web {
    margin-bottom: 50px;

    .open-popup-dilemma {
      padding-left: 40px;
    }

    .for {
      margin-bottom: 40px;
    }

    @include breakpoint(large) {
      .for {
        padding-right: 40px;
        margin-bottom: 0px;
      }
      .imod {
        padding-left: 40px;
      }
    };

    .thumbnails-dilemma {
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.25);
      position: relative;
      cursor: pointer;

      transition: all 0.3s cubic-bezier(0, 0.85, 0.49, 1);

      &:hover {
        transform: scale(1.05);
      }

      .thumbnail__title {
        color: white;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.25);
        position: absolute;
        left: -20px;
        top: -16px;
        padding: 16px 20px;
        h3 {
          font-family: 'EXP-display';
          font-size: 30px;
          letter-spacing: 2px;
          line-height: normal;
        }
      }
      .thumbnail__content {
        background-color: white;
        padding: 16px 20px;
        margin-top: -1px;
        p {
          margin-bottom: 0;
          font-size: 20px;
        }
      }
    }
  }
  .next-dilemma-web {
    display: inline-block;
    padding: 10px 40px;
    font-size: 18px;
    font-weight: bold;
    border: 3px solid white;
    color: white;
    margin-bottom: 50px;

    transition: all 0.3s cubic-bezier(0, 0.85, 0.49, 1);

    &:hover {
      transform: scale(1.05);
    }
  }
}

.see-more-below {
    color: white;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    top: -50px;
    position: relative;
    display: none;
}

section.dilemma-question {

  position: relative;

  .chart-container-web {
    justify-content: center;

    position: relative;

    height: 250px;

    @media (min-width: 640px) {
      height: 700px;
      top: -100px;
    }


    display: flex;

    .population {
      font-size: 14px;
      font-weight: 700;
      color: white;
      text-align: center;
      line-height: 0px;
      position: absolute;

      @media (min-width: 640px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
      }

      top: 60px;
      right: auto;
      left: 200px;
      transform: translate(-50%, -20px) scale(0);

      transition: all cubic-bezier(0.735, 0.005, 0.265, 1.550) .5s;
      transition-delay: 2.1s;

      .number {
        font-family: "EXP-display";
        font-size: 25px;
      }

    }

    .label-percents {
      fill: white;
      font-size: 50px;
      font-family: "EXP-display";
    }

    .label-text {
      font-size: 14px;
      font-weight: 700;
      color: white;
      text-align: center;
    }
  }

  .dilemma-question-wrapper {
    max-width: 860px;
    margin: 0 auto;
    transform: translateY(-52px);
    position: relative;

    //small styles
    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint(large) {
      padding-left: 0;
      padding-right: 0;
    }

    .answers-wrapper {
      position: relative;
    }

    .question, .answers-wrapper {
      display: inline-block;
      float: left;
      width: 100%;
      @include breakpoint(medium) {
        width: 50%;
      }
    }

    .answers, .user-answared {
      width: 100%;
    }

    .question, .answers, .user-answared {
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.25);
    }

    .question {
      position: relative;
      z-index: 1;
      img {
        width: 100%;
        max-height: 255px;
      }
    }

    .user-answared {
      background-color: white;
      @include breakpoint(medium) {
        padding: 30px 30px 40px 30px;
        max-height: 420px;
        overflow: hidden;
      }
      padding: 10px 10px 20px 10px;

      max-height: 177px;
      overflow-y: scroll;


      //small styles
      margin-top: 0;
      margin-left: 0;

      position: absolute;
      top: 0;
      left: 0;

      @include breakpoint(medium) {
        top: 105px;
      }

      z-index: 1000;

      transition: transform cubic-bezier(0.735, 0.005, 0.265, 1.550) 0.5s;
      transform-style: preserve-3D;

      transform: scale(0);
      transform-origin: top left;
      transition-delay: 0.5s;

      &.submited {
        transform: scale(1);
      }

      h2, h4 {
        font-weight: normal;
      }

      h2 {
        font-size: 1em;
        line-height: normal;
        margin-bottom: 12px;
        @include breakpoint(medium) {
          font-size: 1.5em;
          margin-bottom: 20px;
        }
      }

      h4 {
        font-size: 0.8em;
         @include breakpoint(medium) {
           font-size: 1em;
         }
        color: black;
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }

    .answers {

      //small styles
      margin-left: 0;
      margin-top: 0;

      padding: 20px;

      @include breakpoint(medium) {
        margin-left: -30px;
        margin-top: 105px;
        padding-left: 50px;
        padding-right: 30px;
        padding-top: 27px;
        padding-bottom: 27px;
      }

      @include breakpoint(large) {
        padding-left: 80px;
      };

      background-color: white;

      transition: transform cubic-bezier(0.680, -0.550, 0.265, 1.550) 0.5s;
      transform-style: preserve-3D;

      &.submited {
        transform: scale(0);
        transform-origin: top left;
      }

      button.answer-single {
        display: block;

        padding-top: 8px;
        padding-bottom: 8px;

        padding-left: 35px;
        @include breakpoint(medium) {
          padding-left: 50px;
        }

        width: 100%;

        text-align: left;

        position: relative;

        transition: all ease 0.3s;

        &:focus {
          outline: none;
        }


        span.answer-text-dilemma {
          font-size: 1em;
          line-height: normal;
          @include breakpoint(medium) {
            font-size: 1.5em;
          }
        }

        span.circle {
          width: 25px;
          height: 25px;
          border-radius: 50%;

          margin-right: 20px;

          display: flex;
          justify-content: center;
          align-items: center;

          position: absolute;
          top: 50%;
          left: 0;
          @include breakpoint(medium) {
            left: 10px;
          }
          transform: translateY(-50%);

          &:before {
            content: '';
            display: inline-block;

            width: 8px;
            height: 8px;
            border-radius: 50%;

            transform: scale(0);

            transition: all ease 0.3s;

          }
        }

        &.selected {
          background-color: #F9F9F9;
          span.circle:before {
            transform: scale(1);
          }
        }
      }

      .btn {
        margin-top: 20px;
        margin-left: 0;
        @include breakpoint(medium) {
          margin-left: 10px;
        }
        &[disabled] {
          opacity: 0.6;
        }
      }
    }
  }

  .dilemma-writting {
    color: white;

    padding: 20px;

    @include breakpoint(medium) {
      padding-left: 82px;
      padding-right: 61px;
      padding-top: 28px;
      padding-bottom: 28px;
    };

    span.qustion-mark {
      font-size: 2em;
      line-height: 1em;
      font-family: "EXP-display";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      float: left;
      position: absolute;

      @include breakpoint(medium) {
        font-size: 2.5em;
        left: -60px;
        top: -5px;
        transform: none;
      }
    }


    h2.dilemma-title {
      font-size: 1.5em;
      margin-bottom: 10px;
      padding-left: 40px;
      @include breakpoint(medium) {
        margin-bottom: 6.75%;
        font-size: 2em;
        padding-left: 0;
      }
      line-height: 1.2em;
      text-transform: uppercase;

      position: relative;
    }
    p.dilemma-text {
      margin: 0;
      font-size: 1.2em;
      line-height: 1.3125em;
    }
  }

}

.dilemma-thank-you-notes {
  h2 , p {
    color: white;
  }
  h2 {
    font-size: 1.5em;
    @include breakpoint(medium) {
      font-size: 2em;
    }
  }
  p {
    font-size: 1.2em;
    margin-bottom: 50px;
  }
}

.dilemma-popups-exp.dilemma-popups-exp-web {
  align-items: flex-start;
  overflow-y: scroll;
  padding: 30px;
  @include breakpoint(medium) {
    align-items: center;
    overflow-y: auto;
  }
  .single-popup {
    display: none;
    width: 100%;
    background-color: white;

    position: relative;
    .popup-text {
      padding: 20px;
      h1 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 22px;
        line-height: normal;
      }
      p {
        margin-bottom: 0;
        font-size: 15px;
        line-height: 22px;
      }
    }
    .thumbnail__title-popup, .close-popup-dilemma {
      display: inline-block;
      position: absolute;
      padding: 16px 20px;
    }
    .thumbnail__title-popup {
      left: -20px;
      top: -16px;
      h3 {
        font-family: 'EXP-display';
        font-size: 30px;
        letter-spacing: 2px;
        line-height: normal;
      }
    }
    .close-popup-dilemma {
      right: -20px;
      top: -16px;

      &:hover {
        cursor: pointer;
      }

      i {
        font-size: 20px;
      }
    }
    @include breakpoint(medium) {
      width: 50%;
    }
    @include breakpoint(large) {
      width: 25%;
    }
    margin: 0 auto;
    color: white;
    &.open {
      display: block;
      animation: fadeIn 0.3s;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  .single-popup-web-container {
    width: 80%;
  }
}


//Theme Coloring
@each $theme, $color in $themes {
  @include theme($theme, $color, '.dilemma-popups-exp.dilemma-popups-exp-web') {
    .single-popup {
      .popup-text {
        color: $color;
      }
      .thumbnail__title-popup, .close-popup-dilemma {
        background-color: $color;
      }
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.dilemma-question') {
    background-color: $color;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.dilemma-writting') {
    background-color: $color;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.answer-text-dilemma') {
    color: $color;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.user-answared h2') {
    color: $color;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.thumbnails-dilemma') {
    .thumbnail__title {
      background-color: $color;
    }
    .thumbnail__content {
      color: $color;
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.dilemma-question-wrapper') {
    .answers {
      button.answer-single {
        span.circle {
          border: 3px solid $color;
        }

        &.selected {
          span.circle:before {
            background-color: $color;
          }
        }
      }
    }
  }
}
