/*
Thumbnail theme

Thumbnail for theme

Markup:
.row.align-center
  .small-12.medium-8.large-6.columns
    .thumbnail-theme
      a(href="#")
        .thumbnail__image
          img(src="images/image-11.jpg")
        .thumbnail__content
          h3.header--medium Jorden
          p.description Curabitur mattis iaculis tellus non congue. Nulla bibendum dui ac sapien tincidunt fermentum.
          .tags.tags--white
            svg.svg-icon.icon--tag
              use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
            .tag-group
              span.tag Eksperiment
              span.tag Mennesket

Styleguide 2.10

*/

.thumbnail-theme{
  margin-bottom:$spacing-medium;
  a{
    display: block;
    transition: $global-transition;
    .thumbnail__image{
      display: inline-block;
      position: relative;
      z-index: 1;
      box-shadow: $global-dropshadow--default;
      transition: $global-transition;
    }
    .thumbnail__content{
      display: inline-block;
      position: relative;
      padding: $spacing-small $spacing-small $spacing-medium $spacing-small;
      width: 100%;
      margin: 0;
      background-color: $color-theme-primary;
      box-shadow: $global-dropshadow--01;
      transition: $global-transition;
      color: white;
      z-index: 99;
      @include breakpoint(medium) {
        width: 70%;
        margin: -$spacing-large 0 0 $spacing-small;
      };
      @include breakpoint(large) {
        width: 60%;
        margin: -$spacing-large*1.5 0 0 $spacing-medium;
      };
      h3{
        margin-bottom: $spacing-small*0.5;
      }
      p.meta{
        margin-bottom:$spacing-small*0.25;
      }
    }
    &:hover{
      transform: scale($global-scale);
      transition: $global-transition;
      .thumbnail__image{
        box-shadow: $global-dropshadow--hover;
        transition: $global-transition;
      }
      .thumbnail__content{
        box-shadow: $global-dropshadow--hover;
        transition: $global-transition;
      }
    }
  }
}
@each $theme, $color in $themes {
  @include theme($theme, $color, '.thumbnail-theme') {
    a{
      .thumbnail__content{
        background-color: $color;
      }
    }
  }
}
