.navigation-layout {
  //offset the height of the page the same as the height of the navigation
  transition: padding 0.2s ease-in;
  padding-top: $nav-height-small;

  @include breakpoint(large) {
    padding-top: $nav-height-large;
  }

  @include breakpoint(xlarge) {
    padding-top: $nav-height-xlarge;
  }

  &.menu-condensed {
    @include breakpoint(large) {
      padding-top: 70px;
    }

    @include breakpoint(xlarge) {
      padding-top: 80px;
    }
  }

  .global-navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .off-canvas-menu{
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    // width: 256px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: none;
    top: 70px;
    &.is-active {
      display: block;
    }
  }
}

@media(max-width:480px){
    .global-navigation {
        .row {
            display: block;
            .columns {
                display: inline-block;
                width: 33.3%;
                text-align: center;
                vertical-align: middle;
                padding: 0;
                margin: 0 -2px;
            }
        }
    }
}

//generic spacer class to add to sections.
.content-section {
  padding-top: $spacing-large;
  padding-bottom: $spacing-large;
  &.pull-up-over-padding {
    padding-bottom: 250px;
  }
  &.content-section__article-gallery {
    padding-top: $spacing-small;
  }
}
.section-title-padding{
  padding-top: $spacing-small;
  padding-bottom: $spacing-medium;
  h2.header--medium{
    margin-bottom:0.5em;
    hyphens: auto;
  }
}


.main {
  background-color: white;
  box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.15);
}

.YTfullScreen {
  .navigation-layout {
    padding-top: 0;
    .global-navigation {
      z-index: 0;
    }
  }
}
