$map-explode-transition-duration: 1s;

@each $theme, $color in $themes {
  @include theme($theme, $color, '.exhibitions-popup') {
    .exhibitions-popup-content {
      h1 {
        color: $color;
      }
      a {
        border: 2px solid $color;
        color: $color;
      }
    }
  }
}

*[id^="eng-text"] {
  display: none;
}

body {
  position: relative;
}

.exhibitions-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);

  z-index: 200;

  padding-top: 113px;
  padding-left: 10px;
  padding-right: 10px;

  display: none;
  overflow-y: scroll;

  @include breakpoint(medium) {
    padding-top: 166px;
  };

  @include breakpoint(xlarge) {
    overflow-y: auto;
  };

  .close-exhibitions-popup {
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    top: -70px;
    right: 0px;

    svg {
      transform: scale(0.6);
    }

    @include breakpoint(medium) {
      right: -22px;
      top: -22px;
    };

    cursor: pointer;
  }

  .exhibitions-popup-content-image {
    div.columns {
      padding: 0;
    }
  }

  .exhibitions-popup-content {
    background-color: white;
    color: #0b1326;

    .more-info-exhibitions {
      div:first-child {
        margin-top: 26px;
        img {
          margin-right: 13px;
        }
      }
      div:nth-child(2), div:last-child {
        img {
          margin-left: 13px;
        }
      }

      img {
        display: inline-block;

        margin-bottom: 15px;
        margin-right: 24px;
      }
    }

    span {
      font-family: "Mark-Pro";
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.54;

      margin-top: 26px;

      &:first-child {
        margin-top: 10px;
      }

      &:last-child {
        margin-top: 24px;
      }
    }

    h1 {
      font-family: 'EXP-display';
      font-size: 31px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.06;
      letter-spacing: normal;
    }

    p.popup-txt {
      min-height: 100px;

      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #0b1326;
    }

    a {
      display: inline-block;
      float: left;
      text-align: center;
      padding: 12px 20px;
      font-family: "Mark-Pro";
      font-size: 13px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;

      margin-bottom: 25px;

      @include breakpoint(large) {
        margin-bottom: 60px;
      };
    }
  }
}

.map-container {
  background-color: #0B1326;
  padding-top: 80px;
  padding-bottom: 100px;

  h1, h2 {
    color: white;
    text-align: center;
    margin: 0;
  }

  h2 {
    margin-bottom: 20px;
  }

  svg {
    width: 1048px;
    height: 1577px;
    height: 400px;

    @include breakpoint(medium){
      height: 1000px;
    }

    @include breakpoint(large){
      height: 1577px;
    }
  }

}

.info-balloon {
  background: white;
  padding: 20px;
  width: 200px;
  display: none;
  position: absolute;
  z-index: 1;

  .close-ballon {
    float: right;
    margin-right: -35px;
    margin-top: -35px;
    cursor: pointer;
    svg {
      cursor: pointer;
    }
  }
  p {
    color: black;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
  }

  p.info-balloon-title {
    font-weight: bold;
    margin-bottom: 0;
  }
}

.interactive-map-content {
  background-color: #0B1326;
  padding-top: 0px;
  @include breakpoint(medium) {
    padding-top: 61px;
  };
  padding-bottom: 100px;
  //
  // position: relative;
  // z-index: -1;

  .desktop-map {
    display: none;
    position: relative;
  }
  .mobile-map {
    padding-top: 125px;
    .slick-slide:focus {
      outline: none;
    }
    .slick-dots {
      display: flex;
      justify-content: space-between;
      width: 300px;
      color: white;
      position: absolute;
      top: 62px;
      left: 50%;
      transform: translateX(-50%);

      li {
        display: inline-block;
        width: 67px;
        text-align: center;
        cursor: pointer;

        &::after {
          content: '';
          width: 100%;
          height: 10px;
          display: block;
          background-color: rgba(231,231,232, 0.2);
          margin-top: -14px;
        }
      }
    }
  }
  @include breakpoint(xlarge){
    .desktop-map {
      display: block;
    }
    .mobile-map {
      display: none;
    }
  };

  .tint-map-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(11,19,38, 0.7);
    text-align: center;
    padding-top: 178px;

    h1 {
      font-family: 'EXP-display';
      font-size: 31px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.06;
      letter-spacing: normal;
      text-align: center;
      color: #fff;

      max-width: 546px;
      margin: 0 auto;
      margin-bottom: 40px;
    }

    .open-map-btn {
      color: #fff;
      display: inline-block;
      font-size: 23px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      padding: 16px 37px;
      border: solid 4px #ffffff;
      transition: all ease 0.3s;

      &:focus {
        outline: none;
      }
      &:hover {
        background-color: #fff;
      }
    }
  }

  // overiding foundation gutter - design per design spec

  div.columns {
    padding-left: 10px;
    padding-right: 10px;

    box-sizing: content-box;
  }

  //Map menu styles
  ul.map-menu {
    color: white;
    li {
      font-family: MarkPro;
      font-size: 19px;
      font-weight: 900;
      line-height: 1.32;
      letter-spacing: normal;
      display: inline-block;
      margin-bottom: 11px;

      &::after {
        content: '';
        width: 100%;
        height: 10px;
        display: block;
        background-color: rgba(231,231,232, 0.2);
        margin-top: -14px;
        transition: background-color ease 0.3s;

      }

      a {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  &.exploded {

    svg {
      g[id^="icons-"] {
        cursor: pointer;
      }

      #roof {
        transform: translate(1px, 0px);
      }
      #second-level {
        transform: translate(0px, 319px);
        //transform: translate(0px, 340px);
      }
      #first-level {
        transform: translate(1px, 753px);
        //transform: translate(1px, 774px);
      }
      #ground-level {
        transform: translate(1px, 1215px);
        //transform: translate(1px, 1236px);
      }
      #conectors {
        #second-level-conectors, #first-level-conectors, #ground-level-conectors {
          fill-opacity: 1;
        }
        #second-level-conectors {
          transform: translate(0px, 0px) scaleY(1);
          //transform: translate(0px, 21px) scaleY(1);
        }
        #first-level-conectors {
          transform: translate(0px, 340px) scaleY(1);
          //transform: translate(0px, 361px) scaleY(1);
        }
        #ground-level-conectors {
          transform: translate(582px, 851px) scaleY(1);
          //transform: translate(582px, 872px) scaleY(1);
        }
      }
    }
  }

  svg {
    display: block;
    margin: 0 auto;

    // hovering styles
    *[id^="txt-"]{
      pointer-events:none;
    }
    #for-coloring {
      & > g:hover {
        &>*:not(g) {
          transition: all ease 0.3s;
        }
        &>*:not(g) {
          cursor: pointer;
        }
        cursor: pointer;
      }
    }

    // colapsing - exploding styles
    #roof, #second-level, #first-level, #ground-level {
      transition: all ease-in-out $map-explode-transition-duration;
    }
    #roof {
      transform: translate(1px, 21px);
    }
    #second-level {
      transform: translate(0px, 0px);
    }
    #first-level {
      transform: translate(1px, 76px);
    }
    #ground-level {
      transform: translate(1px, 159px);
    }
    #conectors {
      #second-level-conectors, #first-level-conectors, #ground-level-conectors {
        transition: all ease-in-out $map-explode-transition-duration;
        fill-opacity: 0;
      }
      #second-level-conectors {
        //transform: scaleY(0);
        transform: translate(0px, 21px) scaleY(0);
      }
      #first-level-conectors {
        transform: translate(0px, 22px) scaleY(0);
      }
      #ground-level-conectors {
        transform: translate(582px, 175px) scaleY(0);
      }
    }

    .dots-rute-one, .dots-rute-two {
      display: none;
    }
  }
}

.interactive-map-intro {
  background-color: white;
  padding-top: 106px;
}

.interactive-map-intro-text {
  color: #0B1326;
  font-family: MarkPro;

  h1 {
    font-size: 21px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
  }
  p {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
  }
}

.dropdown-toogler {
  line-height: 38px;

  border: solid 2px;
  padding-left: 12px;

  cursor: pointer;
  position: relative;

  &.toggled {
    .dropdown-arrow {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.dropdown-arrow {
  display: inline-flex;
  height: 38px;
  width: 38px;
  float: right;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;

  svg {

    transition: all ease 0.3s;
  }
}

.dropdown-item-txt {
  white-space: nowrap;
}

.dropdown-select {
  display: none;
  color: white;

  li {
    padding-left: 12px;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding-bottom: 5px;
    padding-bottom: 5px;
  }
  position: absolute;
  top: 38px;
  left: 10px;
  right: 10px;
  z-index: 1;
}

.interactive-map-intro-filters {
  margin-bottom: 20px;
  @include breakpoint(medium) {
    margin-bottom: 40px;
  };
  margin-top: 5px;
  div.row {
    margin-left: -10px;
    margin-right: -10px;
  }
  div.columns {
    position: relative;

    padding-left: 10px;
    padding-right: 10px;
  }
  div.small-6 {
    margin-bottom: 20px;
    @include breakpoint(large) {
      margin-bottom: 0px;
    };
  }
}

@keyframes bounceDots {
  0% {
    transform: translateY(-100px);
  }

  60% {
    transform: translateY(0px);
  }

  70% {
    transform: translateY(-10px);
  }

  80% {
    transform: translateY(0px);
  }

  85% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0px);
  }

}

#dots-poi {
	* {
		cursor: pointer;
    transition: all ease .2s;
    stroke: #0B1326;
    stroke-width: 0;

    &.drop-that-dots {
      animation-name: bounceDots;
      animation-duration: 2.6s;
    }
	}
}

.colored-dots {
  transition: all ease .2s;
  stroke: #0B1326;
  stroke-width: 0;
}

.dots-legend {
  text-align: center;
  font-size: 15px;
  color: #0B1326;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: normal;
  margin-bottom: 35px;
  @include breakpoint(medium) {
    margin-bottom: 55px;
  };

  &:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #0B1326;
    border-radius: 100%;
    vertical-align: middle;
    margin-right: 10px;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '#dots-poi-mobile') {
    * {
      &.colored-dots{
        fill: $color;
        stroke: $color;
        stroke-width: 4;
      }
    }

  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '#dots-poi') {
    *:hover {
      fill: $color;
      stroke: $color;
      stroke-width: 4;
    }
    * {
      &.colored-dots {
        fill: $color;
        stroke: $color;
        stroke-width: 4;
      }
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.dropdown-toogler') {
    border-color: $color;
    .dropdown-item-txt {
      color: $color;
    }
    .dropdown-arrow {
      background-color: $color;
    }
    & + .dropdown-select {
      background-color: darken($color, 5);
      li:hover {
        background-color: $color;
      }
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.dots-rute-one') {
    * {
      stroke: $color;
    }
    polygon {
      fill: $color;
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.dots-rute-two') {
    * {
      stroke: $color;
    }
    polygon {
      fill: $color;
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.dots-rute-three') {
    * {
      stroke: $color;
    }
    polygon {
      fill: $color;
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.close-ballon') {
    svg circle {
      fill: $color !important;
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.close-exhibitions-popup ') {
    background-color: $color;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.interactive-map-content') {
    .tint-map-wrapper {
      .open-map-btn {
        &:hover {
          color: white;
          background-color: $color;
          border-color: $color;
        }
      }
    }

    &.filter-clicked {
      svg {
        display: block;
        margin: 0 auto;

        *[id^="txt-"]{
          pointer-events:none;
        }
        #for-coloring {
          & > g:hover {
            &>*:not(g) {
              fill: rgba($color, .5);
            }
          }
        }
      }
    }

    svg {
      display: block;
      margin: 0 auto;

      *[id^="txt-"]{
        pointer-events:none;
      }
      #for-coloring {
        & > g:hover {
          &>*:not(g) {
            fill: $color;
          }
        }
      }
    }

    ul.map-menu {
      li {
        &.active {
          &::after {
            background-color: $color;
          }
        }


        &:hover {
          &::after {
            background-color: $color;
          }
        }
      }
    }

    .mobile-map {
      ul.slick-dots {
        li {
          &.slick-active {
            &::after {
              background-color: $color;
            }
          }
        }
      }
    }
  }
}

// @keyframes bouncing{
//   0%{
//     transform: translateY(0px);
//   }
//   100%{
//     transform: translateY(-20px);
//   }
// }
