/*
Property

A collection exhibition property

Markup:
ul.properties
  li.property.age
    span.property__icon
      svg
        use(xlink:href="images/assets/SVGSpritesheet.svg#age")
    span.property__text 6 til 12 år
  li.property.professional
    span.property__icon
      svg
        use(xlink:href="images/assets/SVGSpritesheet.svg#professional")
    span.property__text fagligt

Styleguide 1.15

*/
.properties{
  display: block;
  @include breakpoint(medium) {
    display: flex;
  };
  position: relative;
  width: 100%;
  text-align: left;
  .property{
    display: flex;
    flex-flow:flex-start;
    position: relative;
    text-align: center;
    margin:$spacing-small*0.25 $spacing-small $spacing-small*0.25 0;
    width: auto;
    span.property__text{
      align-self:center;
      margin-left:$spacing-small*0.5;
      color:desaturate(lighten($brand-color--black, 20), 50);
    }
    span.property__icon{
      align-self:center;
      margin-top:5px;
      svg{
        width: 50px;
        height:28px;
        fill:desaturate(lighten($brand-color--black, 40), 50);
      }
    }
  }
  .professional{
    span.property__icon{
      margin-top:0px;
      svg{
        width: 30px;
        height:30px;
      }
    }
  }
}
