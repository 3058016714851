body {
  width: 100%;
  height: 100%;
}

.slideout-menu {
  position: fixed;
  left: auto;
  bottom: 0;
  right: 0;
  z-index: 0;
  // width: 256px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;

  top: $nav-height-small;

  @include breakpoint(large) {
    top: $nav-height-large
  }

  @include breakpoint(xlarge) {
    top: $nav-height-xlarge
  }
}

.slideout-panel {
  position:relative;
  z-index: 1;
  // will-change: transform;
  backface-visibility: hidden;
  background-color: #fff;

}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
  height: 100%;
}

.slideout-open .slideout-menu {
  display: block !important;
  z-index: 2;
}
