body.dilemma-exp {
  background-color: #000;
  background-image: url('../images/dilemma-home-bg-exp.jpg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 1080px 1920px;
  height: 1920px;
  font-family: $base-font-stack;


  header {
    width: 1024px;
    margin: 0 auto;
  }
}

//slider section

section.dilemma-slider-exp {

  .text-runner-exp {
    overflow: hidden;
    font-size: 22px;
    color: white;
    background-color: #7f4365;
    margin-bottom: 20px;
    white-space: nowrap;

    box-sizing: border-box;

    p {
      margin: 0;
      padding-top: 16px;
      padding-bottom: 16px;


      display: inline-block;
      padding-left: 100%;
      text-indent: 0;
      animation: marquee 20s linear infinite;
    }
  }

  .slider-wrapper-exp {
    h1, h2 {
      color: white;
      max-width: 700px;
      margin:  0 auto;
    }
    h1 {
      font-size: 56px;
      text-align: left;
      font-weight: 300;
      line-height: 73px;
      padding-top: 28px;
    }
    h2 {
      font-size: 18px;
      text-align: right;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

}

section.dilemma-language-exp {
  text-align: center;
  padding-top: 500px;
  a {
    display: inline-block;
    &:first-child {
      margin-right: 33px;
    }
  }
}

@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}
