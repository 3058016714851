/*
Dot menu

Menu for secondary navigation. 3 horizontal dots that turn into a cross
when clicked.

Markup:
.dot-menu
  span

Styleguide 1.12
*/

.dot-menu {
  display: flex;
  justify-content: space-between;
  width: 24px;
  cursor: pointer;
  padding: 10px 0;
  &:hover{
    span, &:before, &:after{
      background-color: $color-theme-primary;
    }
  }
  span {
    width: 4px;
    height: 4px;
    background-color: $brand-color--black;
    border-radius: 50%;
  }

  &:before, &:after {
    content: '';
    width: 4px;
    height: 4px;
    background-color: $brand-color--black;
    border-radius: 50%;
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.dot-menu') {
    &:hover{
      span, &:before, &:after{
        background-color: $color;
      }
    }
  }
}
