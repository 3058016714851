/*
Thumbnail blac

This is used when a generic list is presented, e.g. a list of employees, a list of annual report and ect. The user should be able to use insert links in the text area.
The image should be free of choice on the dimension, but the web editor should keep a uniform dimension for one collection of thumbnails.

Markup:
.row.align-center
  .small-12.medium-6.large-4.columns
    .thumbnail-blanc
      .thumbnail__image
        img(src="images/image-19.jpg")
      .thumbnail__content
        h3.header--smaller List item overskrift
        p.Nullam non urna porta, ullamcorper nisl at, vulputate sem. Donec vestibulum arcu magna, et gravida est elementum sit amet. Aenean ultrices blandit ex.
        p
          a(href="#" class="btn btn--small") thumbnail list
        p
          a(href="#" class="link") thumbnail list

Styleguide 2.7

*/

.thumbnail-blanc{
  margin-bottom:$spacing-medium;
  display: block;
  .thumbnail__image{
    box-shadow: $global-dropshadow--01;
    width: 50%;
  }
  .thumbnail__content{
    box-shadow: $global-dropshadow--default;
    padding:$spacing-small*3 $spacing-small $spacing-small;
    margin-top:-$spacing-small*2;
    margin-left: $spacing-small;
    h3{
      margin-bottom: $spacing-small*0.5;
    }
    p.meta{
      margin-bottom:$spacing-small*0.25;
    }
  }
}


.thumbnail__image {
  img {
    @include breakpoint(small down){
      width: 100%;
    };
  }
}
