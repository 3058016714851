/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 22 Jul, 2016, 11:49:20 AM
    Author     : luka
*/


/*For IE11-specific issues*/

.ie11 {
    .global-navigation__secondary-nav {
        margin-top: 20px;
    }
}

