
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    -ms-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }
/* line 1391, /Users/miguelperez/Sites/csstricks/source/stylesheets/keyframes.css.scss */
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.m-scene .scene_element {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  //animation-timing-function: ease-in;
  //animation-timing-function: cubic-bezier(.87,-.61,.19,1.44);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* line 22, /Users/miguelperez/Sites/csstricks/source/stylesheets/pageTransitions.css.scss */
.m-scene .scene_element--fadeinup {
  transform-origin: top;
  // -webkit-animation-name: fadeInUp;
  // animation-name: fadeInUp;
  @-moz-document url-prefix() {
    animation-name: none;
  }
}



/* line 37, /Users/miguelperez/Sites/csstricks/source/stylesheets/pageTransitions.css.scss */
.m-scene .scene_element--delayed {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }
/* line 37, /Users/miguelperez/Sites/csstricks/source/stylesheets/pageTransitions.css.scss */
.m-scene.is-exiting .scene_element {
  -webkit-animation-direction: alternate-reverse;
  animation-direction: alternate-reverse; }
/* line 42, /Users/miguelperez/Sites/csstricks/source/stylesheets/pageTransitions.css.scss */
.m-scene.is-exiting .scene_element--delayed {
  -webkit-animation-delay: 0s;
  animation-delay: 0s; }

/*@media screen and (min-width: 768px) {
  .m-scene .scene_element--fadeinright-wide {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
}*/

.is-loading,
.is-loading a {
  cursor: progress;
}
