/*
Standard button

A push button.
the buttons has the following modifiers, a invert button will be shown in next chapter.

The web editor should be able to chose button in different forms, and the button will inherit the theme properties from the body or html class.

If none of the form or the theme has been chosen, then the button will be shown as its default form.

btn--small - small buttons.
default - a normal button
btn--large - large button.

Markup:
p &nbsp;
div.theme-default
  div.row.align-center
    button.btn(class="{$modifiers}" data-text="Button {$modifiers}")
      span Button {$modifiers}
p &nbsp;
div.theme-red
  div.row.align-center
    button.btn(class="{$modifiers}" data-text="Button {$modifiers}")
      span Button {$modifiers}
p &nbsp;

Styleguide 1.2
*/

/*
Standard button Invert

A inverted button.
If a button is in a highlight element(with background color), such as call to action. A invert css modifier should be added onto the element by itseflt.

The background color is inherited from the "highligh" class at the same level as "row", this is for example prupose only.


btn--invert - invert the color to white and hover color to its respective background color.

Markup:
div.theme-default
  div.row.align-center.highlight
    button.btn(class="{$modifiers}" data-text="Button {$modifiers}")
      span Button inverted

Styleguide 1.3
*/

.btn {
  display: inline-block;
  font-size: $font-size--large;
  font-weight: 800;
  vertical-align: middle;
  background-color: transparent;
  color:$color-theme-primary;
  border: 0.175em solid;
  border-color: $color-theme-primary;
  padding:$spacing-small*0.75 $spacing-small*2;
  cursor: pointer;
  transition: $global-transition;
  overflow: hidden;
  position: relative;
  span{
    display: inline-block;
    transition: $global-transition;
  }
  &:hover{
    background-color: $color-theme-primary;
    color:white;
    transition: $global-transition;
    &:after{
      opacity: 1;
    	transform: translate3d(0, -50%, 0);
      transition: $global-transition;
    }
    span:first-child{
      opacity: 0;
      transform: translate3d(0, 35%, 0);
      transition: $global-transition;
    }
  }
  &:focus{
    outline:0;
  }
  &:after{
    content: attr(data-text);
  	position: absolute;
  	width: 100%;
    text-align: center;
    top: 50%;
  	left: 0;
  	opacity: 0;
    vertical-align: middle;
    transform: translate3d(0, 85%, 0);
    transition: $global-transition;
    color:white;
  }
  &--large{
    font-size:$font-size--large*1.5;
    &:hover{
      &:after{
        transform: translate3d(0, 32%, 0);
      }
      span{
        transform: translate3d(0, 32%, 0);
      }
    }
  }
  &--small{
    padding:$spacing-small*0.5 $spacing-small;
    font-size:$font-size--small;
  }
  &--invert{
    border-color: white;
    color:white;
    background-color: transparent;
    &:hover{
      background-color: white;
      color:$color-theme-primary;
    }
    &:after{
      color:$color-theme-primary;
    }
  }
  &--black{
    border-color: $brand-color--black !important;
    color:$brand-color--black !important;
    background-color: transparent !important;
    &:hover{
      background-color: $brand-color--black !important;
      color:white !important;
    }
  }
}

.cookieBar-section {
  font-size: 12px;
  padding-top: 1em;
  position: fixed;
  bottom: 0;
  z-index: 100000;
  background: white;
  text-align: center;
  width: 100%;
  display: none;

  @include breakpoint(medium){
    // text-align: none;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 15px;
  };
}

.cookie-close-button {
  margin-bottom: 10px;
  padding: 5px 30px;
  font-size: 12px;

  @include breakpoint(medium){
    margin-bottom: 0;
    float: right;
    padding: 15px 40px;
    font-size: 17px;
  };
}

.read-more-wrap {
    text-align: center;
    margin: 0 0 60px;
}
.discover-exhibition {
    &.hide {
       display: none;
    }
}

.hero__header-container {
  a.play-video {
    border-color: #fff;
    color: #fff;
    padding: 12px 40px 12px 20px;
    text-align: right;
    font-size: 13px;

    background-image: url(../images/assets/play_triangle.png);
    background-repeat: no-repeat;
    background-position: calc( 100% - 19px ) center;

    &:hover {
      background-color: transparent;
    }

    @include breakpoint(medium) {
      display: none;
    }

  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.btn') {
    border-color: $color;
    color:$color;
    &:hover{
      background-color: $color;
      color:white;
    }
    &.cookie-close-button {
      border-color: $color !important;
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.btn--invert') {
    border-color: white;
    color:white;
    background-color: transparent;
    &:hover{
      background-color: white;
      color:$color;
    }
    &:after{
      color:$color;
    }
  }
}
