.highlight.row{
  padding:$spacing-large 0;
  background-color: $color-theme-primary;
  fieldset{
    legend{
      color:white;
    }
    label{
      color:white;
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.highlight.row') {
    background-color: $color;
  }
}
