/*
Tags

A collection for different tag, if there is a a.link class, then it will inherited its class, with a bottom border appearance.

default - a normal tags used in thumbnails
tags--neutral - a tag in grey color, regardless to which theme it is in, mostly in thumbnails
tags--white - a tag in white color, regardless to which theme it is in, mostly in a thumbnail theme.

Markup:
.tags(class="{$modifiers}")
  svg.svg-icon.icon--tag
    use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
  .tag-group
    span.tag
      a(class="link" href="#") tags
    span.tag
      a(class="link" href="#") tags
    span.tag
      a(class="link" href="#") tags
    span.tag
      a(class="link" href="#") tags

Styleguide 1.9

*/

.tags{
  svg.icon--tag{
    display: inline;
    position: relative;
    text-align: left;
    width: 12px;
    height: 12px;
    transform:translateY(2px) rotate(45deg);
    color:$color-theme-primary;
  }
  .tag-group{
    display: inline;
    position: relative;
    text-align: left;
  }
  .tag{
    display: inline-block;
    color:$color-theme-primary;
    @extend .text--small;
    margin:0;
    &:after{
      display: inline-block;
      content:"/";
      margin:0 $spacing-small*0.25;
      color:$color-theme-primary;
    }
    &:last-child{
      &:after{
        display: none;
        content: " ";
      }
    }
    a{
      &:hover{
      }
    }
  }
  &--neutral{
    svg.icon--tag{
      color:desaturate(lighten($brand-color--black, 40%), 40%) !important;
    }
    .tag{
      color:desaturate(lighten($brand-color--black, 40%), 40%) !important;
      &:after{
        color:desaturate(lighten($brand-color--black, 40%), 40%) !important;
      }
      a{
        color:desaturate(lighten($brand-color--black, 40%), 40%) !important;
        &:hover{
          color:desaturate(lighten($brand-color--black, 40%), 40%) !important;
        }
      }
    }
  }
  &--white{
    svg.icon--tag{
      color:rgba(255,255,255,0.7) !important;
    }
    .tag{
      color:rgba(255,255,255,0.8) !important;
      &:after{
        color:rgba(255,255,255,0.8) !important;
      }
      a{
        color:rgba(255,255,255,0.8) !important;
        border-color:rgba(255,255,255,0.8) !important;
        &:hover{
          color:rgba(255,255,255,0.8) !important;
          border-color:rgba(0,0,0,0.8) !important;
        }
      }
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.tags') {
    svg.icon--tag{
      color:$color;
    }
    .tag{
      color:$color;
      &:after{
        color:$color;
      }
      a{
        &:hover{
          color:$color;
        }
      }
    }
  }
}
