// Normalize Styles
@import 'node_modules/normalize.css/normalize';
@import 'site/prerequisites/prerequisites.scss';

@import 'vendor/foundation/foundation.scss';
$breakpoint-classes: (small medium large xlarge xxlarge);
$grid-column-gutter: (
small: $spacing-small * 2,
medium: $spacing-small * 2
);
$grid-row-width: 1340px;
@include foundation-everything(true);

@import 'vendor/slideout.scss';
@import 'vendor/burger.scss';
@import 'vendor/GridColumnCarousel.min.scss';
@import 'vendor/slick.scss';

// Import Modules
//@import '../_modules/link/link';

@import 'site/atoms/atoms.scss';
@import 'site/molecules/molecules.scss';
@import 'site/organisms/organisms.scss';
@import 'site/browser/browser.scss';
//@import 'site/layouts/layouts.scss';
