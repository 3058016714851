.barometar {
    background: $brand-color--blue;
    color: white;
    padding: 90px 30px 30px;
    @media(min-width: 768px){
        text-align: center;
        padding-top: 30px;
    }
    h1 {
        font-size: 2em;
        text-transform: uppercase;
        font-family: 'EXP-display', sans-serif;
        font-weight: normal;
        margin-bottom: 10px;
        line-height: 1.2;
    }
    .subtitle {
        font-size: 1.2em;
    }
    .chart-wrap {
        margin: 60px 0 0;
        @media(min-width: 768px){
        margin-left: -50px;
        }
        .chart {
            margin: 0 auto 60px;
            text-align: center;
            max-width: 740px;
            @media(min-width: 768px){
                display: inline-block;
                max-width: none;
                text-align: left;
                margin-left: 50px;
            }
            .period {
                font-size: 14px;
                text-align: center;
                display: inline-block;
                width: 25%;
                min-width: 60px;
                max-width: 80px;
                .bar {
                    padding: 3px;
                    margin: 1px;
                    background: $pale-blue;
                    color: $brand-color--blue;
                }
                &.active {
                    .bar {
                        background: white;
                    }
                }
                .interval {
                    color: $pale-blue;
                    padding-top: 3px;
                    font-size: 15px;
                    &:after {
                        content: '';
                        display: block;
                        height: 15px;
                        position: relative;
                        top: -8px;
                        border-bottom: 2px solid $pale-blue;
                        border-left: 2px solid $pale-blue;
                    }
                }
                &:first-child {
                    .interval {
                        &:after {
                            border-left-color: transparent;
                        }
                    }
                }
            }
            .day {
                color: $pale-blue;
                text-align: center;
                padding-top: 5px;
                font-weight: bold;
            }
            &.active {
                .day {
                    color: white;
                }
                .interval {
                    color: white;
                    &:after {
                        border-color: white;
                    }
                }
            }
            @for $i from 1 through 3 {
                $pd: 32;
                & .crowd-#{$i} { 
                    padding-top: #{120 - $pd * $i}px;
                    .bar {
                        height: #{$pd * $i}px;
                    }
                }
                @media(max-width: 768px){
                    &.trim-#{$i} { 
                        margin-top: #{-$pd * $i}px;
                    }
                }
            }
        }
    }
}