/*
Thumbnail event

A thumbnail for event, this is shown in the event overview page

Markup:
.row.align-center
  .small-12.medium-10.large-6.columns
    .thumbnail-event
      a(href="#")
        .row.collapse
          .thumbnail__image.small-12.medium-5.columns
            img(src="images/image-10.jpg")
          .thumbnail__content.small-12.medium-7.columns
            h3.header--smaller Kan du få en regnbue til at forsvinde?
            p.text--small.meta.dates 10. aug. 2017 - 11. aug. 2017
            p.description Det er tungt at løfte en sæk på 65 kg – for nogle er det umuligt. Men med én talje eller to kan selv slapsvanse løfte 65 kg. Prøv selv!
  .small-12.medium-10.large-6.columns
    .thumbnail-event
      a(href="#")
        .row.collapse
          .thumbnail__image.small-12.medium-5.columns
            img(src="images/image-10.jpg")
          .thumbnail__content.small-12.medium-7.columns
            h3.header--smaller Kan du få en regnbue til at forsvinde?
            p.text--small.meta.dates 10. aug. 2017 - 11. aug. 2017
            p.description Det er tungt at løfte en sæk på 65 kg – for nogle er det umuligt. Men med én talje eller to kan selv slapsvanse løfte 65 kg. Prøv selv!


Styleguide 2.6

*/

.thumbnail-event{
  margin-bottom:$spacing-medium;
  a{
    display: block;
    position: relative;
    padding-bottom:$spacing-small;
    transition: $global-transition;
    >.row{
      flex-direction:row;
      margin:0;
      @include breakpoint(medium) {
        flex-direction:row-reverse;
      }
    }
    .thumbnail__image{
      img{
        display: inline-block;
        position: relative;
        box-shadow: $global-dropshadow--default;
        transition: $global-transition;
        z-index: 1;
      }
    }
    .thumbnail__content{
      display: inline-block;
      position: relative;
      box-shadow: $global-dropshadow--default;
      padding-bottom:$spacing-medium;
      background-color: white;
      transition: $global-transition;
      z-index: 99;
      >*{
        margin: 0 $spacing-small 0;
      }
      h3{
        margin:$spacing-medium*0.75 $spacing-small $spacing-small*0.5;
      }
      p.meta{
        display: inline-block;
        position: absolute;
        top: 0;
        left:$spacing-small;
        transform: translateY(-50%);
        padding:$spacing-small*0.25 $spacing-small*0.5;
        box-shadow: $global-dropshadow--01;
        font-weight: 600;
        margin:0;
        background-color: $color-theme-primary;
        color:white;
      }
    }
    &:hover{
      transform: scale($global-scale);
      transition: $global-transition;
      .thumbnail__image img, .thumbnail__content{
        box-shadow: $global-dropshadow--hover;
        transition: $global-transition;
      }
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.thumbnail-event') {
    a{
      p.meta{
        background-color: $color;
      }
    }
  }
}
