/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 22 Jul, 2016, 11:49:20 AM
    Author     : luka
*/
@import 'ios.scss';
@import 'ie11.scss';
@import 'safari.scss';
