/* Theme Colors */
$brand-color--black: #0B1326;
$brand-color--blue: #0095FF;
$brand-color--green: #00E14B;
$brand-color--red: #F83D4E;
$brand-color--lavanda: #D885FF;
$brand-color--yellow: #FFDC5C;

$color-theme-primary: $brand-color--blue;

/* Other Colors */
$color-midnight: #0b1326;
$grey-medium: #e6e6e6;
$grey-light: #f5f5f5;
$pale-blue: #aad7fb;

/* Height of the navigation */
$nav-height-small: 70px;
$nav-height-large: 100px; // this is to accommodate the secondary nav list is visible throughout the laptop.
$nav-height-xlarge: 100px;

/* Base Font size */
$font-size--small: 12px;
$font-size: 15px;
$font-size--large: 17px;

/* Font stacks */
$base-font-stack: 'Mark-Pro', Helvetica, Arial, sans-serif;
$display-font: 'EXP-display', Verdana, Geneva, sans-serif;

/* Spacing */
$spacing-small: 20px;
$spacing-medium: 40px;
$spacing-large: 60px;
$spacing-x-large: 100px;

/* Easings */
$easing     : cubic-bezier(.165, .840, .400, 1.000);
$easing2    : cubic-bezier(.300, .000, .000, 1.300);
$easing3    : cubic-bezier(.860, .000, .070, 1.000);
$easing4    : cubic-bezier(.250, 1.400, .180, 1.000);
$easing5	: cubic-bezier(1.000, 0.450, 0.235, 0.900);

/* global dropshadow */
//used in general dropshadow
$global-dropshadow--default:    0px 10px 40px -5px rgba(0,0,0,0.1);
//used in closer dropshadow, e.g. exhibition title, date on event thumbnail.
$global-dropshadow--01:         0px 5px 20px 0px rgba(0,0,0,0.25);
//used in navigation dropshadow
$global-dropshadow--02:         0px 5px 60px 0px rgba(0,0,0,0.25);
//used in hover stats
$global-dropshadow--hover:      0px 50px 80px -10px rgba(0,0,0,0.1);

/* global transitions */
$global-transition-property: all;
$global-transition-duration: .3s;
$global-transition-timing-function: cubic-bezier(0, 0.85, 0.49, 1);
$global-transition: $global-transition-property $global-transition-duration $global-transition-timing-function;
$global-scale: 1.05;

/* Some set breakpoints - these are not THE breakpoints - content dictates breakpoints! */
/* These are just some breakpoints that we know we will use throughout the site */
/*$bp-medium: 640px;
$bp-large: 1024px;*/
