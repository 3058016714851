/*
Image Carousel

A carousel for images in the wysiwyg. The images whould have the same size and aspect ratio for best result.

OBS - The carousel will not be visible untill it is initialized by JavaScript.

The JavaScript to initialize the carousel automatically hooks up to all elements with the data-js-module="image-carousel" attribute.

Markup:
.carousel.grid-column-carousel(data-js="image-carousel")
  ul.grid-column-carousel__list
    li.carousel__page
      img(src="images/image-13.jpg")
      span.caption.text--small Image Caption
    li.carousel__page
      img(src="images/image-12.jpg")
      span.caption.text--small Image Caption
    li.carousel__page
      img(src="images/image-15.jpg")
      span.caption.text--small Image Caption
  ul.grid-column-carousel__page-indicators
    li

Styleguide 2.16

*/


.grid-column-carousel__list.initialized {
    // added to prevent overflowing when more than 15 photos added to gallery
    width: 20000px;
}

.carousel {
  &__page {
    position: relative;
    float: left;
    width: 100%;
  }

  .grid-column-carousel__page-indicators {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    li {
      width: 10px;
      height: 10px;
      cursor: pointer;
      border: 1px solid black;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.active {
        background: black;
      }
    }
  }

  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    color: white;
    z-index: 1;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.4);
      z-index: -1;
    }
  }
}
