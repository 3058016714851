/*
Thumbnail exhibition

A thumbnail for event, this is shown in the event overview page

Markup:
.row.align-center
  .small-12.medium-6.large-4.columns
    .thumbnails-exhibition
      a(href="#")
        .thumbnail__title
          h3.header--small Bobleriet
        .thumbnail__image
          img(src="images/image-02.jpg")
        .thumbnail__content
          p.description Bobleriet handler om sæbebobler. Hvorfor spejler en sæbebobbel dig to gange?
  .small-12.medium-6.large-4.columns
    .thumbnails-exhibition
      a(href="#")
        .thumbnail__title
          h3.header--small Den interaktive biograf
        .thumbnail__image
          img(src="images/image-16.jpg")
        .thumbnail__content
          p.description I den interaktive udstilling Opfindelsernes hus bliver idéer til virkelighed og banebrydende opfindelser.
  .small-12.medium-6.large-4.columns
    .thumbnails-exhibition
      a(href="#")
        .thumbnail__title
          h3.header--small PULS-torvet
        .thumbnail__image
          img(src="images/image-17.jpg")
        .thumbnail__content
          p.description På PULS-torvet gælder det om at være aktiv og få både pulsen og humøret i vejret.

Styleguide 2.8

*/

.thumbnails-exhibition{
  margin:$spacing-small 0;
  @include breakpoint(medium) {
    margin:$spacing-medium*0.5 $spacing-small*0.5;
  }
  a{
    display: block;
    position: relative;
    padding-bottom:$spacing-small;
    transition: $global-transition;
    .thumbnail__image{
      img{
        display: inline-block;
        position: relative;
        box-shadow: $global-dropshadow--default;
        transition: $global-transition;
        z-index: 1;
      }
    }
    .thumbnail__title{
      display: inline-block;
      position: absolute;
      top: -$spacing-medium*0.5;
      margin-left:-$spacing-small;
      z-index: 2;
      background-color: $color-theme-primary;
      padding:$spacing-small*0.5 $spacing-small;
      box-shadow: $global-dropshadow--01;
      width: auto;
      max-width: 100%;
      @include breakpoint(medium) {
        margin-left:-$spacing-medium;
        max-width: 70%;
      }
      h3{
        @extend .header--smaller;
        font-family: 'EXP-display';
        letter-spacing: 0.1em;
        color: white;
        margin-bottom: 0;
      }
    }
    .thumbnail__content{
      display: inline-block;
      position: relative;
      box-shadow: $global-dropshadow--default;
      padding:$spacing-small;
      background-color: white;
      transition: $global-transition;
      // margin-top:-1px;
      z-index: 1;
      @include breakpoint(medium) {
        width: 80%;
      }
      >*{
        // margin: 0 $spacing-small 0;
      }
    }
    &:hover{
      transform: scale($global-scale);
      transition: $global-transition;
      .thumbnail__image{
        img{
          box-shadow: $global-dropshadow--hover;
          transition: $global-transition;
        }
      }
      .thumbnail__content{
        box-shadow: $global-dropshadow--hover;
        transition: $global-transition;
      }
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.thumbnails-exhibition') {
    a{
      .thumbnail__title{
        background-color: $color;
      }
    }
  }
}
