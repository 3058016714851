/*
Call to action pusher

A pusher notification for promote certain sells.

Markup:
p &nbsp;
.notification.fixed
  .row.align-center.fixed
    .small-12.large-8.columns
      h4.header--smaller Tilmeld dig vores nyhedsbrev
      button.btn.btn--small(data-text="Tilmeld")
        span tilmeld
p &nbsp;
p &nbsp;
.notification.fixed
  .row.align-center.fixed
    .small-12.large-8.columns
      img(src="images/exp-card-01.png")
      h4.header--smaller Tilmeld dig vores nyhedsbrev
      button.btn.btn--small(data-text="Køb")
        span Køb
p &nbsp;

Styleguide 2.14

*/

.notification{
  background-color: white;
  box-shadow: 0px -5px 20px 0px rgba(0,0,0,0.25);
  text-align: center;
  padding:$spacing-small*0.75 0 0;
  h4, img, .btn{
    display: inline-block;
    margin:0 $spacing-small*0.5;
  }
  h4{

  }
  img{
    // transform: translateY(-50%);
    margin-top:-60px;
  }
  @include breakpoint(small) {
    img, .btn{
      margin-bottom:$spacing-small*0.5;
    }
    h4{
      margin-bottom:$spacing-small;
    }
  }
}
