/*
Social share

A collection social share

default - a normal social share
.social-shares--invert - invert color

Markup:
.theme-green
  .row.align-right
    div.social-shares(class="{$modifiers}")
      .social-shares__numbers
        span.counter 54
        span.text--small Delinger
      ul.social-shares__icons
        li.social-share__icon.social-facebook
          svg
            use(xlink:href="images/assets/SVGSpritesheet.svg#social-facebook")
        li.social-share__icon.social-instagram
          svg
            use(xlink:href="images/assets/SVGSpritesheet.svg#social-instagram")
        li.social-share__icon.social-pinterest
          svg
            use(xlink:href="images/assets/SVGSpritesheet.svg#social-pinterest")
        li.social-share__icon.social-twitter
          svg
            use(xlink:href="images/assets/SVGSpritesheet.svg#social-twitter")

Styleguide 1.16

*/
.social-shares{
  display: flex;
  @include breakpoint(medium) {
    flex-direction:row-reverse;
  };
}
.social-shares__numbers{
  margin-left:$spacing-small*0.5;
  span{
    display: block;
    color:desaturate(lighten($brand-color--black, 40), 50);
  }
  .counter{
    @extend .header--small;
    margin:0;
  }
}
.social-shares__icons{
  display: flex;
  position: relative;
  text-align: left;
  .social-share__icon{
    display: flex;
    flex-flow:flex-start;
    position: relative;
    text-align: center;
    width: 32px;
    height:32px;
    margin:$spacing-small*0.25;
    padding:8px;
    border-radius: 100%;
    border-color:desaturate(lighten($brand-color--black, 40), 50);
    border-width: 1px;
    border-style: solid;
    svg{
      fill:desaturate(lighten($brand-color--black, 40), 50);
    }
    &:hover{
      cursor: pointer;
      border-color: $color-theme-primary;
      svg{
        fill: $color-theme-primary;
      }
    }
  }
}
.social-shares--invert{
  .social-share__icon{
    border-color: white;
    svg{
      fill:white;
    }
    &:hover{
      background-color:white;
      svg{
        fill:$color-theme-primary;
      }
    }
  }
  .social-shares__numbers{
    span{
      color:white;
    }
  }
}


@each $theme, $color in $themes {
  @include theme($theme, $color, '.social-shares__icons') {
    .social-share__icon{
      &:hover{
        border-color: $color;
        svg{
          fill:$color;
        }
        &.social-linkedin {
          path, polygon {
            fill: $color;
          }
        }
      }
    }
  }
}
@each $theme, $color in $themes {
  @include theme($theme, $color, '.social-shares--invert') {
    .social-share__icon{
      border-color: white;
      svg{
        fill:white;
      }
      &:hover{
        background-color:white;
        svg{
          fill:$color;
        }
      }
    }
    .social-shares__numbers{
      span{
        color:white;
      }
    }
  }
}
