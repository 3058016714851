/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 22 Jul, 2016, 11:49:20 AM
    Author     : luka
*/


/* Class .browser-ios is assigned to body by Javascript (main.js) if device is any mobile Apple device. Use if needed to fix something that is wrong in iPhone but works fine in Android, etc */

/* Class .iosnoflex can be added for .row elements which children don't display properly due to lack of CSS flex support. In particular it is Safari up to 9.2, but    there might be more of them */


.browser-ios {
    .row.iosnoflex {
        display: block !important;
    }
    .social-shares__icons {
        display: block;
        display: inline-block;
        .social-share__icon {
            display: inline-block;
            position: relative;
            svg {
                width: 18px;
                height: 18px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    /*Fix for misalignment on iPhone*/
    .label-btn {
        display: inline-block;
        height: 45px;
        .label-btn__text,
        .label-btn__label,
        .label-btn__icon {
            height: 45px;
            line-height: 43px;
            padding-top: 0;
            padding-bottom: 0;
            vertical-align: middle;
        }
        .label-btn__icon {
            margin-right: -3px;
            svg {
                position: relative;
                top: -7px;
            }
        }
    }
    .secondary-navigation {
        top: 132px;
    }
}

.browser-safari {
    br {
        display: block;
        clear: both;
    }
}
.browser-ios {
    .slideout-menu {
        width: 100%;
    }
    main.main.slideout-panel {
        // -webkit-transform: translateX(0%) !important;
        // transform: translateX(0%) !important;
    }
}
.slideout-open {
    .browser-ios {
        .slideout-menu {
            right: 0 !important;
            overflow: scroll;
            display: block !important;
            position: absolute;
        }
        main.main.slideout-panel {
            -webkit-transform: translateX(-100%) !important;
            transform: translateX(-100%) !important;

        }
        main.main.slideout-panel {
            // -webkit-transform: translateX(-40%) !important;
            // height: auto;
            // overflow: auto;
        }
        @media (max-height: 450px) {
            .navigation-layout .off-canvas-menu {
                // width: 280px;
            }
            main.main.slideout-panel {
                // -webkit-transform: translateX(-40%) !important;
                // height: auto;
                // overflow: auto;
            }
            .off-canvas-menu__primary {
                // margin: 0 0 10px;
                li {
                    // padding: 0;
                }
            }
            .off-canvas-menu__secondary{
                li {
                    // padding: 0;
                    // font-size: 0.74em;
                    // width: 48%;
                    // display: inline-block;
                }
            }
        }
    }
}
.browser-ios .global-navigation__extra-info {
    top:70px;
  }
