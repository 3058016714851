/*********** Styles RESET ***************/
* {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:active, &:focus {
    outline: none;
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

figure{
  padding:0;
  margin:0 !important; // where I can reset the global settings??
  figcaption{
    @extend .text--small;
    margin-top:$spacing-small*0.5;
    text-align: center;
  }
}
