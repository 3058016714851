/*
Visitor info

Badge with visitor info used in the global navigation and off cavas navigation

default - default version uses the midnight color.
visitor-info--themed - uses the color of the current theme.

Markup:
.theme-red
  .visitor-info(class="{$modifiers}")
    svg
      use(xlink:href="images/assets/SVGSpritesheet.svg#calendar")
    div
      p I dag er vi åbne
        span.time  kl. 09.00 - 21.00
      a(href="#") Planlæg dit besøg

Styleguide 2.12
*/

.visitor-info {
  display: flex;
  align-items: center;
  color: white;
  background-color: $brand-color--black;
  a{
    display: flex;
    padding: 12px 15px;
    align-items: center;
    &:hover{
      strong{
        text-decoration: underline;
      }
    }
    &:hover{
      svg{
        transform: rotate(180deg);
        transition: transform .5s cubic-bezier(.87,-.61,.19,1.44);
      }
    }
  }

  svg {
    width: 30px;
    height: 25px;
    fill: white;
    margin-right: $spacing-small;
    transition: transform .5s cubic-bezier(.87,-.61,.19,1.44);
    transform-origin: center;
    overflow: visible;
  }

  div {
    > * {
      display: block;
      font-size: 0.86em;
      line-height: 1.3;
      margin-bottom: 0;
    }
  }
}

.visitor-info.visit{
  a{
    &:hover{
      svg{
        transform: rotate(-360deg);
      }
    }
  }
  svg{
    width: 21px;
    height: 21px;
    transform: rotate(-45deg);
    -moz-transform: rotate(-25deg);
  }
}

.visitor-info--themed {
  background-color: $color-theme-primary;
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.visitor-info--themed') {
    background-color: $color;
  }
}
