/*
wysiwyg

description:

Markup:
.wysiwyg
  .row.align-center
    .small-12.columns
      h1.header.header--large H1 Hvorfor virker det?
      h2.header.header--medium H2 Hvorfor virker det?
      h3.header.header--small H3 Hvorfor virker det?
      h4.header.header--smaller H4 Hvorfor virker det?
      h5.header.header--nano H5 Hvorfor virker det?
      p.text Blandingen af is og salt har en lavere frysetemperatur end rent vand, der fryser ved nul grader. Når vandet i isen opløser saltet, trækker det varme ud af isen, som bliver kølet endnu mere ned.
       a(href="#" class="link") inline link Den kolde
      p.text Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta alias, saepe velit eos inventore, sit omnis optio perferendis consequatur consectetur dolore. Magnam dolorum quam maiores non blanditiis. Dolorum, qui provident! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta alias, saepe velit eos inventore, sit omnis optio perferendis consequatur consectetur dolore. Magnam dolorum quam maiores non blanditiis. Dolorum, qui provident!
  .row.align-center
    .small-12.columns
      h4.header.header--smaller H4 list title
      ul
        li 5 dl mælk eller fløde.
        li 60 g sukker.
        li Vanilje-essens eller vaniljesukker.
        li En lille og en stor frysepose (den lille skal kunne være i den store).
  .row.align-center
    .small-12.columns
      h4.header.header--smaller 2 col text layout
    .small-12.medium-6.columns
      p.text Shure’s Music Phone Adapter (MPA) is our favorite iPhone solution, since it lets you use the headphones you’re most comfortable with. It has an iPhone-compatible jack at one end and a microphone module with an Answer/End/Pause button and a female 3.5mm audio jack for connecting to your own headphones.
    .small-12.medium-6.columns
      p.text We connected the adapter to the Shure SE110 in-ear headphones, whose cabling is modular; the top half is fairly short, so when used with the adapter, the cable is the right length, but the mic lays around your navel. The MPA comes with three different sizes of foam and silicone tips and a carrying pouch.
.wysiwyg
  .row.align-center
    .columns.small-12.medium-6.large-4
      .wysiwyg__thumbnail
        .thumbnail__image
          img(src="images/image-06.jpg")
        .thumbnail__content
          h3.header--smaller Vores cafe
          p.description Morbi augue urna, imperdiet eu metus vel, rhoncus pretium nibh. Etiam neque mi, ultricies a fermentum ut, pellentesque ut turpis.
    .columns.small-12.medium-6.large-4
      .wysiwyg__thumbnail
        .thumbnail__image
          img(src="images/image-06.jpg")
        .thumbnail__content
          h3.header--smaller Vores cafe
          p.description Morbi augue urna, imperdiet eu metus vel, rhoncus pretium nibh. Etiam neque mi, ultricies a fermentum ut, pellentesque ut turpis.
    .columns.small-12.medium-6.large-4
      .wysiwyg__thumbnail
        .thumbnail__image
          img(src="images/image-06.jpg")
        .thumbnail__content
          h3.header--smaller Vores cafe
          p.description Morbi augue urna, imperdiet eu metus vel, rhoncus pretium nibh. Etiam neque mi, ultricies a fermentum ut, pellentesque ut turpis.
  .row.align-center
    .columns.small-12.medium-6.large-6
      .wysiwyg__thumbnail
        .thumbnail__image
          img(src="images/image-06.jpg")
        .thumbnail__content
          h3.header--smaller Vores cafe
          p.description Morbi augue urna, imperdiet eu metus vel, rhoncus pretium nibh. Etiam neque mi, ultricies a fermentum ut, pellentesque ut turpis.
    .columns.small-12.medium-6.large-6
      .wysiwyg__thumbnail
        .thumbnail__image
          img(src="images/image-06.jpg")
        .thumbnail__content
          h3.header--smaller Vores cafe
          p.description Morbi augue urna, imperdiet eu metus vel, rhoncus pretium nibh. Etiam neque mi, ultricies a fermentum ut, pellentesque ut turpis.
div.theme-default
  .wysiwyg.highlight
    div.row
      .columns.small-12.medium-6
        h4.header.header--small Invert title
        p.text Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta alias, saepe velit eos inventore, sit omnis optio perferendis consequatur consectetur dolore. Magnam dolorum quam maiores non blanditiis. Dolorum, qui provident!
.wysiwyg
  div.row
    .columns.small-12.medium-5
      h4.header.header--smaller wysiwyg title
      p.text Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta alias, saepe velit eos inventore, sit omnis optio perferendis consequatur consectetur dolore. Magnam dolorum quam maiores non blanditiis. Dolorum, qui provident!
    .columns.small-12.medium-7
      .wysiwyg__facts
        h3.header.header--small 3.8 - 5.7 centimeters
        p The Skylab astronauts grew 1.5 - 2.25 inches (3.8 - 5.7 centimeters) due to spinal lengthening and straightening as a result of zero gravity.
        button.btn.btn--small small buttons
  div.row.reverse
    .columns.small-12.medium-5
      h4.header.header--smaller wysiwyg title
      p.text Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta alias, saepe velit eos inventore, sit omnis optio perferendis consequatur consectetur dolore. Magnam dolorum quam maiores non blanditiis. Dolorum, qui provident!
    .columns.small-12.medium-7
      .wysiwyg__facts
        h3.header.header--small 3.8 - 5.7 centimeters
        p The Skylab astronauts grew 1.5 - 2.25 inches (3.8 - 5.7 centimeters) due to spinal lengthening and straightening as a result of zero gravity.
        button.btn.btn--small small buttons
  div.row.align-center
    .columns.small-12.medium-7
      .wysiwyg__facts
        h3.header.header--small 3.8 - 5.7 centimeters
        p The Skylab astronauts grew 1.5 - 2.25 inches (3.8 - 5.7 centimeters) due to spinal lengthening and straightening as a result of zero gravity.
        button.btn.btn--small small buttons
div.theme-default
  .wysiwyg.highlight
    div.row
      .columns.small-12.medium-5
        h4.header.header--small wysiwyg title
        p.text Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta alias, saepe velit eos inventore, sit omnis optio perferendis consequatur consectetur dolore. Magnam dolorum quam maiores non blanditiis. Dolorum, qui provident!
      .columns.small-12.medium-7
        .wysiwyg__facts
          h3.header.header--small 3.8 - 5.7 centimeters
          p The Skylab astronauts grew 1.5 - 2.25 inches (3.8 - 5.7 centimeters) due to spinal lengthening and straightening as a result of zero gravity.
          button.btn.btn--small small buttons
  .wysiwyg.wysiwyg--side-by-side
    .row.align-center.reverse
      .wysiwyg__content.columns.small-12.medium-5.large-5
        .wysiwyg__content__inner.background--grey
          h3.header--small Tips & tricks
          p Noget tekst der fortæller, at her kan man få de bedste tips om sæbebobler. Og tricks til at lave sine egne sæbebobler.
          a.btn Find opskrifter her
      .wysiwyg__media--video.columns.small-12.medium-6.large-7
        .flex-video.widescreen.vimeo
          iframe(src="http://player.vimeo.com/video/60122989" width="400" height="225" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen)

Styleguide 2.18
*/

main {
  .scene_element > .wysiwyg {
    @include breakpoint(medium down) {
      padding-top: 83px;
    };
  }
}

.wysiwyg{
  &.wysiwyg-padding-top {
    padding-top: 30px;
  }
  iframe {
    width: 100%;
  }
  .row{
    padding-bottom: $spacing-medium;
  }
  .row.reverse{
    flex-flow: row-reverse;
  }
  &.highlight{
    margin-bottom:$spacing-medium;
    padding:$spacing-large 0;
    background-color: $color-theme-primary;
    .wysiwyg__facts{
      h1, h2, h3, h4, h5{
        color:$color-theme-primary;
      }
    }
  }
  ol{
    list-style: decimal;
    padding-left: 1em;
    padding-bottom: 2em;
    li{
      margin-bottom:0.5em;
    }
  }
  ul{
    list-style: disc;
    padding-left: 1em;
    padding-bottom: 2em;
    li{
      margin-bottom:0.5em;
    }
  }
  &--side-by-side{
    .row{
      >.columns{
        &:first-child{
          padding-right:0;
          padding-left:0;
          @include breakpoint(medium) {
            padding-left:$spacing-small;
          }
        }
        &:last-child{
          padding-left:0;
          padding-right:0;
          @include breakpoint(medium) {
            padding-right:$spacing-small;
          }
        }
      }
    }
    .row.reverse{
      >.columns{
        &:first-child{
          padding-left:0;
          padding-right:0;
          @include breakpoint(medium) {
            padding-right:$spacing-small;
          }
        }
        &:last-child{
          padding-right:0;
          padding-left:0;
          @include breakpoint(medium) {
            padding-left:$spacing-small;
          }
        }
      }
    }
  }
  .wysiwyg__content__inner{
    display: inline-block;
    padding:$spacing-medium;
  }
  .wysiwyg__media--video{

  }
  .wysiwyg__facts{
    box-shadow: $global-dropshadow--default;
    background-color: white;
    padding: $spacing-medium;
    h3{
      margin-bottom: 0.25em;
    }
  }
  .wysiwyg__thumbnail{
    .thumbnail__image{
      margin-bottom: 1em;
    }
    .thumbnail__content{
      h3{
        margin-bottom: 0.25em;
      }
    }

  }

  .alignleft, .aligncenter, .alignright, .alignnone {
    margin: 20px 0;
    float: none;
    max-width: 100%;
    //width: 100%;
  }

  .alignright {
    @include breakpoint(medium){
      float: right !important;
      margin-right: -40px;

      margin-top: 20px;
      margin-left: 20px;
      margin-bottom: 20px;
    };
  }

  .alignleft {
    @include breakpoint(medium){
      float: left !important;
      margin-left: -40px;

      margin-top: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
    };
  }

  .aligncenter {
    @include breakpoint(medium){
      margin:  20px auto 40px;
      display: block;
    };
  }

  .alignnone {
    @include breakpoint(medium){
      //display: block !important;
      margin-top: 20px;
      margin-bottom: 40px;
    };
  }

  .wp-caption {

    // width: 100% !important;
    img {
      margin-bottom: 1em;
      width: 100%;
    }

    @include breakpoint(medium) {
      max-width: 100%;
      // width: auto !important;
      img {
        width: auto;
      }
    }
  }
  p.wp-caption-text {
    font-size: 12px;
    line-height: 1.25em;
    text-align: left;
    margin-bottom: 1.5em;
    // max-width: 300px;
  }

  .inner {
    @include breakpoint(medium){
      padding-left: 40px;
    };
  }
  // services columns

.services-wrapper {
  .columns {
    padding-bottom: 3rem;
  }
}
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.wysiwyg') {
    &.highlight{
      background-color: $color;
      .wysiwyg__facts{
        h1, h2, h3, h4, h5{
          color:$color;
        }
        color:$color;
      }
    }
  }
}

//article content

.article-author-info {
  .article-date, .author-name {
    font-weight: bold;
  }
}

.wysiwyg.highlight {
  a {
    color: white !important;
    border-color: white !important;

    &:hover {
      border: none;
    }
  }
}

//factbox

.float-left-fact-box {
  .wysiwyg__facts {
    margin-bottom: 10px;

    @include breakpoint(medium){
      width: 50%;
      float: left;

      margin-right: 10px;
    };
  }
}

