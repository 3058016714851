.form-global {
  padding-top: 40px;
  padding-bottom: 40px;

  button[type="submit"] {
    border-width: 2px;
    border-style: solid;
    padding: 15px 40px;
    &:focus {
      outline: none;
    }
  }

  textarea {
    height: 150px !important;
  }
}

//theme coloring stuff
@each $theme, $color in $themes {
  @include theme($theme, $color, '.form-global') {
    button[type="submit"] {
      color: $color;
      border-color: $color;
      transition: $global-transition-property $global-transition-duration $global-transition-timing-function;
      &:hover {
        background-color: $color;
        color: #fff;
      }
    }
  }
}
