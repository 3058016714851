@each $theme, $color in $themes {
  @include theme($theme, $color, '.ducksuite-wrapper') {
    &.ducksuite-wrapper--gallery {
      #main-div .author-info {
        background: $color;
      }

      #main-div .gallery_main img {
        border: 10px solid $color;
      }
    }

    &.ducksuite-wrapper--photowall {
      #photowall .author-info {
        background: $color;
      }
    }

    &.ducksuite-wrapper--slideshow {

    }

    .multislides li span {
      background: $color;
    }
  }
}


.ducksuite-wrapper {
  * {
    font-family:"Mark-Pro";
  }
  .header--medium {
    font-family: $display-font;
    padding-top: 40px;
  }

  .multislides li span {
    line-height: 14px;
    font-size: 11px;
  }
  &.ducksuite-wrapper--photowall {
    #photowall .author-info {
      line-height: 14px;
      font-size: 11px;
    }
  }
}
