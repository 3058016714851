/*
Hero

A large hero section for the top of the page.

default - a normal hero
.hero--theme-background - sets the background color below the image to the same as the theme

markup:
.hero(class="{$modifiers}" data-js-section="hero")
  .hero__top
    .hero__background
      img(src="images/image-02.jpg" srcset="images/image-02.jpg w1600")
    .row.align-middle
      .columns.hero__header-container
        h1.hero__header
          span Sciencecenter
          span i verd
          span ensklasse
          span hos os
  .hero__bottom
    .row.scene_element.scene_element--delayed.scene_element--fadeinup
      .columns.small-12.medium-10.xlarge-offset-1.xlarge-8.xxlarge-7
        .hero__content
          article.info
            h2.header--medium Bobleriet
            p Bobleriet handler om sæbebobler. Hvorfor spejler en sæbebobbel dig to gange? Og hvorfor er den egentlig rund? Kom og pust og bliv klogere på videnskaben bag de fascinerende sæbebobler eller slå dig ned og nyd synet.
            .tags
              svg.svg-icon.icon--tag
                use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
              .tag-group
                span.tag
                  a(href="#") Eksperiment
                span.tag
                  a(href="#") Jorden
                span.tag
                  a(href="#") Magna at volutpat
                span.tag
                  a(href="#") Blandit nisl
          .extra-info
            .info-badge
              svg.svg-icon
                use(xlink:href="images/assets/SVGSpritesheet.svg#exclamation")
            h4.header--small Vigtigt
            p Dette forsøg skal foretages med en voksen. Forsøget skal foretages udendørs på sne, grus eller fugtig jord.
p &nbsp;
.color-theme-container-for-the-element.theme-red
  .hero(class="{$modifiers}")
    .hero__top
      .hero__background
        video(autoplay loop muted src="videos/movie-01.mp4")
      .row.align-middle
        .columns.hero__header-container
          h1.hero__header
            span Lav din egen
            span Stjernekaster
    .hero__bottom
      .row.scene_element.scene_element--delayed.scene_element--fadeinup
        .columns.small-12.medium-10.xlarge-offset-1.xlarge-8.xxlarge-7
          .hero__content
            article.info
              h2.header--medium Bobleriet
              p Bobleriet handler om sæbebobler. Hvorfor spejler en sæbebobbel dig to gange? Og hvorfor er den egentlig rund? Kom og pust og bliv klogere på videnskaben bag de fascinerende sæbebobler eller slå dig ned og nyd synet.
              .tags
                svg.svg-icon.icon--tag
                  use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
                .tag-group
                  span.tag
                    a(href="#") Eksperiment
                  span.tag
                    a(href="#") Jorden
                  span.tag
                    a(href="#") Magna at volutpat
                  span.tag
                    a(href="#") Blandit nisl
            .extra-info
              .info-badge
                svg.svg-icon
                  use(xlink:href="images/assets/SVGSpritesheet.svg#exclamation")
              h4.header--small Vigtigt
              p Dette forsøg skal foretages med en voksen. Forsøget skal foretages udendørs på sne, grus eller fugtig jord.

Styleguide 3.1
*/

.hero__top {
  position: relative;
  height: 720px;
  overflow: hidden;
  height: calc(80vh - #{$nav-height-small});

  /*@media (min-width: 640px) and (orientation: landscape) {
    height: calc(70vh - #{$nav-height-small});
  }*/

  @media (min-width: 1024px) and (orientation: landscape) {
    height: calc(85vh - #{$nav-height-large});
  }

  @media (min-width: 1200px) and (orientation: landscape) {
    height: calc(90vh - #{$nav-height-xlarge});
  }

  .row {
    height: 100%;
  }
}

.hero__bottom {
  margin-top: -1px;
  padding-bottom: 0px;
  position: relative;
  z-index: 1;
  &.hero__bottom-not-colored {
    // padding-bottom: 0px;
  }
}

.hero__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.compat-object-fit {
   background-size: cover;
   background-position: center center;
  }

  > *:not(a) {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;  // this causes issue with mi browsers.
    transform: translate(-50%, -50%);
  }

  >.caption{
    object-fit:none;
    position: absolute;
    color:rgba(white,0.5);
    background-color: rgba($brand-color--black,0);
    top: initial;
    bottom:0;
    right: 0;
    left:initial;
    width: auto;
    height: auto;
    margin-bottom: 0;
    padding:$spacing-small*0.5 $spacing-small $spacing-small*0.75;
    transform: translate(0, 0);
    font-size: 9px;
    display: none;
    transition: $global-transition;
    @include breakpoint(medium) {
      display: inline-block;
      max-width: 200px;
      // bottom: 90px;
    }
  }
  >video {
      //min-height: 712px;
  }
}
.hero__top{
  //min-height: 712px;
  &:hover{
    .hero__background {
      >.caption{
        color:rgba(white,1);
        transition: $global-transition;
        background-color: rgba($brand-color--black,1);
      }
    }
  }
}

.hero__header-container {
  position: relative;
  z-index: 1;
  @include breakpoint(medium) {
    display: flex;
    justify-content: flex-end;
  }
}

.hero__header {
  font-size: 1.5em;
  //font-size: 2em;
  // display: inline-block;
  color: white;
  letter-spacing: 2px;

  @include breakpoint(medium) {
    font-size: 2.5em;
    padding-right: 50px;
  }

  @include breakpoint(xlarge) {
    font-size: 5.7em;
    padding-right: 100px;
  }

  span {
    line-height: 1;
    display: block;
    font-family: 'EXP-display';
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(.01,.96,.58,1), transform 0.55s cubic-bezier(.01,.96,.58,1);

    &:nth-child(2n+2) {
      transition-delay: 0.4s;
    }
    &:nth-child(3) {
      transition-delay: 0.8s;
    }
  }

  //scale the text down depending on the amount of spans
  span:nth-last-child(n+4), span:nth-last-child(n+4) ~ span {
    font-size: 0.7em;
  }
}

.hero__content {
  transform: translateY(-90px);
  position: relative;
  //opacity: 0;
  //transition: opacity 0.3s cubic-bezier(.01,.96,.58,1), transform 0.55s cubic-bezier(.01,.96,.58,1);

  .info {
    background-color: white;
    position: relative;
    // top: -70px;
    box-shadow: $global-dropshadow--default;
    padding: $spacing-medium $spacing-medium $spacing-medium*1.25;
    color: $color-theme-primary;
    h2{
      margin-bottom: 0.5em;
      // letter-spacing: 1px;
    }
    p {
      @extend .text--large;
      margin-bottom:1.5em;
    }
    > * {
      &:not(:last-child) {
      }
    }
  }

  .extra-info {
    position: relative;
    width: 60%;
    background-color: $grey-light;
    box-shadow: $global-dropshadow--default;
    padding: $spacing-medium;

    .info-badge {
      position: absolute;
      top: $spacing-small;
      left: 0;
      width: 60px;
      height: 60px;
      padding: 20px;
      transform: translateX(-50%);
      background-color: $color-theme-primary;
      border-radius: 50%;
      box-shadow: $global-dropshadow--01;

      .svg-icon {
        fill: white;
      }
    }
  }
}

.hero--theme-background {
  .hero__bottom {
    background-color: $color-theme-primary;
  }
}

.hero--animate-text-in {

  .hero__header {
    span {
      opacity: 1;
    }
  }
}

/*.hero--animate-content {
  .hero__content {
    transform: translateY(-90px);
    opacity: 1;
  }
}*/

.hero--animate-text-split {
  .hero__header {
    span {
      transition-delay: 0s !important;
      &:nth-child(1) {
        @include breakpoint(medium) {
          transform: translateX(-15px);
        }

        @include breakpoint(xlarge) {
          transform: translateX(-30px);
        }
      }
      &:nth-child(2n+2) {
        @include breakpoint(medium) {
          transform: translateX(50px);
        }

        @include breakpoint(xlarge) {
          transform: translateX(100px);
        }
      }
      &:nth-child(3) {
        @include breakpoint(medium) {
          transform: translateX(-50px);
        }

        @include breakpoint(xlarge) {
          transform: translateX(-100px);
        }
      }
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.hero') {
    &.hero--theme-background {
      .hero__bottom {
        background-color: $color;
      }
    }

    .hero__content {
      .info {
        color: $color;
      }

      .extra-info {
        .info-badge {
          background-color: $color;
        }
      }
    }
  }
}

.no-video-image-hero__bottom {
  margin-top: 166px;
}

//to much space under hero issues
.hero {
  &+.quiz-wrapper {
    padding-top: 0;
    margin-top: 0px;
  }
  &+.three-boxes-wrapper {
    padding-top: 90px;
  }
}
