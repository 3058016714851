/*
Secondary navigation

A thumbnail for event, this is shown in the event overview page

Markup:
p &nbsp;
p &nbsp;
p default state
p &nbsp;
.row.align-center
  .columns.small-12.medium-10.xlarge-8.xxlarge-7
    .hero__content
      .secondary-navigation
        .secondary-navigation__item
          .level-title Om os
            span.icon--arrow--down
              svg
                use(xlink:href="images/assets/SVGSpritesheet.svg#arrow--down")
          .level-children
            ul.nav-list
              li.list-item
                a(href="#") formål og historier
              li.list-item
                a(href="#") Om huset
              li.list-item
                a(href="#") Bestyrelse, præsidium
              li.list-item.active
                a(href="#") Medarbejdere
              li.list-item
                a(href="#") Årsberetning
              li.list-item
                a(href="#") Finansiering
              li.list-item
                a(href="#") Netværk
      article.info
        h2.header--medium Om os
        p Om os handler om sæbebobler. Hvorfor spejler en sæbebobbel dig to gange? Og hvorfor er den egentlig rund? Kom og pust og bliv klogere på videnskaben bag de fascinerende sæbebobler eller slå dig ned og nyd synet.
        .tags
          svg.svg-icon.icon--tag
            use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
          .tag-group
            span.tag
              a(href="#") Eksperiment
            span.tag
              a(href="#") Jorden
            span.tag
              a(href="#") Magna at volutpat
            span.tag
              a(href="#") Blandit nisl
p &nbsp;
p &nbsp;
p open state
p &nbsp;
.row.align-center
  .columns.small-12.medium-10.xlarge-8.xxlarge-7
    .hero__content
      .secondary-navigation
        .secondary-navigation__item.open
          .level-title Om os
            span.icon--arrow--down
              svg
                use(xlink:href="images/assets/SVGSpritesheet.svg#arrow--down")
          .level-children
            ul.nav-list
              li.list-item
                a(href="#") formål og historier
              li.list-item
                a(href="#") Om huset
              li.list-item
                a(href="#") Bestyrelse, præsidium
              li.list-item.active
                a(href="#") Medarbejdere
              li.list-item
                a(href="#") Årsberetning
              li.list-item
                a(href="#") Finansiering
              li.list-item
                a(href="#") Netværk
      article.info
        h2.header--medium Om os
        p Om os handler om sæbebobler. Hvorfor spejler en sæbebobbel dig to gange? Og hvorfor er den egentlig rund? Kom og pust og bliv klogere på videnskaben bag de fascinerende sæbebobler eller slå dig ned og nyd synet.
        .tags
          svg.svg-icon.icon--tag
            use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
          .tag-group
            span.tag
              a(href="#") Eksperiment
            span.tag
              a(href="#") Jorden
            span.tag
              a(href="#") Magna at volutpat
            span.tag
              a(href="#") Blandit nisl
p &nbsp;
p &nbsp;
p open state
p &nbsp;
.row.align-center
  .columns.small-12.medium-10.xlarge-8.xxlarge-7
    .hero__content
      .secondary-navigation
        .secondary-navigation__path
          a(href="#") Om os
        .secondary-navigation__item.open
          .level-title Medarbejdere
            span.icon--arrow--down
              svg
                use(xlink:href="images/assets/SVGSpritesheet.svg#arrow--down")
          .level-children
            ul.nav-list
              li.list-item
                a(href="#") Medarbejdere Sub-nav01
              li.list-item
                a(href="#") Medarbejdere Sub-nav01
              li.list-item
                a(href="#") Medarbejdere Sub-nav01
              li.list-item
                a(href="#") Medarbejdere Sub-nav01
      article.info
        h2.header--medium Medarbejdere
        p Om os handler om sæbebobler. Hvorfor spejler en sæbebobbel dig to gange? Og hvorfor er den egentlig rund? Kom og pust og bliv klogere på videnskaben bag de fascinerende sæbebobler eller slå dig ned og nyd synet.
        .tags
          svg.svg-icon.icon--tag
            use(xlink:href="images/assets/SVGSpritesheet.svg#tags")
          .tag-group
            span.tag
              a(href="#") Eksperiment
            span.tag
              a(href="#") Jorden
            span.tag
              a(href="#") Magna at volutpat
            span.tag
              a(href="#") Blandit nisl


Styleguide 2.13

*/


.secondary-navigation {

   .slideout-open &  {
    top: 0px;
    visibility: hidden;
    transition: none;
  }
  .slideout-open .browser-ios & {
    top: 62px;
  }

  display: block;
  position: fixed;
  // margin-left: $spacing-small;
  width: 100%;
  // transform: translateY(- $spacing-small);
  top: 136px;
  z-index: 99;
  user-select: none;
  transition:  $global-transition;

  @include breakpoint(medium) {
    top: 132px;
  }
  @include breakpoint(large) {
    // margin-left: $spacing-medium;
    // width: auto;
    // top: 100px;
    top: initial;
    height: 90px;
    // will-change: height;
  }

  &.secMenu-condensed {
    @include breakpoint(large) {
      height: 66px;
      // top: 70px;
      top: initial;
      // transition:  $global-transition;
      .secondary-navigation__item {
        padding-top: 8px;
        // transition:  $global-transition;
      }
    }
    @include breakpoint(xlarge) {
      height: 66px;
      // top: 80px;
      // top: initial;
      // transition:  $global-transition;
      .secondary-navigation__item {
        padding-top: 8px;
        // transition:  $global-transition;
      }
    }
  }

  .secondary-navigation__path{
    display: block;
    position: relative;
    float: left;
    width: 100%;

    @include breakpoint(large) {
      width: auto;
    }

    a{
      display: block;
      position: relative;
      width: auto;
      margin: 0;
      padding: $spacing-small*0.5 $spacing-small*0.75;
      background-color: white;
      color: $color-theme-primary;
      box-shadow: $global-dropshadow--01;
      z-index: 1000;

      @include breakpoint(large) {
        display: inline-block;
      }

      &:hover{
        background-color: $color-theme-primary;
        color:white;
      }
    }
  }
  .secondary-navigation__item{
    position: relative;
    width: 100%;
    //float: left;

    @include breakpoint(large) {
      width: auto;
      display: block;
      // align-items: flex-start;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-top: 21px;
      margin: 0 auto;
    }

    &.open{
      .level-children{
        transform: scale(1);
        display: block;
      }
      .level-title{
        .icon--arrow--down svg {
          transform: rotate(180Deg);
          transition: transform .2s cubic-bezier(.87,-.61,.19,1.44);
          @include breakpoint(medium) {
            transform: rotate(180Deg);
          }
        }
      }
    }
    .level-title {
      display: flex;
      position: relative;
      margin: 0;
      padding: $spacing-small*0.5 $spacing-small*0.75;
      background-color: $color-theme-primary;
      color: white;
      box-shadow: $global-dropshadow--01;
      z-index: 99;
      width: 100%;

      @include breakpoint(large) {
        display: inline-block;
        position: relative;
        margin: 0;
        padding: 18px 25px;
        background-color: $color-theme-primary;
        color: white;
        box-shadow: none;
        z-index: 99;
        width: auto;
        border: 1px solid #fff;
        line-height: 1em;
        font-size: 13px;
        a {
          line-height: 1em;
        }
      }
      // @extend .text--small;
      &:hover{
        cursor: pointer;
        // color: $color-theme-primary;
        // background-color: white;
        .icon--arrow--down, .icon--close{
          svg{
            fill: $color-theme-primary;
          }
        }
      }
      .icon--arrow--down{
        position: relative;
        margin: 0;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg{
          width: 12px;
          height: 12px;
          fill: white;
          transform-origin: center;
          transition: transform .2s cubic-bezier(.87,-.61,.19,1.44);
        }
        @include breakpoint(large) {
          display: none;
        }
      }
      .icon--close{
        position: relative;
        display: none;
        margin: 0 $spacing-small*0.25 0 $spacing-small*0.5;
        transform-origin: center;
        svg{
          width: 12px;
          height: 12px;
          fill: white;
        }
      }
      a.hidden-mobile {
        display: none;
        @include breakpoint(large) {
          display: block;
        }
      }
    }
    .level-children{
      transform-origin: top;
      transform: scale(0);
      display: none;
      background-color: white;
      box-shadow: $global-dropshadow--01;
      z-index: 90;
      transition: transform 0.3s cubic-bezier(.87,0,.19,1.34);
      width: 100%;

      @include breakpoint(large) {
        // width: auto;
        // margin-top:$spacing-small;
        // margin-left:-$spacing-small*0.5;
        // transform-origin: top left;
        display: inline-block;
        background-color: transparent;
        box-shadow: none;
        transition: none;
        transform: none;
        width: auto;
      }

      .list-item{
        @include breakpoint(large) {
          display: inline;
        }
        a{
          display: inline-block;
          background-color: white;
          width: 100%;
          padding: $spacing-small*0.25 $spacing-small;
          color: $color-theme-primary;
          @include breakpoint(large) {
            display: inline-block;
            background-color: transparent;
            width: auto;
            padding: $spacing-small*0.25 $spacing-small;
            color: #fff;
            font-size: 16px;
          }

          &:hover{
            // color:white;
            // background-color: $color-theme-primary;
          }
        }
      }
      .active{
        a{
          // color: white;
          // background-color: $color-theme-primary;
        }
      }
    }
  }
}

.theme_back-container {
  position: fixed;
  z-index: 9;
  width: 100%;
  left: 0;
  margin: 0 auto;
  right: 0;
  transition: $global-transition;
  .theme-back-button {
    display: flex;
    background-color: #24272d;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    top: 75px;
    position: relative;
    padding: 15px 15px 15px 20px;
    left: 27px;
    margin: 0;
    &:before {
      content: "\f340";
      font-family: dashicons;
      font-size: 16px;
      position: relative;
      right: 10px;
    }
    @include breakpoint(large) {
      top: 20px;
      padding: 18px 20px 17px 30px;
    }
  }
}


@each $theme, $color in $themes {
  @include theme($theme, $color, '.secondary-navigation') {
    background-color: $color;
    .secondary-navigation__path{
      a{
        // color:$color;
        &:hover{
          // background-color: $color;
          // color:white;
        }
      }
    }
    .secondary-navigation__item{
      .level-title{
        background-color: $color;
        &:hover{
          // color: $color;
          // background-color:white;
          .icon--arrow--down, .icon--close{
            svg{
              fill: #fff;
            }
          }
        }
      }
      .level-children{
        .list-item{
          a{
            color: $color;
            @include breakpoint(large) {
              color: #fff;
            }
            &:hover{
              // background-color: $color;
              // color:white;
            }
          }
        }
        .active{
          a{
            background-color: $color;
            color:white;
            @include breakpoint(large) {
              background-color: transparent;
              color:white;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
