/*
A label button.

A label button is design to contain 2 relevent informations and one icons. At the example below, the deisgn has been used for web product pruchase button.

One part of the button text is the call to action text, at this case "læg i kurv", the other part it the price of the product.
There is only one size label button at this moment, wihch inherited from the btn--small modifier.

Markup:
.theme-default
  .row.align-center
    button.label-btn(data-text="Se og køb")
      span.label-btn__text Se og køb
      span.label-btn__icon
        svg.svg-icon
          use(xlink:href="images/assets/SVGSpritesheet.svg#cart")
      span.label-btn__label 899 DKK
p &nbsp;
.theme-red
  .row.align-center
    button.label-btn(data-text="Se og køb")
      span.label-btn__text Se og køb
      span.label-btn__icon
        svg.svg-icon
          use(xlink:href="images/assets/SVGSpritesheet.svg#cart")
      span.label-btn__label 899 DKK

Styleguide 1.4
*/

/*
A label button invert.

The inverted label button inherited properties from both invert css modifier and btn--small.

label-btn--invert - invert label button

Markup:
p &nbsp;
p theme default invert
p &nbsp;
.theme-default
  .row.align-center.highlight
    button(class="label-btn btn--invert {$modifiers}" data-text="Se og køb")
      span.label-btn__text Se og køb
      span.label-btn__icon
        svg.svg-icon
          use(xlink:href="images/assets/SVGSpritesheet.svg#cart")
      span.label-btn__label 899 DKK
p &nbsp;
p theme red invert
p &nbsp;
.theme-red
  .row.align-center.highlight
    button(class="label-btn btn--invert {$modifiers}" data-text="Se og køb")
      span.label-btn__text Se og køb
      span.label-btn__icon
        svg.svg-icon
          use(xlink:href="images/assets/SVGSpritesheet.svg#cart")
      span.label-btn__label 899 DKK


Styleguide 1.5
*/

.label-btn {
  display: flex;
  align-items: center;
  font-size: $font-size--small;
  @extend .btn;
  @extend .btn--small;
  padding:0;
  .label-btn__icon{
    display: inline-block;
    height: 100%;
    padding:1px 0 14px 10px;
    background-color: $color-theme-primary;
    @include breakpoint(medium){
      padding: 6px 0 8px 10px;
    };
  }
  .svg-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    fill: white;
    background-color:transparent;
    vertical-align: middle;
    transform: translate(0%);
  }

  &:hover {
    background-color: $color-theme-primary;
    .label-btn__text {
      background-color: $color-theme-primary;
      color: white;
    }
    .label-btn__icon, .label-btn__label{
      // opacity: 1;
      // transform: translate(0, 0);
    }
  }
  &:after {
    font-size: $font-size--small;
    text-transform: uppercase;
    text-align: left;
    padding-left:$spacing-small;
  }
  &--invert{
    &:hover{
      background-color: white;
      .label-btn__text {
        background-color: white;
        color:$color-theme-primary;
      }
      .svg-icon {
        fill: $color-theme-primary;
        background-color: transparent;
      }
    }
    .label-btn__text{
      color:white;
      background-color:transparent;
    }
    .label-btn__label{
      color:$color-theme-primary;
      background-color:white;
    }
    .label-btn__icon {
      background: white;
      svg{
        fill:$color-theme-primary;
      }
    }
  }
}

.label-btn__text {
  color: $color-theme-primary;
  background-color: transparent;
  padding:$spacing-small*0.55 $spacing-small $spacing-small*0.45 $spacing-small;
  font-weight: 800;
  line-height: 1.125em;
  text-transform: uppercase;
}

.label-btn__label {
  padding:$spacing-small*0.5 $spacing-small $spacing-small*0.5 $spacing-small*0.5;
  background-color: $color-theme-primary;
  color: white;
  font-weight: 800;
  line-height: 1.125em;
  text-transform: uppercase;
  font-size: $font-size;
}

// fixing the ios alignment issue
@media only screen
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 2){
    .label-btn{
      .svg-icon{
        margin-top: -8px;
        transform: translateY(50%);
      }
    }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.label-btn') {
    .label-btn__icon{
      background-color: $color;
    }
    &:hover {
      background-color: $color;
      .label-btn__text {
        background-color: $color;
        color:white;
      }
      .svg-icon {
        fill: white;
        background-color: transparent;
      }
    }
    .label-btn__text{
      color: $color;
      background-color: transparent;;
    }
    .label-btn__label{
      background-color:$color;
    }
  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.label-btn--invert') {
    .svg-icon {
      fill: white;
    }
    &:hover {
      background-color: white;
      .label-btn__text {
        color: $color;
        background-color:white;
      }
      .svg-icon {
        fill: $color;
      }
    }
    .label-btn__text{
      color: white;
      background-color: transparent;;
    }
    .label-btn__label{
      background-color:white;
      color:$color;
    }
    .label-btn__icon{
      background-color: white;
      svg{
        fill: $color;
      }
    }
  }
}
