/*
Global footer

default - default view of the footer

Markup:
footer.footer
  .row
    .small-12.medium-6.large-3.columns
      .footer__item
        ul.opening-time
          li
            span.day Mondag
            span.time 10.00 - 20.00
          li
            span.day Tirsdag
            span.time 10.00 - 20.00
          li
            span.day Onsdag
            span.time 10.00 - 20.00
          li.today
            span.day Torsdag
            span.time 10.00 - 21.00
          li
            span.day Fredag
            span.time 10.00 - 20.00
          li
            span.day Lørdag (årskort)
            span.time 09.00 - 22.00
          li
            span.day Lørdag
            span.time 10.00 - 20.00
          li
            span.day Søndag
            span.time 10.00 - 20.00
        p.text--small Lukket den 23. 24. 25. dec, samt den 31. dec. og 1. jan.
        p
          a(href="#" class="link link--invert") Planlæg dit besøg
    .small-12.medium-6.large-3.columns
      .footer__item.address
        h5 Address
        p Tuborg Havnevej 7
        p 2900 Hellerup
        p Denmark
      .footer__item.contact
        h5 Contact us
        p Tel: +45 21 54 87 98
        p Mail:
          a(href="mailto:info@site.com") info@experimentarium.com
      .footer__item
        h5 CVR
        p 12 54 87 65
    .small-12.medium-6.large-4.columns
      .footer__item.news-letter-signup
        h5 Tilmeld vores nyhedsbrev
        p Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget pretium eros, et porta lacus.
        .input-group
          input(class="input-group-field input-text input-text--invert" type="text" placeholder="Din mail adresse")
          .input-group-button
            input(type="submit" class="button btn btn--invert" value="Tilmeld")
    .small-12.medium-6.large-2.columns
      .footer__item.links
        ul
          li
            a(href="#" class="link") om os
          li
            a(href="#" class="link") TIL VIRKSOMHEDER
          li
            a(href="#" class="link") TIL SKOLER
          li
            a(href="#" class="link") PRESSE
          li
            a(href="#" class="link") ENGLISH
          li
            a(href="#" class="link") WEBSHOP

Styleguide 3.4

*/

.footer-content {
  .news-letter-signup {
    position: relative;
    z-index: 2;
  }
}

.footer{
  position: relative;
  background-color: $brand-color--black;
  color: white;
  padding:$spacing-medium 0;

  .footer__item{
    margin-bottom:$spacing-small;
    h5{
      font-weight: 800;
    }
    a.link{
      border-bottom:1px solid white;
      &:hover{
        border-color:transparent;
      }
    }
    .opening-time{
      margin-bottom: $spacing-small;
      li{
        @extend .text--small;
        .day{
          display: inline-block;
          margin-right:$spacing-small*0.5;
          min-width: 50px;
        }
      }
      li.today{
        font-weight: 800 !important;
        span{
        }
      }
    }
  }
  .address, .contact{
    p{
      margin-bottom: 0;
    }
  }
  .footer__item.social-share{
    margin-top:$spacing-medium;
    ul.social-shares__icons{
      margin-top:$spacing-small*0.5;
    }
  }
  .links{
    li{
      @extend .text--small;

      // Alternative layout
      display: inline-block;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
      // end Alternative layout
      a{
        text-transform: uppercase;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .news-letter-signup{
    .input-text{
      // padding:$spacing-small*0.775 $spacing-small*0.5;
      color:$brand-color--black;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: rgba($brand-color--black, 0.5);
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: rgba($brand-color--black, 0.5);
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: rgba($brand-color--black, 0.5);
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: rgba($brand-color--black, 0.5);
      }
      &:focus{
        background-color: $light-gray;
        border-color: white;
        color:$brand-color--black;
      }
    }
    input[type="submit"]{
      padding-left:$spacing-small*0.75;
      padding-right:$spacing-small*0.75;
      &:hover{
        color:$brand-color--black;
      }
    }
    .newsletter-checkbox--agree {
      #checkboxFiveInput {
        visibility: hidden;
        display: none;
      }
      label {
        cursor: pointer;
        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        top: 0;
        left: 0;
        margin-left: 0;
        margin-right: 3px;
        background: rgba(255, 255, 255, 0.85);
        border: 1px solid #ddd;
        &:after {
          opacity: 0.1;
          content: '';
          position: absolute;
          width: 12px;
          height: 6px;
          background: transparent;
          top: 3px;
          left: 1px;
          border: 3px solid #333;
          border-top: none;
          border-right: none;
          transform: rotate(-45deg);
        }
        &:hover::after {
          opacity: 0.5;
        }
      }
      input[type=checkbox]:checked + label:after {
        opacity: 1;
        border-color: #38b11b;
      }
      .newsletter-label--title {
        font-size: 0.8em;
        display: inline;
        margin-top: 0px;
        padding-left: 0px;
        vertical-align: middle;
      }
      p.this-i-presume-should-be-hidden {
        opacity: .4;
        font-size: 0.8em;
        &:hover {
          opacity: 1;
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }
  .footer-copyright {

  }
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.global-navigation') {
  }
}
