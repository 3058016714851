/*
Logo

Markup:
<div class="site-logo">
  <a href="#">
    <svg class="logo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    	 width="200px" height="107.908px" viewBox="0 0 188 107.908" enable-background="new 0 0 188 107.908" xml:space="preserve">
    <g class="logo-section-01">
    	<polygon id="Fill-1" points="47.453,0.239 47.453,31.252 47.758,31.252 66.9,31.252 66.9,24.22 54.702,24.22 54.702,19.11
    		65.034,19.11 65.034,12.208 54.702,12.208 54.702,7.228 68.897,7.228 68.897,0.239 	"/>
    	<polygon id="Fill-2" points="89.385,0.239 84.088,8.189 79.011,0.239 70.416,0.239 79.705,13.911 68.072,31.253 76.492,31.253
    		84.088,19.502 91.555,31.253 100.28,31.253 88.516,13.737 97.763,0.239 	"/>
    	<path id="Fill-3" d="M116.426,10.984c0-2.62-1.866-4.149-4.774-4.149h-2.908v8.299h2.907
    		C114.559,15.134,116.426,13.605,116.426,10.984L116.426,10.984z M124.021,10.984c0,6.552-4.904,10.92-11.633,10.92h-3.645v9.348
    		h-7.207V0.238h7.207h3.731C119.161,0.238,124.021,4.52,124.021,10.984L124.021,10.984z"/>
    	<polygon id="Fill-5" points="134.002,7.228 134.002,12.208 144.334,12.208 144.334,19.109 134.002,19.109 134.002,24.22
    		146.201,24.22 146.201,31.252 127.057,31.252 126.754,31.252 126.754,0.239 134.002,0.239 148.197,0.239 148.197,7.228 	"/>
    	<path id="Fill-7" d="M158.832,7.009v7.688h3.343c2.907,0,4.729-1.441,4.729-3.844s-1.821-3.844-4.729-3.844H158.832z
    		 M161.176,21.555h-2.344v9.697h-7.293V0.239h7.293h4.123c6.686,0,11.547,4.237,11.547,10.614c0,4.368-2.258,7.775-5.859,9.479
    		l8.551,10.919h-8.682L161.176,21.555z"/>
    	<polygon id="Fill-9" points="179.188,31.253 187,31.253 187,0.239 179.188,0.239 	"/>
    </g>
    <g class="logo-section-02">
    	<polygon id="Fill-11" points="47.663,69.462 40.283,69.462 40.283,49.629 27.694,69.462 21.01,69.462 21.01,49.893 8.725,69.462
    		0,69.462 20.271,38.447 25.308,38.447 27.564,38.447 27.564,58.233 40.153,38.447 47.663,38.447 	"/>
    	<polygon id="Fill-12" points="60.204,45.437 60.204,50.417 70.536,50.417 70.536,57.317 60.204,57.317 60.204,62.429
    		72.402,62.429 72.402,69.46 53.26,69.46 52.955,69.46 52.955,38.447 60.204,38.447 74.399,38.447 74.399,45.437 	"/>
    	<polygon id="Fill-13" points="104.305,38.447 104.305,69.46 97.188,69.46 84.901,50.678 84.901,69.46 77.739,69.46 77.739,38.447
    		84.989,38.447 97.188,57.187 97.188,38.447 	"/>
    	<polygon id="Fill-14" points="135.948,45.61 121.971,45.61 121.971,69.462 114.374,69.462 114.374,45.61 107.169,45.61
    		107.169,38.447 135.948,38.447 	"/>
    </g>
    <g class="logo-section-03">
    	<path id="Fill-15" d="M63.248,95.327V82.616L55.13,95.327H63.248z M70.627,107.252h-7.379v-5.416H50.962l-3.472,5.416h-8.378
    		l20.663-31.013h10.852V107.252z"/>
    	<path id="Fill-16" d="M83.213,83.01v7.688h3.343c2.908,0,4.732-1.441,4.732-3.846c0-2.4-1.824-3.844-4.732-3.844L83.213,83.01
    		L83.213,83.01z M85.557,97.556h-2.344v9.696H75.92V76.239h7.292h4.125c6.684,0,11.546,4.236,11.546,10.613
    		c0,4.369-2.258,7.774-5.86,9.479l8.552,10.92h-8.683L85.557,97.556z"/>
    	<polygon id="Fill-17" points="103.571,107.252 111.384,107.252 111.384,76.239 103.571,76.239 	"/>
    	<path id="Fill-18" d="M141.854,76.239v18.127c0,8.779-5.47,13.542-12.762,13.542c-7.162,0-12.762-4.675-12.762-13.542V76.239h7.639
    		v17.209c0,4.806,1.823,6.902,5.123,6.902c3.212,0,5.12-2.099,5.12-6.902V76.239H141.854z"/>
    	<polygon id="Fill-19" points="181.528,107.252 174.41,107.252 174.365,86.985 167.289,107.252 161.3,107.252 153.746,86.678
    		153.746,107.252 146.627,107.252 146.627,76.239 153.746,76.239 156.699,76.239 164.382,97.644 171.718,76.239 181.528,76.239 	"/>
    </g>
    </svg>
  </a>
</div>


Styleguide 1.10

*/

.site-logo {
  display: block;
  // margin-bottom:$spacing-small;
  .footer &{
    a{

    }
    svg{
      fill:white;
    }
  }
  a{
    display: inline-block;
    cursor: pointer;
    width: 84px;
    height: 48px;

    @include breakpoint(large) {
      width: 104px;
      height: 60px;
    }
    &:hover{
      svg{
        .logo-section-01, .logo-section-02, .logo-section-03{
          transform: translateX(0);
        }
        .logo-section-01{
          transform: translateX(-13px);
        }
        .logo-section-02{
          transform: translateX(13px);
        }
        .logo-section-03{
          transform: translateX(-13px);
        }
        #aegsperi {
          transform: translate(0px, 0px);
        }
        #ment {
          transform: translate(290px, 203px);
        }
        #arium {
          transform: translate(0px, 403px);
        }
      }
    }
  }
  svg{
    width: 100%;
    height: 100%;
    > g * {
      transition: transform 0.3s cubic-bezier(.87,-.61,.19,1.44);
    }
    .logo-section-01{
      transform: translateX(30px);
    }
    .logo-section-02{
      transform: translateX(-13px);
      transition-delay: 0.05s;
    }
    .logo-section-03{
      transform: translateX(13px);
      transition-delay: 0.1s;
    }
    #aegsperi {
      transform: translate(105px, 0px);
    }
    #ment {
      transform: translate(30px, 203px);
      transition-delay: 0.05s;

    }
    #arium {
      transform: translate(104px, 403px);
      transition-delay: 0.1s;

    }
  }
}

#aegsperi {
  path:first-child, path:nth-child(2) {
    //fill: $brand-color--yellow;
  }
}
