.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.pull-up-over {
  margin-top: -250px;
  &.pull-up-over-no-main-text {
    margin-top: 50px;
  }
}

.pull-up-over-padding {
  padding-bottom: 250px;
}

.background{
  &--grey{
    background-color:$grey-light;
  }
}
.hide{
  display: none !important;
}

.float-left {
  float: left;
}

//wp class that being added when wp user is looged on
.customize-support {
    .global-navigation__nav-bar {
      margin-top: 32px;
    }
}

.latest-article-wrapper {
  margin-top: 40px;
}

.services-wrapper {
  padding-bottom: 0!important;
  p.description {
    margin-bottom: 20px;
  }
}

.fancybox-title-float-wrap .child {
  white-space: normal !important;
}

.link-in-cookie {
  text-decoration: underline;
  &:hover {
    border: none;
  }
}

.newsletter-error-handle {
  display: none;
}

.customize-support {
  .beta-version-disclaimer {
    display: none;
  }
}

.beta-version-disclaimer {
  background: #fff;
  padding: 10px 26px;
}

.download-image {
  display: inline-block;
  //float: right;

  background-image: url(../images/icon-download.svg);
  background-repeat: no-repeat;
  background-size: contain;

  width: 47px;
  height: 47px;

  position: absolute;
  bottom: 30px;
  right: 30px;

  z-index: 10000;

  background-color: white;

}

.caption .text--small,
.fancybox-title .child {
  position: relative;
}

.center-absolute {
    @include center-absolute
}

.make-me-relative {
  position: relative;
}

.see-more-btn-wrapper {
  margin-bottom: 20px;
}
.leadfamly-campaign-embed,
.leadfamily-campaign-embed {
  padding: 3em 0;
  margin-bottom: 3em;
}
.mb2-search-load {
  margin: 0 auto 2em !important;
}
