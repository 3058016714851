/*
Thumbnail Top 5

This is used for pull a collection of different post links

Markup:
.row.align-center
  .small-12.medium-6.large-4.columns
    .thumbnail-top5
      .thumbnail__title
        h3.header--small 5 mest læste
      .thumbnail__content
        ul.top5-list
          li.list-item
            a(href="#")
              h4.header--smaller Lav en stjernekaster
              p.meta.dates 10, Aug, 2017
          li.list-item
            a(href="#")
              h4.header--smaller Lav din egen sne
              p.meta.dates 10, Aug, 2017
          li.list-item
            a(href="#")
              h4.header--smaller Lav dit eget sølvæg
              p.meta.dates 10, Aug, 2017
          li.list-item
            a(href="#")
              h4.header--smaller Frugtfarvometer
              p.meta.dates 10, Aug, 2017
          li.list-item
            a(href="#")
              h4.header--smaller Lav dit eget instrument
              p.meta.dates 10, Aug, 2017

Styleguide 2.9

*/

.thumbnail-top5{
  margin:$spacing-small 0 $spacing-medium;
  display: block;
  position: relative;
  .thumbnail__title{
    display: inline-block;
    position: absolute;
    top: -$spacing-medium*0.5;
    margin-left:$spacing-small;
    z-index: 100;
    background-color: $brand-color--black;
    padding:$spacing-small*0.5 $spacing-small;
    box-shadow: $global-dropshadow--01;
    @include breakpoint(medium) {
      width: 70%;
    }
    h3{
      @extend .header--smaller;
      font-family: 'EXP-display';
      letter-spacing: 0.1em;
      color: white;
      margin-bottom: 0;
    }
  }
  .thumbnail__content{
    // box-shadow: 0px 20px 40px -5px rgba(0,0,0,0.1);
    padding:$spacing-small*3 $spacing-small $spacing-small;
    background-color: $grey-light;
    p.meta{
      margin-bottom:$spacing-small*0.25;
    }
  }
}
.top5-list{
  counter-reset: top-counter;
  .list-item{
    position: relative;
    margin-bottom:$spacing-small;
    &:before{
      display: block;
      position: absolute;
      content:counter(top-counter);
      counter-increment: top-counter;
      width: 40px;
      height: 40px;
      margin-top: -10px;
      text-align: center;
      @extend .header--medium;
      z-index: 99;
    }
    &:after{
      display: block;
      content: " ";
      position: absolute;
      width: 40px;
      height: 10px;
      background-color: $color-theme-primary;
      top: 15px;
      left: 0;
      z-index: 1;
    }
    a{
      display: inline-block;
      position: relative;
      margin-left: 60px;
      h4{
        margin-bottom: 0;
      }
      &:hover{
        color:$color-theme-primary;
      }
    }
  }
}


@each $theme, $color in $themes {
  @include theme($theme, $color, '.top5-list') {
    .list-item{
      &::after{
        background-color: $color;
      }
      a{
        &:hover{
          color: $color;
        }
      }
    }
  }
}
