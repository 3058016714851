/*
Themes

This sections demonstrates different themes.

You can find the themes mixin in prerequisites > themes.scss(maybe we should make this more clear what is what)

If you want to assign theme colors to a element, we use the loop function in our mixins.scss file, this will generate the each theme color style for the element.

default -  a default theme color is blue
.theme-red - a red color theme
.theme-green - a green color theme
.theme-lavanda - a lavanda color theme
.theme-yellow - a yellow color theme(this is rarelly used)

Markup:
div(class="{$modifiers}")
  .row.align-center
    h3.header--smaller.header--theme Beautiful theme color

Styleguide 1.17
*/

/*
Themes invert

This sections demonstrates different invert theme themes.

Each invertable elements has a "--invert" css modifier, you can create your own one if need, this will render the element white in default state, and its repective colored theme in other states.

default -  a default theme color is blue
.theme-red - a red color theme
.theme-green - a green color theme
.theme-lavanda - a lavanda color theme
.theme-yellow - a yellow color theme(this is rarelly used)

Markup:
div(class="{$modifiers}")
  .row.align-center.highlight
    h3.header--smaller.header--invert Beautiful theme color

Styleguide 1.18
*/


.header--theme{
  color:$color-theme-primary;
}
.header--invert{
  color:white;
}

@each $theme, $color in $themes {
  @include theme($theme, $color, '.header--theme') {
    color:$color;
  }
}
