/*
Fonts and type

This sections demonstrates the different fonts and types.

We have two different typefaces, "EXP-display" and "Mark-Pro".

"EXP-display" is mostly used for the page title and bigger titles, and "Mark-Pro" is used both as title and body text.

There is only one weight in "EXP-display" and a every extensive weight sets for "Mark-Pro".

You can find all the fonts file from the scr > fonts folder.

Markup:
h1.header--large Heading 1: The quick brown fox jumps over the lazy dog
h2.header--medium Heading 2: The quick brown fox jumps over the lazy dog
h3.header--small Heading 3: The quick brown fox jumps over the lazy dog
h4.header--smaller Heading 4: The quick brown fox jumps over the lazy dog
h5.header--nano Heading 5: The quick brown fox jumps over the lazy dog
p.text Blandingen af is og salt har en lavere frysetemperatur end rent vand, der fryser ved nul grader. Når vandet i isen opløser saltet, trækker det varme ud af isen, som bliver kølet endnu mere ned.
 a(class="link" href="#") inline link Den kolde blanding fryser iscremen, så den får den helt rigtige iskonsistens.
p.text--small Blandingen af is og salt har en lavere frysetemperatur end rent vand, der fryser ved nul grader. Når vandet i isen opløser saltet, trækker det varme ud af isen, som bliver kølet endnu mere ned. inline link Den kolde blanding fryser iscremen, så den får den helt rigtige iskonsistens.

Styleguide 1.1
*/



/* I am styling on a body class as well as the body tag - this is a workaround because i cannot append styles to the body tag in the styleguide */
body, .sgwa-body {
  font-size: $font-size--large;
  color: $brand-color--black;

  @include breakpoint(medium) {
    font-size: $font-size;
  }
}

* {
  font-family: $base-font-stack;
  font-size: 1em;
  line-height: 1.75;
  color: inherit;
  margin: 0;

  &.text-align-center {
    text-align: center;
  }
  &.text-align-left {
    text-align: left;
  }
  &.text-align-right {
    text-align: left;
    @include breakpoint(medium) {
      text-align: right;
    }
  }
  &.white {
    color: white;
  }
}

p {
  margin-bottom:1em;
  word-wrap: break-word;
  line-height: 1.5em;
  font-weight: 400;
}


.text {
  font-weight: 400;
  &--small {
    font-size:$font-size--small;
    line-height: 1.25em;
  }
  &--large {
    line-height: 1.3125em;
    font-size: 1.25em;
    @include breakpoint(medium) {
      font-size: 1.4em;
    }
  }
  .highlight &{
    color:white;
    font-weight: 600;
  }
}

.text--small.white{
  font-weight: 600;
}

.header {
  // display: inline-block;
  line-height: 1.25;
  margin-top: 0;
  font-weight: 800;
  text-rendering: optimizeLegibility;
  &--large {
    @extend .header;
    font-family: 'EXP-display';
    font-size: 2em;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    @include breakpoint(medium) {
      font-size: 4em;
    }
  }

  &--medium {
    @extend .header;
    font-family: 'EXP-display';
    font-size: 1.5em;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 0.75em;

    @include breakpoint(medium) {
      line-height: 1em;
      font-size: 2.5em;
    }
    @include breakpoint(large) {
      font-size: 2.5em;
    }
  }

  &--small {
    @extend .header;
    font-size: 1.25em;
    margin-bottom: 1em;

    @include breakpoint(medium) {
      font-size: 1.5em;
    }
  }

  &--smaller {
    @extend .header;
    font-size: 1.2em;
    margin-bottom: 1em;
    line-height: 1.35;
  }

  &--nano {
    @extend .header;
    font-size: 1em;
    margin-bottom: 1em;
  }

  .highlight &{
    color:white;
  }

}

// text styles for wysiwyg editor
.wysiwyg, .galery {
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.25;
    margin-top: 0;
    font-weight: 800;
    text-rendering: optimizeLegibility;
  }
  // h1, h2 {
  //   text-transform: uppercase;
  //   font-family: 'EXP-display';
  // }
  h1 {
    font-family: 'EXP-display';
    font-size: 2em;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    @include breakpoint(medium) {
      font-size: 4em;
    }
  }
  h2 {
    font-family: 'EXP-display';
    font-size: 1.5em;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 0.375em;

    @include breakpoint(medium) {
      line-height: 1em;
      font-size: 2.5em;
    }
    @include breakpoint(large) {
      font-size: 2.5em;
    }
  }
  h3 {
    font-size: 1.25em;
    margin-bottom: 0.5em;

    @include breakpoint(medium) {
      font-size: 1.5em;
    }
  }
  h4 {
    font-size: 1.2em;
    margin-bottom: 0.5em;
    line-height: 1.35;
  }
  h5 {
    font-size: 0.5em;
    margin-bottom: 1em;
  }
  h6 {
    font-size: 0.5em;
    margin-bottom: 1em;
  }
  a {
    @extend .link;
    cursor: pointer;
  }
  p {
    margin-bottom: 1.5em;
  }
}

.wysiwyg__facts {
  a {
    // color: inherit !important;
    // border-bottom: none !important;
  }
}


@each $theme, $color in $themes {
  @include theme($theme, $color, '.wysiwyg') {
    a:not(.btn) {
      border-bottom: 1px solid $color;
      .btn {
        background-color: #fff;
        &:hover {
          background-color: $color;
        }
      }
      &:hover {
        border-bottom: none;
      }
    }
  }
}
