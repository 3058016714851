// .interaction {
// 	display: flex;
//     flex-direction: row;
//     justify-content: flex-end;
//     align-items: center;
//     position: absolute;
//     width: 100%;
//     top: 2rem;
//     z-index: 9999;
//     margin-top: -50px;
// 	padding: 0 4rem 0 4rem;
// }


.interaction {
	display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
	padding-right: 1rem;

    .rotator {
        display: block;
        width: 75px;
        height: auto;
        transform-origin: 31.5% 39.5%;
        cursor: pointer;

        #gear {
            overflow: visible;

            g {
                &.on {
                    fill:$color-midnight;
                }
            }
        }

        #handle {
            transform-origin: top left;
            animation: twinkle .4s infinite alternate;
        }

        &.rotated #handle {
            // animation: 0;

            g {
                fill:$color-midnight;
            }
        }
    }

    #lamp {
        display:flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 2;
        margin-right: 27px;

        #cord {
            width: 3px;
            height: 100px;
            margin-top: -13px;

            g {
                opacity: .2;

                &.on {
                    opacity: 1;
                    fill:$color-midnight;
                }
            }
        }

        #bulb {
            width: 30px;
            margin-left: 1px;

            g {
                opacity: .2;
                animation: blink .4s infinite alternate;

                &.on {
                    animation: 0;
                    opacity: 1;
                    fill:$color-midnight;
                }
            }
        }

        img.glow {
            margin-top: -70px;
            border-radius: 999px;
            opacity: 0;

            &.off {
                opacity: 0 !important;
            }
        }
    }

    #connector {
        position: absolute;
        top: 30px;
        right: 66px;
        width: 100px;
        height: auto;

        g {
            opacity: .2;

            &.on {
                opacity: 1;
                fill:$color-midnight;
            }
        }
    }
}

#knob {
    position: relative;
    display: block;
    width: 200px;
    height: 200px;
}

.knob {
    width: 100%;
    height: 100%;
    background: blue;
}

.secret-stuff {
    background: $grey-medium;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    // transform: scale(0);
    transition: all 1s $easing2;

    &.on {
        opacity: 1;
        // transform: scale(1);
        max-height: 1000px;
        padding-top: 100px;
        transition: all 3s $easing2;
    }

		//content band inside this element styles
		.wysiwyg {
			* {
				color: #0B132A !important;
			}
		}
}

// Make secret area flash white when bulb is lighted
.footer {
    transition: all .5s $easing5;
}

.footer.on {
    padding-top: 50px;
    background: rgba(255, 255, 255, 1);
    z-index: 2;
}

// .whitelayer {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     background: $brand-color--black;
//     max-height: 0;
//     opacity: 0;
//     z-index: -1;
//     transition: all $easing .1s;

//     &.on {
//         opacity: 1;
//         max-height: 1000px;
//         height: 9999px;
//     }
// }

@keyframes twinkle {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(5deg);
    }
}

@keyframes blink {
    0% {
        opacity: .2;
    }

    20% {
        opacity: .1;
    }

    40% {
        opacity: .15;
    }

    60% {
        opacity: .3;
    }

    80% {
        opacity: .18;
    }

    100% {
        opacity: .25;
    }
}
