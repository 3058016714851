html.sticky-footer {
    height: 100%;
    body {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }
    main {
        flex: 1;
    }
    footer {
        padding-bottom: 40px;
    }
}

body.dilemma-categories-exp {
    background-color: #5f0f40;

    footer {
        margin-top: 90px;
    }

    .dilemma-item {
        width: 36%;
        height: 300px;
        margin: 0 7% 50px;
        float: left;
        color: white;
        h2 {
            font-size: 30px;
            font-weight: normal;
            line-height: 35px;
            margin-bottom: 10px;
        }
        .dilemma-submit-btn-exp {
            display: inline-block;
            margin: 20px 0;
            padding: 5px 45px 12px 40px;
        }

    }

}

section.archive-intro-text {
    color: #fff6d9;
    max-width: 700px;
    margin: 0 auto;
    h1 {

        font-size: 72px;
        font-weight: 300;
        margin-top: 36px;
        margin-bottom: 0;
        line-height: 93px;
    }

    p {

        font-size: 24px;
        margin-bottom: 66px;
        line-height: 31px;
    }
}

section.archive-carousels {
    max-width: 920px;
    margin: 0 auto;

    .slider-archive-exp {
        opacity: 0;
        transform: translateX(-200px);
        transition: cubic-bezier(.175,.885,.32,1.275) 0.7s;

        &.slide-in {
            opacity: 1;
            transform: translateX(0px);

            &+.slider-archive-exp-ctrls {
                opacity: 1;
                transform: translateX(0px);
            }
        }

        a {
            background: rgba(256, 256, 256, 0.6);
            border: 1px solid #eee;
            padding: 15px;
            margin-right: 15px;
            //this height need to be fixed, designer specs
            height: 160px;
        }
        h2 {

            font-size: 19px;
            line-height: 23px;
        }
        h4 {

            font-size: 14px;
            line-height: 23px;
            color: #5f0f40;
            font-weight: normal;
        }
    }

    .category-title {
        h2 {
            color: white;
            margin-top: 14px;

        }
    }

    .slider-archive-exp-ctrls {
        display: flex;
        justify-content: center;
        padding: 20px 0 60px 0;

        opacity: 0;
        transform: translateX(-200px);
        transition: all cubic-bezier(.175,.885,.32,1.275) 0.7s;

        i {
            color: white;
            font-size: 26px;
            border: 1px solid white;
            border-radius: 50%;
            height: 60px;
            width: 60px;
            line-height: 60px;
            text-align: center;

            &:first-child {
                margin-right: 30px;
            }
        }
    }
}


