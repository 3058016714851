/*
Standard filters

A collection filer group

default - a normal tags used in thumbnails

Markup:
.theme-green
  .row.align-center
    ul.filters(class="{$modifiers}")
      li.filter
        a(href="#") Fun facts
      li.filter
        a(href="#") Godnathistorier
      li.filter
        a(href="#") Experimenter
      li.filter
        a(href="#" class="active") Sådan gør du
      li.filter
        a(href="#") Quiz
      li.filter
        a(href="#") Nyheder
      li.filter
        a(href="#") Temaer

Styleguide 1.13

*/


/*
Standard filters invert

A collection filer group with invert color

filters--invert - a tag in grey color, regardless to which theme it is in, mostly in thumbnails

Markup:
.theme-green
  .row.align-center.highlight
    ul.filters(class="{$modifiers}")
      li.filter
        a(href="#") Fun facts
      li.filter
        a(href="#") Godnathistorier
      li.filter
        a(href="#") Experimenter
      li.filter
        a(href="#" class="active") Sådan gør du
      li.filter
        a(href="#") Quiz
      li.filter
        a(href="#") Nyheder
      li.filter
        a(href="#") Temaer

Styleguide 1.14

*/

.filters{
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0 0 $spacing-medium;
  .filter{
    display: inline-block;
    position: relative;
    text-align: center;
    margin:$spacing-small*0.25;
    a{
      font-weight: 400;
      @extend .btn;
      @extend .btn--small;
      border: 0.125em solid;
    }
    a.active{
      color:white;
      background-color: $color-theme-primary;
    }
  }
  &--invert{
    .filter{
      a{
        font-weight: 700;
        @extend .btn--invert;
      }
      a.active{
        color:$color-theme-primary;
        background-color:white;
      }
    }
  }
}



@each $theme, $color in $themes {
  @include theme($theme, $color, '.filters') {
    .filter{
      a{

      }
      a.active{
        background-color: $color;
      }
    }
    &--invert{
      .filter{
        a.active{
          color:$color;
          background-color: white;
        }
      }
    }
  }
}
